<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectList from '@cox2m/city-services-ui-components/src/components/SelectList.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import CheckBoxColumn from './components/CheckBoxColumn.svelte';
  import RoleSearch from './components/RoleSearch.svelte';
  import DateColumn from './components/DateColumn.svelte';
  import DisableRoleModal from './components/DisableRoleModal.svelte';
  import EnableRoleModal from './components/EnableRoleModal.svelte';

  import {getGroups, searchUsersByName} from 'web-actions';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';

  let showDisableRoleModal = false;
  let showEnableRoleModal = false;

  let roleToDisable = null;
  let roleToEnable = null;

  const ROLE_STATUS_OPTIONS = [
    {label: 'All', value: null},
    {label: 'Enabled', value: true},
    {label: 'Disabled', value: false}
  ];

  const ROLES_TYPES_OPTIONS = [
    {label: 'All Roles', value: null},
    {label: 'Custom', value: 'custom'},
    {label: 'Active Directory', value: 'active-directory'}
  ];

  const columns = [
    {
      label: 'Enabled',
      key: 'active',
      type: 'text',
      width: 50,
      Component: CheckBoxColumn
    },
    {
      label: 'Role Name',
      key: 'name',
      type: 'text'
    },
    {
      label: 'Active Users',
      key: 'activeUsersAmount',
      type: 'number'
    },
    {
      label: 'Created',
      key: 'creationDate',
      type: 'date',
      Component: DateColumn
    },
    {
      label: 'Last Active',
      key: 'lastActive',
      type: 'date',
      Component: DateColumn
    },

  ];

  let selectedRoleStatus = null;
  let selectedRoleType = null;
  let groupName = '';

  let filteredGroupList = [];
  let groupList = [];

  let tableLoading = true;
  let loading = true;

  let fetchError = false;

  const showDisableRoleModalHandler = e => {
    showDisableRoleModal = true;
    roleToDisable = e.detail;
  };

  const showEnableRoleModalHandler = e => {
    showEnableRoleModal = true;
    roleToEnable = e.detail;
  };

  const getFilteredGroups = () => {
    if (groupList.length) {
      tableLoading = true;
      try {
        filteredGroupList = groupList.filter(
          group =>
            group.name.toLowerCase().includes(groupName.toLowerCase()) &&
            group.name.toLowerCase().includes(groupName.toLowerCase()) &&
            group.name.toLowerCase().includes(groupName.toLowerCase()) &&
            (selectedRoleStatus != null
              ? group.active == selectedRoleStatus
              : group)
        );
      } catch (e) {
        e;
        filteredGroupList = [];
      }
      tableLoading = false;
    }
  };

  const fetchGroupsListInformation = async () => {
    loading = true;
    const groupsResponse = await getGroups({token: $user.token}, {active: true});
    const usersResponse = await searchUsersByName({
      xToken: $user.token,
      limit: 1000
    });

    if (groupsResponse.fulfilled && usersResponse.fulfilled) {
      groupList = groupsResponse.collection.map(group => {
        let activeUsers = usersResponse.users.filter(user =>
          user.groupNames.includes(group.name)
        );
        return {
          activeUsersAmount: activeUsers.length,
          activeUsers: activeUsers,
          name: group.name,
          active: group.active,
          group: group,
          creationDate: group.createdAt,
          lastActive: group.lastActive
        };
      });
      filteredGroupList = groupList;
      tableLoading = false;
    }
    loading = false;

  }
  onMount(async () => {
    await fetchGroupsListInformation ();
  });

  const refreshRoles = async () => {
    // await getGroups();
    await fetchGroupsListInformation ();
    };

  $: getFilteredGroups(selectedRoleStatus, selectedRoleType);
</script>

<style>
  .section-title {
    font-size: var(--cox2m-fs-700);
  }
  .section-container {
    border-radius: 6px;
    width: 100%;
    padding: var(--cox2m-spacing-4-units );
    padding-bottom: var(--cox2m-spacing-2-units );
    margin: 0 auto;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
    background-color: var(--cox2m-clr-neutral-white);
  }
  .section-subtitle {
    font-size: medium;
  }

  #role-form-container {
    padding-bottom: var(--cox2m-spacing-8-units );
  }
  :global(.options-container) {
    z-index: var(--cox2m-z-index-2);
  }
  :global(#users-management-table) {
    font-size: clamp(var(--cox2m-fs-1200), 1.5vw, var(--cox2m-fs-900));
  }
  :global(#users-management-table-container) {
    height: calc(100vh - 500px);
    min-height: 500px;
  }
  .filter-section-container {
    align-items: center;
    justify-content: space-between;
  }
  #inputs-container {
    column-gap: var(--cox2m-spacing-4-units);
  }
  @media only screen and (max-width: 1200px) {
    #role-form-container {
      max-width: calc(100% - var(--cox2m-spacing-4-units));
      padding: var(--cox2m-spacing-4-units);
      padding-bottom: var(--cox2m-spacing-12-units);
    }
    .section-container {
      max-width: 100%;
      margin: 0 var(--cox2m-spacing-2-units);
    }
    .filter-section-container,
    #inputs-container {
      align-items: flex-end;
      flex-direction: column;
      gap: calc(var(--cox2m-spacing-4-units ) - var(--cox2m-spacing-1-units));
    }
    #inputs-container,
    #inputs-container > *,
    :global(#role-selector, #role-selector-container, #status-selector, #status-selector-container) {
      width: 100%;
    }
  }
</style>

<DisableRoleModal
  on:disableRoleSuccess={refreshRoles}
  bind:show={showDisableRoleModal}
  {roleToDisable} />
<EnableRoleModal
  on:enableRoleSuccess={refreshRoles}
  bind:show={showEnableRoleModal}
  {roleToEnable} />

{#if loading && !fetchError}
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <LoadingSpinner />
  </div>
{:else if !loading && fetchError}
  <div
    class="d-flex row align-items-center justify-content-center w-100 h-100 mt-5
    pt-5">
    <Icon
      icon="cross-circle"
      className="mb-3"
      color="var(--cox2m-clr-brand-500)"
      size="var(--cox2m-spacing-16-units)" />
    <h2 class="text-center">
      We are sorry, we couldn't fetch the information correctly, refresh the
      page or try again later
    </h2>
  </div>
{:else}
  <div
    id="role-form-container"
    data-testid="role-form-container"
    class=" rounded w-100">
    <div class="section-container row">
      <div class="col-12 p-0 mb-5">
        <h4
          class="section-title font-weight-bold pt-3 mb-0"
          id="section-title"
          data-testid="section-title">
          Roles
        </h4>
        <p class="m-0 pt-2 section-subtitle">
          Roles are inherited from the city active directory. Enable a role to
          make the platform available to those users and set their permissions.
        </p>
      </div>
      <div class="col-12 m-0 p-0">
        <div class="d-flex filter-section-container flex-wrap">
          <div
            id="inputs-container"
            data-testid="inputs-container"
            class="d-flex">
            <div id="role-search-container" data-testid="role-search-container">
              <RoleSearch
                bind:groupName
                fetchFunction={() => {
                  getFilteredGroups();
                }} />
            </div>
            <div class="d-flex">
              <SelectList
                minWidth="190px"
                defaultLabel="All Roles"
                borderColor="var(--cox2m-clr-brand-400)"
                iconColor="var(--cox2m-clr-brand-600)"
                options={ROLES_TYPES_OPTIONS}
                height="var(--cox2m-spacing-10-units)"
                id="role-selector"
                on:selectedOptionChange={e => (selectedRoleType = e.detail.value)} />
            </div>
            <div class="d-flex">
              <SelectList
                minWidth="190px"
                options={ROLE_STATUS_OPTIONS}
                defaultLabel="All Statuses"
                borderColor="var(--cox2m-clr-brand-400)"
                iconColor="var(--cox2m-clr-brand-600)"
                height="var(--cox2m-spacing-10-units)"
                id="status-selector"
                on:selectedOptionChange={e => (selectedRoleStatus = e.detail.value)} />
            </div>
          </div>
        </div>

        <div class="col-12 m-0 mt-3 p-0">
          <div class="align-items-center justify-content-between row">
            <Table
              id="roles-management-table"
              {columns}
              rows={filteredGroupList}
              on:openDisableRoleModal={e => showDisableRoleModalHandler(e)}
              on:openEnableRoleModal={e => showEnableRoleModalHandler(e)}
              pagination
              isLoading={tableLoading}
              notFoundMessage={`No roles found, try adjusting your filters or
              search terms`}
              itemsPerPage={20} />
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
