<script context="module">
  export const EditUserTabs = {};
</script>

<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import ToggleSwitch from '@cox2m/city-services-ui-components/src/forms/ToggleSwitch.svelte';
  import Item from './Item.svelte';

  import {
    Tabs,
    TabList,
    TabPanel,
    Tab
  } from '@cox2m/city-services-ui-components/src/components/tabs/tabs';
  import {createEventDispatcher} from 'svelte';
  import {user} from 'web-stores';
  import {
    getEventDefinitionsGroupedByGroup,
    getPermissionsByAppSlug,
    getUserByClientId,
    getApps
  } from 'web-actions';

  export let userToEdit = null;

  export let allPermissionsList = [];
  let initialAllPermissionsState;

  const dispatch = createEventDispatcher();

  let fetchInformationError = false;
  let loadingAppPermissions = true;
  let apps = [];

  let userPermissions = [];
  let allPermissions = {};

  let appsResponse = [];
  let userResponse = [];

  let overriddenPermissions = [];

  const getAppPermissions = async slug => {
    let appPermissions = await getPermissionsByAppSlug($user.token, slug);
    return appPermissions.collection;
  };

  const buildUserPermissionsList = async () => {
    loadingAppPermissions = true;

    appsResponse = await getApps({token: $user.token});
    userResponse = await getUserByClientId($user.token, userToEdit.clientId);

    if (appsResponse.fulfilled && userResponse.fulfilled) {
      let settingsApp = {
        name: 'Settings',
        appId: null,
        description: 'city services platform test description',
        slug: 'settings',
        permissions: allPermissionsList.filter(permission =>
          permission.value.includes('platform:')
        )
      };
      let tempApps = [];

      userPermissions = userResponse.permissions;

      allPermissionsList.forEach(permission => {
        allPermissions[permission.value] = {
          active: userPermissions.some(
            userPermission => userPermission.value === permission.value
          )
        };
      });

      initialAllPermissionsState = JSON.parse(JSON.stringify(allPermissions));

      tempApps = await appsResponse.apps.reduce(
        async (accumulatorPromise, current, index) => {
          let accumulator = await accumulatorPromise;
          const appPermissions = await getAppPermissions(current.slug);
          let appGroupedAlerts = await getEventDefinitionsGroupedByGroup(
            $user.token,
            current.slug
          );
          accumulator[index] = {
            ...current,
            permissions: appPermissions,
            alerts: appGroupedAlerts.alertGroups || []
          };
          return accumulator;
        },
        []
      );

      apps = [settingsApp, ...tempApps];
    } else {
      fetchInformationError = true;
    }

    loadingAppPermissions = false;
  };

  const addOrRemoveOverriddenPermission = (permissionToOverridde, isActive, dispatchCheck = false) => {
    let isAlreadyOverridden = overriddenPermissions.some(
      permission => permission.value === permissionToOverridde.value
    );
    let isEqualToInitial =
      isActive ===
      initialAllPermissionsState[permissionToOverridde.value].active;

    if (isAlreadyOverridden && isEqualToInitial) {
      overriddenPermissions = overriddenPermissions.filter(
        overriddenPermission =>
          overriddenPermission.value !== permissionToOverridde.value
      );
    } else if (
      !isEqualToInitial &&
      !overriddenPermissions.some(
        overridden => overridden.value === permissionToOverridde.value
      )
    ) {
      overriddenPermissions = [
        ...overriddenPermissions,
        {...permissionToOverridde, isActive}
      ];
    }

    dispatchCheck && dispatch('checkOverriddenPermissions', overriddenPermissions);
  };

  const addOrRemoveGroupOfPermissions = (e, alertsGroup, dispatchCheck = false) => {
    alertsGroup.forEach(alertGroupPermission => {
      alertGroupPermission.value = alertGroupPermission.permissionValue;
      allPermissions[alertGroupPermission.permissionValue].active =
        e.target.checked;

      addOrRemoveOverriddenPermission(
        allPermissionsList.find(
          permission => permission.value === alertGroupPermission.value
        ),
        e.target.checked,
        false
      );
    });

    dispatchCheck && dispatch('checkOverriddenPermissions', overriddenPermissions);
  };

  const checkIfAlertsAreOn = (parentAlertPermissionValue, alerts) => {
    allPermissions[
      parentAlertPermissionValue
    ].active = alerts.some(alertGroup =>
      alertGroup.eventsDefinitionByGroup.some(
        alertGroupPermission =>
          allPermissions[alertGroupPermission.permissionValue].active
      )
    );

    const alertPermission = allPermissionsList.find(permission => permission.value === parentAlertPermissionValue);

    addOrRemoveOverriddenPermission(
      alertPermission,
      allPermissions[parentAlertPermissionValue].active
    );
  };

  const toggleAlerts = (e, alerts) => {
    alerts.forEach(alertGroup => addOrRemoveGroupOfPermissions(e, alertGroup.eventsDefinitionByGroup, false));

    dispatch('checkOverriddenPermissions', overriddenPermissions);
  }

  $: buildUserPermissionsList(userToEdit.groupNames);
</script>

<style>
  .tab-panel-content-container {
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    padding: var(--cox2m-spacing-6-units );
    padding-top: var(--cox2m-spacing-12-units);
  }
  .tab-panel-item-container {
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-300);
    border-radius: var(--cox2m-spacing-2-units );
    margin-bottom: var(--cox2m-spacing-4-units );
  }
  .tab-panel-content-container.disabled .tab-panel-item-container {
    background: var(--cox2m-clr-neutral-100);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-500);
  }
  .item-checkbox {
    position: relative;
    top: calc(var(--cox2m-spacing-1-units) + 1px);
  }
  .tablist-container .col:first-of-type {
    margin-left: var(--cox2m-spacing-8-units ) !important;
  }
  .tablist-container .col:last-of-type {
    margin-right: var(--cox2m-spacing-8-units ) !important;
  }
  .tabs-error p {
    font-size: var(--cox2m-fs-600);
    color: var(--cox2m-clr-brand-600);
    width: 90%;
  }
  .loading-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
</style>

{#if loadingAppPermissions}
  <div
    class="w-100 loading-container d-flex align-items-center
    justify-content-center">
    <LoadingSpinner />
  </div>
{:else if !loadingAppPermissions && fetchInformationError}
  <div
    class="tabs-error w-100 h-100 d-flex align-items-center
    justify-content-center">
    <p>
      We are sorry! we had an unexpected error, please refresh the page or try
      selecting the user again from the table.
    </p>
  </div>
{:else}
  <Tabs>
    <TabList>
      <div
        class="d-flex tablist-container"
        id="edit-user-modal-tablist"
        data-testid="edit-user-modal-tablist">
        {#each apps as app}
          <div
            class="col m-0 px-0"
            id={app.slug + '-tab'}
            data-testid={app.slug + '-tab'}>
            <Tab>{app.name}</Tab>
          </div>
        {/each}
      </div>
    </TabList>
    {#each apps as app}
      <TabPanel>
        <div
          id={app.slug + '-tab-panel-container'}
          data-testid={app.slug + '-tab-panel-container'}
          class="tab-panel-content-container {$user.token.substring(0, 8) === userToEdit.clientId ? 'disabled' : ''}">
          {#each app.permissions as permission, index (permission.id)}
            {#if !permission.value.includes('alert') || permission.value.includes('alerts:*') || permission.value.endsWith('alerts')}
              <div
                class="tab-panel-item-container"
                id={`${app.slug}-tab-permission-${index}`}
                data-testid={`${app.slug}-tab-permission-${index}`}>
                <Item
                  id={permission.id}
                  title={permission.name}
                  description={permission.description}
                  showExpander={permission.value.endsWith('alerts:*') && app.alerts.length}
                  subItem={false}>
                  <span slot="item-checkbox" class="item-checkbox">
                    <ToggleSwitch
                      id={`${permission.id}-switch`}
                      disabled={$user.token.substring(0, 8) === userToEdit.clientId || userToEdit.status === 'blocked'}
                      onChange={(e) => {
                        addOrRemoveOverriddenPermission(permission, allPermissions[permission.value].active, true)
                        permission.value.endsWith('alerts:*') && toggleAlerts(e, app.alerts);
                        }}
                      bind:checked={allPermissions[permission.value].active} />
                  </span>
                  {#if permission.value.endsWith('alerts:*')}
                    {#each app.alerts as alertGroup}
                      <Item
                        id={`${permission.id}-group-${alertGroup.slug}`}
                        title={alertGroup.slug === 'none' ? 'default' : alertGroup.slug}
                        showExpander={true}
                        subItem={true}
                        nestingNumber={1}>
                        <span slot="item-checkbox" class="item-checkbox">
                          <ToggleSwitch
                            id={`${permission.id}-group-${alertGroup.slug}-switch`}
                            disabled={$user.token.substring(0, 8) === userToEdit.clientId || userToEdit.status === 'blocked'}
                            onChange={e => {
                              addOrRemoveGroupOfPermissions(e, alertGroup.eventsDefinitionByGroup, true);
                              checkIfAlertsAreOn(permission.value, app.alerts);
                            }}
                            checked={alertGroup.eventsDefinitionByGroup.some(alertGroupPermission => allPermissions[alertGroupPermission.permissionValue].active)} />
                        </span>

                        {#each alertGroup.eventsDefinitionByGroup as alertGroupPermission}
                          <Item
                            id={alertGroupPermission.eventDefinitionId}
                            title={alertGroupPermission.permissionName}
                            description={alertGroupPermission.permissionDescription}
                            showExpander={false}
                            subItem={true}
                            nestingNumber={2}>
                            <span slot="item-checkbox" class="item-checkbox">
                              <ToggleSwitch
                                id={`${alertGroupPermission.eventDefinitionId}-switch`}
                                disabled={$user.token.substring(0, 8) === userToEdit.clientId || userToEdit.status === 'blocked'}
                                onChange={() => {
                                  addOrRemoveOverriddenPermission(allPermissionsList.find(permission => permission.value === alertGroupPermission.permissionValue), allPermissions[alertGroupPermission.permissionValue].active, true );
                                  checkIfAlertsAreOn(permission.value, app.alerts);
                                }}
                                bind:checked={allPermissions[alertGroupPermission.permissionValue].active} />
                            </span>
                          </Item>
                        {/each}
                      </Item>
                    {/each}
                  {/if}
                </Item>
              </div>
            {/if}
          {/each}
        </div>
      </TabPanel>
    {/each}
  </Tabs>
{/if}
