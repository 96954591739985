<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {InviteStrategies} from 'web-constants';
  import {stepContainerClass} from './../styles';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  function selectInviteStrategy(selectedStrategy) {
    return function() {
      dispatch('goNextStep', {
        inviteStrategy: selectedStrategy
      });
    };
  }

  const OPTIONS = [
    {
      icon: 'notebook',
      text: '+ Add User via Active Directory',
      onClick: selectInviteStrategy(InviteStrategies.ACTIVE_DIRECTORY)
    },
    {
      icon: 'email',
      text: '+ Add User via Email Invite',
      onClick: selectInviteStrategy(InviteStrategies.REGULAR_EMAIL)
    }
  ];
</script>

<style>
  .options-container {
    flex-grow: 1;
  }

  .option {
    height: var(--cox2m-spacing-19-units);
    display: grid;
    grid-template-columns: 20% 1fr;
    margin: 1em 0px;
    border-radius: 5px;
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-300);
    align-items: center;
    cursor: pointer;
  }

  .option:hover {
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-brand-500);
    color: var(--cox2m-clr-brand-500);
  }

  .icon-container {
    justify-self: center;
  }

  .title-paragraph {
    margin: 0px 0px 1em 0px;
  }

  p {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

<div class={stepContainerClass}>
  <h3 id="first-step-title" data-testid="first-step-title">How would you like to add this user?</h3>
  <p class="title-paragraph fs-1000">
    Choose from the options below to get started adding a new user.
  </p>
  <div class="options-container">
    {#each OPTIONS as opt}
      <div class="option" on:click={opt.onClick}>
        <div class="icon-container">
          <Icon icon={opt.icon} size="var(--cox2m-spacing-9-units)" color="var(--cox2m-clr-neutral-black)" />
        </div>
        <p class="fs-1000">
          <strong>{opt.text}</strong>
        </p>
      </div>
    {/each}
  </div>
</div>
