<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import RolesForm from 'web-forms/roles/RolesForm.svelte';

  import {menuOpts, user} from 'web-stores';
</script>

<Modal>
  <MainScreen title="Roles" selected="Roles" showAlertsBanner={false} menuOpt={$menuOpts} user={$user} appSlug="platform" smartCitiesUrl={true}>
    <div slot="main-dashboard" class="d-block h-100">
      <RolesForm />
    </div>

    <span
      slot="main-header"
      class="col d-none d-sm-flex d-md-none justify-content-center">
    </span>
  </MainScreen>
</Modal>
