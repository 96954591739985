<script>
  import clickOutside from '@cox2m/city-services-ui-components/src/svelte-actions/clickOutside';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import ViewAsUserModal from './ViewAsUserModal.svelte';

  import {createEventDispatcher} from 'svelte';
  import {fly} from 'svelte/transition';
  import {user} from 'web-stores';

  export let userToEdit;

  let showViewAsUserModal = false;

  const dispatch = createEventDispatcher();

  let expanded = false;

  const handleItemAction = (e, callback) => {
    e.cancelBubble = true;
    expanded = false;
    callback();
  };

  const isBlocked = () => {
    return $user.token.substring(0, 8) !== userToEdit.clientId && userToEdit.status === 'blocked'
  }

  const isBlockable = () => {
    return $user.organizationId === userToEdit.organization.id
  }
</script>

<style>
  .actions-container {
    min-width: 190px;
    background-color: var(--cox2m-clr-neutral-white);
    z-index: 12;
    border-radius: 6px;
    right: calc(var(--cox2m-spacing-2-units) * -1);
    top: var(--cox2m-spacing-8-units);
    border: 1px solid var(--cox2m-clr-neutral-500);
  }

  .option {
    cursor: pointer;
  }
  .option:hover {
    background-color: var(--cox2m-clr-brand-200);
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
  }

  @media only screen and (max-width: 1440px) {
    .option {
      font-size: var(--cox2m-fs-1000);
    }
  }
</style>

<ViewAsUserModal userToViewAs={userToEdit} bind:show={showViewAsUserModal} />

<div
  class="position-relative cursor-pointer d-flex align-items-center"
  title="more options"
  use:clickOutside={() => {
    expanded = false;
  }}
  id="{userToEdit.clientId}-user-modal-actions"
  data-testid="{userToEdit.clientId}-user-modal-actions"
  on:click={() => {
    expanded = !expanded;
  }}>
  <Icon
    icon="more-horiz"
    color="var(--cox2m-clr-brand-700)"
    className="font-weight-bold"
    size="calc(var(--cox2m-spacing-4-units) + var(--cox2m-visual-corrector))" />
  {#if expanded}
    <div
      in:fly={{y: 50, duration: 500}}
      out:fly={{y: 50, duration: 500}}
      class="actions-container position-absolute py-2"
      id={`${userToEdit.clientId}-modal-actions-container`}
      data-testid={`${userToEdit.clientId}-modal-actions-container`}>

      {#if isBlockable()}
        {#if !isBlocked()}
          <p
            class="w-100 option py-2 px-3 m-0"
            id={`${userToEdit.clientId}-block-modal-option`}
            data-testid={`${userToEdit.clientId}-block-modal-option`}
            on:click={e => handleItemAction(e, () =>
              dispatch('openBlockUserModal', {
                userToBlock: userToEdit
              })
            )}>
            Block user
          </p>
        {/if}
        {#if isBlocked()}
          <p
            class="w-100 option py-2 px-3 m-0"
            id={`${userToEdit.clientId}-unblock-modal-option`}
            data-testid={`${userToEdit.clientId}-unblock-modal-option`}
            on:click={e => handleItemAction(e, () =>
              dispatch('openUnblockUserModal', {
                userToUnblock: userToEdit
              })
            )}>
            Unblock user
          </p>
        {/if}
      {/if}

      {#if $user && $user.operations && $user.operations.includes('post-dummy-user-groups') && $user.token.substring(0, 8) !== userToEdit.clientId}
        <p
          class="w-100 option py-2 px-3 m-0"
          id={`${userToEdit.clientId}-mock-user-modal-option`}
          data-testid={`${userToEdit.clientId}-mock-user-modal-option`}
          on:click={e => handleItemAction(e, () => (showViewAsUserModal = true))}>
          View as user
        </p>
      {/if}
    </div>
  {/if}
</div>
