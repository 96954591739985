<script>
  import UnauthorizedFlowScreen from 'web-forms/login/UnauthenticatedFlowScreen.svelte';
  import ChangePasswordForm from 'web-forms/login/components/ChangePasswordForm.svelte';
  import {cleanCookies} from "../funcs";
  import {onMount} from "svelte";

  onMount(() => {
    cleanCookies({shouldRemoveLatestVisitedUrl: false});
  });
</script>

<UnauthorizedFlowScreen title="Choose a new password">
  <ChangePasswordForm />
</UnauthorizedFlowScreen>

