<script>
  import AlertsScreen from '@cox2m/city-services-ui-components/src/components/alerts/AlertsScreen.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {findByKeyRecursively} from '@cox2m/city-services-ui-components/src/funcs';
  import {push, location} from 'svelte-spa-router';
  import {menuOpts, user} from 'web-stores';
  import {buildMenuWithDynamicItems} from "../menuOptions/populate-menu";

  export let params;

  let applicationsFetchError = false;
  let loading = true;

  let selected = null;

  const findSelectedValue = () => {
    try{
      if(params && params.path){
        selected = findByKeyRecursively({searchCriteria: `alerts-${params.path}`, array: $menuOpts});
        loading = false;
      }else{
        let tempSelection = findByKeyRecursively({searchCriteria: `alerts`, array: $menuOpts});
        push(tempSelection.subItems[0].path);
      }
    }catch(e){
      selected = null;
      loading = true;
    }
  }

  /**
   * Reload the alerts menu to update the alert icon when an alert is created/deleted
   * @returns {Promise<void>}
   */
  const reloadAlertMenu = async () => {
    menuOpts.updateMenuValues(await buildMenuWithDynamicItems($user));
  };

  $: findSelectedValue($menuOpts, $location);
</script>

<style>
  @media (max-width: 767.98px) {
    div {
      height: 100%;
    }
  }
  .loading-container,
  .error-container {
    width: 100%;
    height: 80vh;
    margin-top: var(--cox2m-spacing-8-units);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-message {
    color: var(--cox2m-clr-neutral-700);
  }
</style>

<Modal>
  <MainScreen title="Alerts" menuOpt={$menuOpts} user={$user} showAlertsBanner={false}>
    <div slot="main-dashboard">
    {#if loading || !selected}
      <div class="loading-container">
      <div>
          <LoadingSpinner />
        </div>
      </div>
    {:else}
      {#if !applicationsFetchError && selected}
          <AlertsScreen
            appName={selected.props && selected.props.appName || ''}
            id={selected.props && selected.props.id}
            on:alertCreationOrDeletion={reloadAlertMenu}
          />
      {:else}
          <div class="error-container">
            <div>
              <h2
                class="error-message"
                data-testid="alerts-fetching-error-message">
                We are sorry! please refresh the page or try again later
              </h2>
            </div>
          </div>
      {/if}
    {/if}
  </div>
  </MainScreen>
</Modal>
