<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import FormTextInput from '@cox2m/city-services-ui-components/src/forms/FormTextInput.svelte';
  import SelectInput from '@cox2m/city-services-ui-components/src/forms/SelectInput.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import PermissionCheckRow from './components/PermissionCheckRow.svelte';
  import MultiSelect from './components/MultiSelect.svelte';

  import {EMAIL_REGEX, PHONE_NUMBER_REGEX} from 'web-constants';
  import {asyncForEach} from 'web-funcs';
  import {
    textInputErrorClass,
    saveChangesButtonActiveClass
  } from './components/styles.js';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';
  import {
    getGroups,
    getDepartments,
    getUserByClientId,
    getPermissionsByGroup,
    updateUserUsingClientId
  } from 'web-actions';

  export let clientId;

  let name = '';
  let jobTitle = '';
  let phoneNumber = '';
  let email = '';
  let domain = '';
  let selectedGroup = '';
  let selectedDepartment = '';
  let departmentList = [];
  let groupList = [];
  let permissionsList = [];

  let loadingSubmit = false;
  let isNameCorrect = true;
  let isEmailCorrect = true;
  let isJobTitleCorrect = true;
  let isPhoneNumberCorrect = true;
  let isDomainCorrect = true;
  let isGroupCorrect = true;
  let isDepartmentCorrect = true;

  let errorMessage = '';
  let successMessage = '';

  $: permissionRows = permissionsList;
  $: hasEditProfileInformation =
    name.trim().length !== 0 &&
    email.trim().length !== 0 &&
    jobTitle.trim().length !== 0 &&
    phoneNumber.trim().length !== 0 &&
    selectedGroup.trim().length !== 0 &&
    selectedDepartment.trim().length !== 0;

  $: isInformationValid =
    EMAIL_REGEX.test(email) && PHONE_NUMBER_REGEX.test(phoneNumber);

  const cleanErrorsAndMessages = () => {
    isNameCorrect = true;
    isEmailCorrect = true;
    isJobTitleCorrect = true;
    isPhoneNumberCorrect = true;
    isDomainCorrect = true;
    isGroupCorrect = true;
    isDepartmentCorrect = true;
    errorMessage = '';
    successMessage = '';
  };

  const handleGroupChange = async value => {
    handleTextChange();
    selectedGroup = value;
    const PERMITS = [];
    if (selectedGroup) {
      await asyncForEach(selectedGroup.split(','), async groupVal => {
        const {groupId} = groupList.find(group => group.value === groupVal);
        const getPermissionsResponse = await getPermissionsByGroup(
          $user.token,
          groupId,
          {limit: 100}
        );
        if (getPermissionsResponse.fulfilled) {
          PERMITS.push(...getPermissionsResponse.permissions);
        }
      });
    }
    permissionsList = [...new Set(PERMITS.map(permit => JSON.stringify(permit)))].map(str => JSON.parse(str));
  };

  const handleTextChange = () => {
    cleanErrorsAndMessages();
  };

  const validateEditProfileInformation = () => {
    cleanErrorsAndMessages();
    if (name.trim() === '') {
      errorMessage = 'Please type a name.';
      isNameCorrect = false;
    } else if (email.trim() === '') {
      errorMessage = 'Please type an email address.';
      isEmailCorrect = false;
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = 'Please type a valid email address.';
      isEmailCorrect = false;
    } else if (jobTitle.trim() === '') {
      errorMessage = 'Please type a job title.';
      isJobTitleCorrect = false;
    } else if (phoneNumber.trim() === '') {
      errorMessage = 'Please type a phone number.';
      isPhoneNumberCorrect = false;
    } else if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      errorMessage = 'Please type a valid phone number. E.g.: +XXXXXXXXX or XXX-XXX-XXXX';
      isPhoneNumberCorrect = false;
    } else if (
      selectedGroup.trim() === '' ||
      selectedGroup === 'Select an option'
    ) {
      errorMessage = 'Please select a group.';
      isGroupCorrect = false;
    } else if (
      selectedDepartment.trim() === '' ||
      selectedDepartment === 'Select an option'
    ) {
      errorMessage = 'Please select a department.';
      isDepartmentCorrect = false;
    } else {
      errorMessage = '';
    }
  };

  let loading = false;

  onMount(async () => {
    loading = true;
    const getGroupsDtoList = await getGroups({token: $user.token}, {active: true});
    if (getGroupsDtoList.fulfilled) {
      getGroupsDtoList.collection.forEach(function (item) {
        groupList.push({
          value: item.name,
          label: item.name,
          groupId: item.groupId
        });
      });
      groupList = [...groupList];
    }
    const getDepartmentDtoList = await getDepartments({token: $user.token});
    if (getDepartmentDtoList.fulfilled) {
      getDepartmentDtoList.collection.forEach(function (it) {
        departmentList.push({value: it.name, label: it.name});
      });
      departmentList = [...departmentList];
    }

    const userResponse = await getUserByClientId($user.token, clientId);
    if (userResponse.fulfilled) {
      const client = userResponse;
      name = `${client.givenName || ''} ${client.middleName ||
      ''} ${client.familyName || ''}`
        .replace(/  +/g, ' ')
        .trim();
      jobTitle = `${client.title || ''}`.trim();
      email = client.email;
      phoneNumber = `${client.phoneNumber || ''}`.trim();
      domain = `${client.organization.name || ''}`.trim();
      selectedGroup = client.groupNames ? client.groupNames.join(',') : '';
      selectedDepartment = `${client.departmentName || ''}`.trim();
      permissionsList = client.permissions;
    }
    loading = false;
  });

  const handleSaveChangesClick = async () => {
    validateEditProfileInformation();
    if (hasEditProfileInformation && isInformationValid) {
      loadingSubmit = true;
      let requestResponse = await updateUserUsingClientId(
        $user.token,
        clientId,
        {
          phoneNumber,
          name: name,
          title: jobTitle,
          departmentName: selectedDepartment,
          groupNames: selectedGroup.split(',')
        }
      );
      if (requestResponse.fulfilled) {
        successMessage = 'User succesfully updated.';
      } else {
        errorMessage = 'Error updating the user.';
      }
      loadingSubmit = false;
    }
  };
</script>

<style>
  h4 {
    font-weight: var(--cox2m-fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .title-border {
    border-top: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-400);
  }

  p {
    color: var(--cox2m-clr-neutral-500);
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
  }

  .success-message {
    color: var(--cox2m-clr-success-400);
  }

  .container {
    background-color: var(--cox2m-clr-neutral-white);
  }

  .spinner-border {
    align-self: flex-end;
    width: var(--cox2m-spacing-11-units);
    height: var(--cox2m-spacing-11-units);
    margin: 0px 0px 0.5em 0.5em;
  }
  .fs-600{
    font-size: var(--cox2m-fs-600);
  }
  .fs-900{
    font-size: var(--cox2m-fs-900);
  }
</style>

<div class="container col-md-12 order-md-1">
  <div class="col-md-12 order-md-1">
    <h4 class="py-3 m-0 fs-600">User details</h4>
    <div class="row">
      <div class="col-md-6 mb-3">
        <FormTextInput
          id="manage-user-name-text"
          name="Name"
          placeholder="Name"
          bind:value={name}
          onChange={handleTextChange}
          className={!isNameCorrect ? textInputErrorClass : ''}/>
      </div>
      <div class="col-md-6 mb-3">
        <FormTextInput
          id="manage-user-email-text"
          name="Email Address"
          placeholder="Email Address"
          disabled="true"
          bind:value={email}
          onChange={handleTextChange}
          className={!isEmailCorrect ? textInputErrorClass : ''}/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <FormTextInput
          id="manage-user-job-title-text"
          name="Job Title"
          placeholder="Job Title"
          bind:value={jobTitle}
          onChange={handleTextChange}
          className={!isJobTitleCorrect ? textInputErrorClass : ''}/>
      </div>
      <div class="col-md-6 mb-3">
        <FormTextInput
          id="manage-user-phone-number-text"
          name="Phone number"
          placeholder="Phone number"
          bind:value={phoneNumber}
          onChange={handleTextChange}
          className={!isPhoneNumberCorrect ? textInputErrorClass : ''}/>
      </div>
    </div>
    <h4 class="title-border py-3 m-0 fs-600">Domain and Roles</h4>
    <div class="row">
      <div class="col-md-6 mb-3">
        <SelectInput
          id="manage-user-domain-select"
          label="Domain"
          bind:value={domain}
          customClass={!isDomainCorrect ? textInputErrorClass : ''}
          options={[]}
          disabled
          onChange={handleTextChange}
          defaultOption="Choose domain"/>
      </div>
      <div class="col-md-6 mb-3 d-flex">
        <MultiSelect
          items={groupList}
          id="manage-user-group-select"
          label="Role"
          bind:value={selectedGroup}
          onChange={handleGroupChange}
          customClass={!isGroupCorrect ? textInputErrorClass : ''}/>
        {#if loading}
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        {/if}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3 d-flex">
        <SelectInput
          id="manage-user-department-select"
          label="Department"
          bind:value={selectedDepartment}
          customClass={!isDepartmentCorrect ? textInputErrorClass : ''}
          options={departmentList}
          onChange={handleTextChange}
          defaultOption="Choose department"/>
        {#if loading}
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        {/if}
      </div>
      <div class="col-md-6 mb-3"/>
    </div>
    {#if permissionRows !== null && permissionRows.length !== 0}
      <h4 id="manage-user-permissions-title" class="title-border py-3 m-0 fs-600">
        User permissions
      </h4>
      <div id="manage-user-permissions-list" class="row">
        {#each permissionRows as permission}
          <PermissionCheckRow
            title={permission.name}
            message={permission.description || '\n'}/>
        {/each}
      </div>
    {/if}

    <div
      class="col-md-12 d-flex align-items-center justify-content-end py-3 px-0">
      {#if errorMessage}
        <div class="d-flex align-items-center m-0 mr-3">
          <Icon icon="cross-circle" color="var(--cox2m-clr-critical-500)" size="var(--cox2m-spacing-6-units)" className="mr-2"/>
          <p class="error-message m-0 fs-900">{errorMessage}</p>
        </div>
      {/if}
      {#if successMessage}
        <div class="d-flex align-items-center m-0 mr-3">
          <Icon icon="check-circle" color="var(--cox2m-clr-success-400)" size="var(--cox2m-spacing-6-units)" className="mr-2"/>
          <p class="success-message m-0 fs-900">{successMessage}</p>
        </div>
      {/if}
      {#if !loadingSubmit}
        <button
          id="manage-user-save-profile-button"
          type="button"
          on:click={handleSaveChangesClick}
          class="{hasEditProfileInformation && isInformationValid ? saveChangesButtonActiveClass : 'btn btn-secondary'}
          m-0">
          Save Changes
        </button>
      {:else}
        <LoadingSpinner/>
      {/if}
    </div>
  </div>
</div>
