<script>
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import ActiveDirectoryDefaultStep from './ActiveDirectoryDefaultStep.svelte';
  import EmailDefaultStep from './EmailDefaultStep.svelte';
  import {InviteStrategies} from 'web-constants';
  import {stepContainerClass, buttonActiveClass} from '../styles';
  export let stepperData;

  let processFinished = false;
  export let handleFinishClick = () => {};
  const terminateWizard = () => {
    processFinished = true;
  };
</script>

<style>
  .buttons-container {
    margin-top: auto;
    margin-bottom: 1em;
    text-align: right;
  }
</style>

{#if !processFinished}
  {#if stepperData.inviteStrategy === InviteStrategies.ACTIVE_DIRECTORY}
    <ActiveDirectoryDefaultStep on:goPrevStep on:inviteSent={terminateWizard} />
  {:else}
    <EmailDefaultStep on:goPrevStep on:inviteSent={terminateWizard} />
  {/if}
{:else}
  <div class={stepContainerClass}>
    <header>
      <h3 id="invitation-sent-header">
        Your invitation to add this user has been sent.
      </h3>
    </header>
    <p>
      Once the user has accepted your invitation you will be notified and you
      will be able to see them in your "Users" dashboard
    </p>
    <div class="buttons-container">
      <Button
        id="close-button"
        text="Close"
        on:click={handleFinishClick}
        customClass={buttonActiveClass} />
    </div>

  </div>
{/if}
