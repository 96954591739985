<script>
  import AllAlertsView from '@cox2m/city-services-ui-components/src/components/alerts/AllAlertsView.svelte'
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {menuOpts, user} from 'web-stores';
</script>

<Modal>
  <MainScreen title="Alerts Test" menuOpt={$menuOpts} user={$user} showAlertsBanner={true} appSlug="platform" smartCitiesUrl={true}>
    <div slot="main-dashboard" class="d-block h-100">
      <AllAlertsView />
    </div>
  </MainScreen>
</Modal>
