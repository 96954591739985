<script>
  import Popper from '@cox2m/city-services-ui-components/src/components/Popper.svelte';

  export let props;
</script>

<style>
  span{
    text-transform: lowercase;
  }
</style>

<div>
  {#if props.user.hasOverriddenPermissions}
    <Popper
      showEvents={['mouseenter']}
      hideEvents={['mouseleave', 'blur', 'click', 'focus']}>
      <span slot="tooltip-trigger" class="m-0 p-0">Custom</span>
      <div slot="tooltip-component">
        <span>{props.groups}</span>
      </div>
    </Popper>
  {:else if props.groups.length == 1}
    <Popper
      showEvents={['mouseenter']}
      hideEvents={['mouseleave', 'blur', 'click', 'focus']}>
      <span class="m-0 p-0" slot="tooltip-trigger">
        {props.groups[0].length > 8 ? props.groups[0].substring(0, 8) + '...' : props.groups[0]}
      </span>
      <div slot="tooltip-component">
        <span>{props.groups}</span>
      </div>
    </Popper>
  {:else}
    <Popper
      showEvents={['mouseenter']}
      hideEvents={['mouseleave', 'blur', 'click', 'focus']}>
      <span class="m-0 p-0" slot="tooltip-trigger">+{props.groups.length}</span>
      <div slot="tooltip-component">
        <span>{props.groups}</span>
      </div>
    </Popper>
  {/if}
</div>
