<script>
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import TextArea from '@cox2m/city-services-ui-components/src/forms/TextArea.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {setSuccessToast} from '@cox2m/city-services-ui-components/src/funcs';
  import {putUsersClientIdBlock} from 'web-actions';
  import {createEventDispatcher} from 'svelte';
  import {fade} from 'svelte/transition';
  import {user} from 'web-stores';

  const dispatch = createEventDispatcher();

  export let show = false;
  export let userToBlock = null;

  let reason = '';
  let emptyReason = false;
  let addErrorMessage = false;
  let inputError = false;

  const handleBlockUser = async () => {
    if (!reason.length) {
      emptyReason = true;
      addErrorMessage = true;
      inputError = true;
    } else {
      show = false;
      inputError = false;
      const response = await putUsersClientIdBlock(
        $user.token,
        userToBlock.clientId,
        reason
      );
      if (response.fulfilled) {
        dispatch('blockUserSuccess');
        setSuccessToast(window.dispatchEvent, 'User was blocked successfully');
      }
    }
  };
</script>

<style>
  .modal-content {
    max-width: 680px;
    box-shadow: var(--cox2m-shadow-6-dp-dir) var(--cox2m-shadow-6-dp-clr);
  }
  .disclaimer-icon {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  #empty-reason-message {
    color: var(--cox2m-clr-neutral-700);
    font-size: var(--cox2m-fs-1200);
  }
  .note-label{
    font-size: var(--cox2m-fs-1000);
  }
  .note-label.error{
    color: var(--cox2m-clr-critical-700);
    font-weight: var(--cox2m-fw-bold);
  }

  .modal-backdrop {
    z-index: 1100;
  }
  .modal.fade.show {
    z-index: 1101;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

{#if show}
  <div class="modal-backdrop fade show" transition:fade/>
  <div
    class="modal fade show d-block"
    id="block-user-modal"
    data-testid="block-user-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="block-user-modal-title"
    aria-hidden="true"
    transition:fade>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pl-4 border-0">
        <div class="modal-body">
          <div class="row m-0 justify-content-between align-items-center">
            <h5
              class="modal-title font-weight-bold
              position-relative"
              id="block-user-modal-title"
              data-testid="block-user-modal-title">
              <span class="position-absolute disclaimer-icon">
                <Icon
                  icon="info"
                  size="var(--cox2m-spacing-6-units)"
                  color="var(--cox2m-clr-brand-600)" />
              </span>
              Block this user
            </h5>
            <Icon
              icon="cross"
              size="calc(var(--cox2m-spacing-4-units) + var(--cox2m-visual-corrector))"
              className="m-0 p-0"
              id="block-user-modal-close"
              onClick={() => (show = false)}
              color="var(--cox2m-clr-neutral-700)" />
          </div>
          <p class="disclaimer fs-1000 mt-3">
            Once blocked, this user will no longer be able to log into Smart
            Security. Blocked users can be restored to regain access.
          </p>

          <div class="block-message-container">
            <span class="font-weight-bold note-label {addErrorMessage ? 'error' : ''}">
              Note *Required*
            </span>
            <div class="pt-1">
              <TextArea
                bind:value={reason}
                id="reason-input"
                placeholder="I want to block this user because..."
                on:validationSuccess={() => {addErrorMessage = false; emptyReason = false; inputError = false}}
                on:validationFailure={() => {addErrorMessage = true; inputError = true;}}
                {inputError}
                validationFunction={() => reason.length} />
            </div>
            {#if emptyReason}
              <p
                id="empty-reason-message"
                data-testid="empty-reason-message"
                class="p-0 m-0">
                * This is a required field, please provide a blockage reason
                before proceeding
              </p>
            {/if}
          </div>
        </div>
        <div class="modal-footer pt-0 mt-0 border-0">
          <div class="row m-0 align-items-center pr-1">
            <div class="d-flex">
              <Button
                customClass="mr-3 "
                color="var(--cox2m-clr-brand-600)"
                backgroundColor="var(--cox2m-clr-neutral-white)"
                borderColor="var(--cox2m-clr-brand-400)"
                text="Cancel"
                id="cancel-block-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => (show = false)} />
            </div>
            <div class="d-flex">
              <Button
                customClass=""
                color="var(--cox2m-clr-neutral-white)"
                backgroundColor="var(--cox2m-clr-brand-600)"
                borderColor="var(--cox2m-clr-brand-600)"
                text="Block"
                id="block-user-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => handleBlockUser()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
