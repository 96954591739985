<script>
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  export let title = "Log in to your account";
  export let subtitle = "";
</script>

<style>
  main {
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    justify-content: center;
    padding-top: var(--cox2m-spacing-12-units);
    background: url(images/city.svg) repeat-x center bottom;
    width: 100%;
    background-size: 50vw 75vh;
    background-position-y: 40vh;
    background-position-x: 50vw;
    height: 100vh;
    overflow-y: auto;
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--cox2m-spacing-4-units) 0;
  }

  h4 {
    font-size: clamp(var(--cox2m-fs-1300), var(--cox2m-fs-1000), var(--cox2m-fs-1000));
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-brand-700);
    line-height: var(--cox2m-spacing-2-units);
    margin: 0;
  }

  #unauthenticated-container {
    width: 526px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1em;
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 8px;
    margin-top: clamp(var(--cox2m-spacing-6-units), var(--cox2m-spacing-18-units), 5.25rem);
    align-self: center;
    margin-bottom: auto;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  :global(body) {
    background: var(--cox2m-clr-brand-200);
  }

  .logo {
    width: clamp(300px, 10vw, 360px);
    align-self: center;
    margin-inline: auto;
  }

  .title {
    margin-top: clamp(var(--cox2m-spacing-8-units), var(--cox2m-spacing-10-units), 2.75rem);
    text-align: center;
    font-weight: var(--cox2m-fw-bold);
    font-size: clamp(var(--cox2m-fs-900), var(--cox2m-fs-700), var(--cox2m-fs-600)) !important;
    line-height: clamp(var(--cox2m-fs-600), var(--cox2m-fs-500), var(--cox2m-fs-500)) !important;
    color: var(--cox2m-clr-neutral-black);
    white-space: pre;
  }

  .subtitle {
    margin-top: var(--cox2m-spacing-3-units);
    font-size: clamp(var(--cox2m-fs-1200), var(--cox2m-fs-1000), var(--cox2m-fs-700)) !important;
    font-weight: var(--cox2m-fw-regular);
    text-align: center;
    margin-bottom: 0;
    }

  .powered-by-logo {
    height: var(--cox2m-spacing-6-units);
  }

  .footer-logo {
    margin-left: var(--cox2m-spacing-8-units);
    align-items: center;
    display: flex;
  }

  .logo-tm {
    background: linear-gradient(93.61deg, var(--cox2m-clr-brand-600) -5.76%, var(--cox2m-clr-brand-700) 79.56%);
    font-size: var(--cox2m-fs-1300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    vertical-align: top;
  }

  @media (max-width: 1200px){
    main{
      background-size: 93vw 60vh;
      background-position-y: 45vh;
    }
    .logo{
      width: 298px;
      height: var(--cox2m-spacing-15-units);
    }
  }

  @media (min-width: 640px) {
    #unauthenticated-container {
      max-width: none;
    }
  }

  @media (max-width: 768px) {
    #unauthenticated-container {
      width: 94vw;
      margin-top: 40px;
      margin-bottom: auto;
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      margin-inline: auto;
    }
  }

  @media (min-width: 1920px) {
    #unauthenticated-container {
      -moz-transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -o-transform: scale(1.25);
      -ms-transform: scale(1.25);
      transform: scale(1.25);
    }
  }
</style>

<main>
  <object class="logo" title="COX2M Logo" data="design-system/media/Cox2m-logo.svg" />
  <div class="title-containers">
    <h1 class="title">{title}</h1>
    {#if subtitle}
      <h2 class="subtitle">{subtitle }</h2>
    {/if}
  </div>
  <div class="modal-container">
    <Modal>
      <div id="unauthenticated-container" class="cox2m-shadow-1-dp">
        <slot />
      </div>
    </Modal>
  </div>
  <footer>
    <h4>powered by</h4>
    <div class="footer-logo">
      <object class="powered-by-logo" title="COX2M Powered By Logo" data="design-system/media/Cox2m-logo.svg" />
      <span class="logo-tm">TM</span>
    </div>
  </footer>
</main>
