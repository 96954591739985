<script>
    import UnauthorizedFlowScreen from 'web-forms/login/UnauthenticatedFlowScreen.svelte';
    import ForgotPasswordForm from "web-forms/login/components/ForgotPasswordForm.svelte";

    let recoveryLinkSent = false;
</script>

<UnauthorizedFlowScreen
  title={!recoveryLinkSent ?
  `Forgot your password?
  Not to worry!` : `Can't log in?`}
  subtitle={!recoveryLinkSent ? "Enter your email address and we’ll send you a link to reset your password." : ''}>
    <ForgotPasswordForm bind:recoveryLinkSent={recoveryLinkSent}/>
</UnauthorizedFlowScreen>

