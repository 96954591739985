import AllAlertsTestCreationModal from './routes/AllAlertsTestCreationModal.svelte';
import ForgotPassword from './routes/ForgotPassword.svelte';
import UsersDashboard from './routes/UsersDashboard.svelte';
import RolesDashboard from './routes/RolesDashboard.svelte';
import ResetPassword from './routes/ResetPassword.svelte';
import Notifications from './routes/Notifications.svelte';
import AllAlertsTest from './routes/AllAlertsTest.svelte';
import NewAlertsMenu from './routes/NewAlertsMenu.svelte';
import Applications from './routes/Applications.svelte';
import EditProfile from './routes/EditProfile.svelte';
import ManageUser from './routes/ManageUser.svelte';
import AlertsMenu from './routes/AlertsMenu.svelte';
import Logout from './routes/Logout.svelte';
import Login from './routes/Login.svelte';
import Start from "./routes/Start.svelte";

import OrganizationSettings from './routes/OrganizationSettings.svelte';
import Monitoring from "./routes/Monitoring.svelte";

import NotFoundPage from './routes/NotFoundPage.svelte';

const routes = {
  '/logout': Logout,
  '/login/:client?': Login,
  '/account/password/reset': ResetPassword,
  '/account/password/forgot': ForgotPassword,

  //use dashboard
  '/': Applications,

  '/start': Start,
  '/alerts': AlertsMenu,
  '/users': UsersDashboard,
  '/roles': RolesDashboard,
  '/alerts/:path': AlertsMenu,
  '/new-alerts/:path': NewAlertsMenu,
  '/notifications': Notifications,
  '/account/manage/:user': ManageUser,
  '/account/profile/edit': EditProfile,
  '/account/profile/edit/:path': EditProfile,
  '/all-alerts-test' : AllAlertsTest,
  '/all-alerts-screen-creation-modal-test/:path': AllAlertsTestCreationModal,

  '/monitoring/logs': Monitoring,
  '/monitoring/organization-settings' : OrganizationSettings,

  '*': NotFoundPage
};

export const openRoutes = [
  '/login', '/login/active-directory', '/account/password/forgot', '/account/password/reset', '/start'
]

export default routes
