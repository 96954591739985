<script>
  import CheckBox from '@cox2m/city-services-ui-components/src/components/CheckBox.svelte';
  import {createEventDispatcher} from 'svelte';

  export let props;

  const dispatch = createEventDispatcher();

  const handleItemAction = (e, callback) => {
    e.cancelBubble = true;
    callback();
  };
</script>

<style>
  .checkbox-column-countainer {
    padding-left: var(--cox2m-spacing-2-units);
  }
</style>

<div class="checkbox-column-countainer">
  <CheckBox
    checked={props.active}
    onClick={e => handleItemAction(e, () =>
        dispatch('exteriorizeTableEvent', {
          eventName: props.active
            ? 'openDisableRoleModal'
            : 'openEnableRoleModal',
          exteriorDetails: props.group
        })
      )} />
</div>
