import {PublicClientApplication, LogLevel} from '@azure/msal-browser'
import {loginWithActiveDirectoryToken} from "web-actions";
import {user} from 'web-stores';

const msalConfig = {
  auth: {
    clientId: "AAD_CLIENT_ID",
    authority: "https://login.microsoftonline.com/organizations/",
    redirectUri: 'SMART_CITIES_URL',
    postLogoutRedirectUri: 'SMART_CITIES_URL/#/logout'
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

let msalClient = new PublicClientApplication(msalConfig);

/**
 * Sign in using MSAL to a Microsoft account
 * @returns {Promise<{fulfilled: boolean}>}
 * @param aadClientId {string}
 * @param loginHint {string}
 * @param eventHandler {EventCallbackFunction}
 * @param additionalLoginInfo
 */
export const signIn = async (aadClientId, loginHint, eventHandler, additionalLoginInfo) =>{
  if (aadClientId) {
    msalConfig.auth.clientId = aadClientId;
    msalClient = new PublicClientApplication(msalConfig);
  }

  if (eventHandler) {
    msalClient.addEventCallback(eventHandler);
  }

  try {
    const msalResponse = await msalClient.loginPopup({
      scopes: ["User.Read"],
      loginHint
    });

    if (msalResponse !== null) {
      const response = await loginWithActiveDirectoryToken(msalResponse.accessToken, additionalLoginInfo)
      const {fulfilled, ...data} = response;

      if (fulfilled) {
        data.email = msalResponse.account.username;
        data.isActiveDirectory = true;
        user.saveUser(data);
        if (document.location.hostname === 'localhost') {
          document.cookie = `${'ENV'}_token=${data.token};`;
        } else {
          const now = new Date();
          const minutes = 60;
          now.setTime(now.getTime() + minutes * 60 * 1000);
          document.cookie = `${'ENV'}_token=${data.token};expires=${now.toUTCString()};path=/;domain=cox2m.com`;
        }

        return { fulfilled: true, ...data }
      }
    }
    return { fulfilled: false, message: "Token response is empty" }
  } catch (ex) {
    return { fulfilled: false, ...ex.body };
  }
}

/**
 * Logs out a user from Microsoft Active Directory
 * @param username
 */
export const logout = async (username) => {
  const logoutRequest = {
    account: msalClient.getAccountByUsername(username)
  };
  await msalClient.logout(logoutRequest);
}

/**
 * Add an event handler
 */
export const addEventCallback = (eventHandler) => {
  msalClient.addEventCallback(eventHandler);
}
