<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MultiSelectList from '@cox2m/city-services-ui-components/src/forms/MultiSelectList.svelte';
  import EditUserRolesConfirmationModal from './components/EditUserRolesConfirmationModal.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Popper from '@cox2m/city-services-ui-components/src/components/Popper.svelte';
  import OverridePermissionModal from './components/OverridePermissionModal.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import UserAvatarLabelColumn from './components/UserAvatarLabelColumn.svelte';
  import EditUserModalActions from './components/EditUserModalActions.svelte';
  import EditUserForm from './components/EditUserForm.svelte';
  import EditUserTabs from './components/EditUserTabs.svelte';

  import {fade} from 'svelte/transition';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';

  import {
    getPermissionsByGroup,
    getAllPermissions,
    getUserByClientId,
    getGroups
  } from 'web-actions';

  export let userToEdit = null;
  export let show = false;

  let initialGroupList = [];
  let groupList = [];
  let loadingView = false;

  let overriddenPermissionList = [];
  let allPermissionsList = [];

  let formDisableSaveButton = true;
  let disableSaveButton = true;

  let userHasOverriddedPermissions =
    userToEdit && userToEdit.hasOverriddenPermissions;

  let showUpdateOverriddenRolesModal = false;
  let showUpdateRolesConfirmation = false;
  let fetchUserError = false;
  let allGroupsSelected;
  let resetOverriddenPermissions = false;

  let blockageReasonToUpdate = null;
  let phoneNumberToUpdate = null;

  const getPermissionsResponse = async groupId => {
    const permissionResponse = await getPermissionsByGroup(
      $user.token,
      groupId,
      {limit: 100}
    );
    if (permissionResponse.fulfilled) {
      return permissionResponse.permissions;
    } else {
      return [];
    }
  };

  const fetchUser = async () => {
    loadingView = true;

    let userUpdateResponse = await getUserByClientId(
      $user.token,
      userToEdit.clientId
    );

    if (userUpdateResponse.fulfilled) {
      userToEdit = userUpdateResponse;
      await buildGroups();
    } else {
      fetchUserError = true;
    }

    loadingView = false;
  };

  const buildGroupsList = async groupListResponse => {
    let list = [];
    if (groupListResponse.fulfilled) {
      list = await groupListResponse.collection.reduce(
        async (accumulatorPromise, current, index) => {
          let accumulator = await accumulatorPromise;
          const groupPermissions = await getPermissionsResponse(
            current.groupId
          );

          accumulator[index] = {
            ...current,
            value: current.name,
            label:
              current.name[0] +
              current.name
                .substring(1, current.name.length)
                .replace(/[-|_]/g, ' ')
                .toLowerCase(),
            groupId: current.groupId,
            active: userToEdit && userToEdit.groupNames.includes(current.name),
            groupPermissions: groupPermissions
          };
          return accumulator;
        },
        []
      );
    }

    return list;
  };

  const buildGroups = async () => {
    loadingView = true;
    if (show) {
      let tempList = [];

      const groupListResponse = await getGroups({token: $user.token}, {active: true});

      tempList = await buildGroupsList(groupListResponse);

      initialGroupList = tempList;
      groupList = tempList;
    }

    loadingView = false;
  };

  const handleCheckboxClick = (e, item, isSelectAll) => {
    e.preventDefault();

    if (isSelectAll) {
      groupList = groupList.map(group => ({
        ...group,
        active: !allGroupsSelected
      }));
    } else {
      groupList = groupList.map(group => {
        if (group.value != item.value) return group;

        return {
          ...group,
          active: !item.active
        };
      });
    }
  };

  const removeBadgeElement = badgeToRemove => {
    groupList = groupList.map(group => {
      if (group.value != badgeToRemove.value) return group;

      return {
        ...group,
        active: false
      };
    });
  };

  const getAllPermissionsList = async () => {
    const allPermissionsResponse = await getAllPermissions($user.token);

    if (allPermissionsResponse.fulfilled) {
      allPermissionsList = allPermissionsResponse.collection;
    }
  };

  const checkDisableCondition = e => {
    overriddenPermissionList = e.detail;
    if (overriddenPermissionList.length === 0) {
      disableSaveButton = true;
      userHasOverriddedPermissions = userToEdit.hasOverriddenPermissions;
    } else {
      userHasOverriddedPermissions = true;
      disableSaveButton = false;
    }
  };

  const handleFormUpdate = e => {
    formDisableSaveButton = e.detail.shouldBlock;
    blockageReasonToUpdate = e.detail.blockage_reason;
    phoneNumberToUpdate = e.detail.phoneNumber;
  };

  const handleOverriddenPermissions = async (
    removeOverriddenPermissions = false
  ) => {
    showUpdateOverriddenRolesModal = true;
    resetOverriddenPermissions = removeOverriddenPermissions;
  };

  const handleSaveUser = async (removeOverriddenPermissions = false) => {
    handleOverriddenPermissions(removeOverriddenPermissions);
  };

  onMount(async () => {
    await getAllPermissionsList();
  });

  //reseters
  $: userToEdit && (overriddenPermissionList = []);
  $: userToEdit && (disableSaveButton = true);
  $: userToEdit && (phoneNumberToUpdate = null);
  $: userToEdit && (blockageReasonToUpdate = null);
  $: userToEdit && userToEdit.groupNames && buildGroups(userToEdit, show);
  $: userToEdit && (resetOverriddenPermissions = false);
  $: userHasOverriddedPermissions =
    userToEdit && userToEdit.hasOverriddenPermissions;

  $: userToEdit &&
    groupList &&
    (allGroupsSelected = groupList.every(group => group.active));
</script>

<style>
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: min(65rem, 100%);
    height: 100%;
    max-width: unset;
  }

  .modal.right .modal-content {
    height: 100%;
  }
  .modal-content {
    border-radius: 0;
    border-bottom-left-radius: var(--cox2m-spacing-4-units);
  }
  .modal-dialog {
    right: 0;
  }
  .modal-content-container {
    display: grid;
    grid-template-rows: min-content auto var(--cox2m-spacing-16-units);
    overflow-y: auto;
  }
  .options-icon-container {
    padding: var(--cox2m-spacing-2-units);
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-400);
    width: var(--cox2m-spacing-8-units);
    height: var(--cox2m-spacing-8-units);
    border-radius: 4px;
  }
  .user-information-section {
    padding: var(--cox2m-spacing-6-units);
  }
  .edit-modal-title {
    font-size: clamp(
      var(--cox2m-fs-800),
      1.1rem + 0.125vw,
      var(--cox2m-fs-700)
    );
    color: var(--cox2m-clr-neutral-black);
    padding-bottom: var(--cox2m-spacing-8-units);
    font-weight: var(--cox2m-fw-bold);
  }
  .user-name-container {
    display: grid;
    grid-template-columns:
      calc(var(--cox2m-spacing-12-units) + var(--cox2m-visual-corrector))
      1fr;
    gap: var(--cox2m-spacing-1-units) var(--cox2m-spacing-2-units);
  }
  .user-name {
    font-size: clamp(
      var(--cox2m-fs-600),
      2.25rem + -0.625vw,
      var(--cox2m-fs-500)
    );
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-neutral-black);
  }

  .roles-container {
    grid-column: 2;
    gap: var(--cox2m-spacing-2-units);
  }

  .user-data-form-container {
    grid-row: 3;
    grid-column: 1 / -1;
    padding-top: var(--cox2m-spacing-4-units);
  }

  .tabs-section {
    overflow-x: hidden;
    min-height: 100%;
    height: max-content;
  }

  .action-buttons-section {
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    position: sticky;
    bottom: 0;
    background: var(--cox2m-clr-neutral-white);
  }
  .action-buttons-container {
    gap: var(--cox2m-spacing-4-units);
    padding-right: var(--cox2m-spacing-8-units);
  }
  .user-roles-badge {
    display: grid;
    width: var(--cox2m-spacing-5-units);
    height: var(--cox2m-spacing-5-units);
    background: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
    place-items: center;
    font-size: var(--cox2m-fs-1000);
  }
  .roles-label {
    font-weight: var(--cox2m-fw-medium);
    color: var(--cox2m-clr-neutral-black);
  }
  .fetch-error p {
    font-size: var(--cox2m-fs-600);
    color: var(--cox2m-clr-brand-600);
    width: 90%;
  }
  .reset-roles-to-default {
    margin-left: var(--cox2m-spacing-8-units);
  }
  .reset-tag {
    color: var(--cox2m-clr-brand-600);
    font-size: var(--cox2m-fs-1000);
    font-weight: var(--cox2m-fw-bold);
    text-decoration: underline;
  }
  .close-icon-container {
    position: relative;
    top: var(--cox2m-spacing-1-units);
    margin-left: var(--cox2m-spacing-4-units);
  }
  .roles-loading {
    margin-left: var(--cox2m-spacing-2-units);
    margin-top: var(--cox2m-spacing-1-units);
  }
  @media only screen and (max-width: 767px) {
    .reset-roles-to-default {
      margin-left: 0;
    }
  }
</style>

<OverridePermissionModal
  {userToEdit}
  {overriddenPermissionList}
  {resetOverriddenPermissions}
  {blockageReasonToUpdate}
  {phoneNumberToUpdate}
  on:userUpdateSuccess
  on:userUpdateSuccess={() => fetchUser()}
  bind:show={showUpdateOverriddenRolesModal} />

<EditUserRolesConfirmationModal
  {userToEdit}
  {groupList}
  {blockageReasonToUpdate}
  {phoneNumberToUpdate}
  on:userUpdateSuccess
  on:userUpdateSuccess={() => fetchUser()}
  bind:show={showUpdateRolesConfirmation} />

{#if show && userToEdit}
  <div class="modal-backdrop fade show" transition:fade />
  <div class="modal right show d-block" transition:fade>
    <div
      class="show d-block modal-dialog"
      id="edit-user-modal"
      data-testid="edit-user-modal">
      <div class="modal-content">
        <div class="modal-content-container w-100 h-100">
          {#if fetchUserError}
            <section class="user-information-section">
              <div class="d-flex justify-content-between title-container">
                <span class="edit-modal-title">Edit User</span>
              </div>
            </section>
            <section class="user-fetch-error">
              <div
                class="d-flex fetch-error align-items-center w-100 h-100
                justify-content-center text-center">
                <p>
                  We are sorry! we had an unexpected error, please refresh the
                  page or try selecting the user again from the table.
                </p>
              </div>
            </section>
          {:else}
            <section class="user-information-section">
              <div
                class="d-flex justify-content-between align-items-start
                title-container">
                <span class="edit-modal-title">Edit User</span>
                <div class="d-flex align-items-center">

                  {#if $user.token.substring(0, 8) !== userToEdit.clientId}
                    <span
                      class="options-icon-container d-flex align-items-center
                      justify-content-center">
                      <EditUserModalActions
                        on:openBlockUserModal
                        on:openUnblockUserModal
                        {userToEdit} />
                    </span>
                  {/if}

                  <span
                    class="close-icon-container cursor-pointer"
                    on:click={() => (show = false)}>
                    <Icon
                      icon="cross"
                      className="font-weight-bold"
                      color="var(--cox2m-clr-neutral-black)"
                      size="var(--cox2m-spacing-6-units)" />
                  </span>
                </div>
              </div>

              <div class="user-name-container align-items-center">
                <UserAvatarLabelColumn
                  showName={false}
                  width="var(--cox2m-spacing-12-units)"
                  height="var(--cox2m-spacing-12-units)"
                  props={{user: userToEdit}} />
                <span
                  class="user-name"
                  id="user-to-edit-name"
                  data-testid="user-to-edit-name">
                  {`${userToEdit.givenName} ${userToEdit.familyName}`}
                </span>
                {#if !loadingView}
                  <div
                    class="roles-container flex-wrap align-items-center d-flex">
                    {#if userHasOverriddedPermissions}
                      <span class="roles-label">Overridden roles:</span>
                    {:else}
                      <span class="roles-label">Roles:</span>
                    {/if}
                    <span class="user-roles-badge rounded-circle">
                      {groupList.filter(group => group.active).length}
                    </span>
                    <MultiSelectList
                      id="roles-multiselect"
                      showBadges={false}
                      allSelected={allGroupsSelected}
                      onApplyClick={() => (showUpdateRolesConfirmation = true)}
                      badgeClick={badge => removeBadgeElement(badge)}
                      onCancelClick={() => (groupList = initialGroupList)}
                      onClick={(e, item, isSelectAll) => {
                        handleCheckboxClick(e, item, isSelectAll);
                      }}
                      loading={loadingView}
                      extraDisableCondition={$user.token.substring(0, 8) === userToEdit.clientId || overriddenPermissionList.length || userHasOverriddedPermissions || userToEdit.status === 'blocked'}
                      list={groupList} />
                    {#if userHasOverriddedPermissions}
                      <span
                        class="reset-roles-to-default d-flex align-items-center">
                        <span
                          class="d-flex reset-tag cursor-pointer"
                          on:click={() => handleOverriddenPermissions(true)}>
                          Reset to Default
                        </span>
                        <Popper>
                          <div
                            slot="tooltip-trigger"
                            class="d-flex align-items-center
                            justify-content-center ml-1">
                            <Icon
                              icon="question"
                              size="calc(var(--cox2m-spacing-4-units) +
                              var(--cox2m-visual-corrector))"
                              color="var(--cox2m-clr-neutral-white)"
                              style="background-color:
                              var(--cox2m-clr-brand-600); border-radius: 50%;
                              padding: 1px;" />
                          </div>
                          <pre
                            class="m-0"
                            style="color: var(--cox2m-clr-neutral-white);"
                            slot="tooltip-component">
                            {`By click 'Reset' you will restore this user\nto their original role & permissions.`}
                          </pre>
                        </Popper>
                      </span>
                    {/if}
                  </div>
                {:else}
                  <span class="roles-loading">
                    <LoadingSpinner />
                  </span>
                {/if}
                <div class="user-data-form-container">
                  <EditUserForm
                    on:shouldBlockSaveButton={e => handleFormUpdate(e)}
                    on:openUnblockUserModal
                    {userToEdit} />
                </div>
              </div>
            </section>

            <section
              class="tabs-section w-100 position-relative"
              id="tabs-section"
              data-testid="tabs-section">
              <EditUserTabs
                on:checkOverriddenPermissions={e => checkDisableCondition(e)}
                {allPermissionsList}
                {userToEdit} />
            </section>
          {/if}

          <section class="action-buttons-section d-flex">
            <div
              class="w-100 d-flex action-buttons-container align-items-center
              justify-content-end">
              <div>
                <Button
                  id="close-edit-user-modal"
                  backgroundColor="var(--cox2m-clr-neutral-white)"
                  color="var(--cox2m-clr-brand-600)"
                  borderColor="var(--cox2m-clr-brand-400)"
                  on:click={() => (show = false)}
                  text="Cancel" />
              </div>
              <div>
                <Button
                  id="save-user-edition"
                  backgroundColor="var(--cox2m-clr-brand-600)"
                  color="var(--cox2m-clr-neutral-white)"
                  borderColor="var(--cox2m-clr-brand-600)"
                  text="Save"
                  on:click={() => handleSaveUser()}
                  disabled={fetchUserError || (disableSaveButton && formDisableSaveButton)} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
{/if}
