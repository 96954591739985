<script>
  import {user} from 'web-stores';
  import {onMount} from 'svelte';
  import {logout} from 'web-actions';
  import {push} from 'svelte-spa-router';
  import {logout as logoutAad} from 'web-auth';

  if (document.location.hostname === 'localhost') {
    document.cookie = `${'ENV'}_token=;`;
    document.cookie = `token=;`;
  } else {
    document.cookie = `${'ENV'}_token=;path=/;domain=cox2m.com`;
    document.cookie = `token=;path=/;domain=cox2m.com`;
  }
  onMount(async () => {
    let response = await logout($user.token);
    if (response.fulfilled) {
      push('/');
    } else {
      console.error('Error, show error message.', response);
    }
    if ($user.isActiveDirectory) {
      logoutAad($user.email);
    }
    user.logout();
  });
</script>
