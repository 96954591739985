<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import {push} from 'svelte-spa-router';
  import {getApps} from 'web-actions';
  import {user, menuOpts} from 'web-stores';
  import {onMount} from 'svelte';

  let apps = [];
  let appName = '';
  let loading = false;

  onMount(async () => {
    loading = true;
    const result = await getApps({token: $user.token});
    if (result.fulfilled) {
      apps = result.apps;
    }
    loading = false;
  });

  const goToApplication = app => {
    if (app.website.includes('cox2m')) {
      window.location = app.website;
    } else {
      push(`/external-app/${app.slug}`);
    }
  };
</script>

<style>
  .application-card {
    border-radius: 5px;
    cursor: pointer;
  }
  .card-summary {
    font-weight: var(--cox2m-fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .card-message {
    font-weight: var(--cox2m-fw-regular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .spinner-container {
    height: calc(100% - var(--cox2m-spacing-15-units));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .city-badge {
    position: absolute;
    text-align: center;
    top: var(--cox2m-spacing-5-units);
    right: 0;
    padding-left: var(--cox2m-spacing-6-units);
    padding-right: var(--cox2m-spacing-6-units);
    border-radius: var(--cox2m-spacing-1-units) 0rem 0rem
      var(--cox2m-spacing-1-units);
    background: var(--cox2m-clr-brand-200);
    font-size: var(--cox2m-fs-1000);
    font-weight: var(--cox2m-fw-bold);
  }
  .fs-800 {
    font-size: var(--cox2m-fs-800);
  }
  .fs-1200 {
    font-size: var(--cox2m-fs-1200);
  }
  #main-dashboard-application-container:not(.spinner-container) {
    display: grid;
    grid-gap: var(--cox2m-spacing-6-units);
    padding-block: var(--cox2m-spacing-6-units);
    grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
  }

  #main-dashboard-application-container.spinner-container {
    display: grid;
    place-content: center;
  }
</style>

<Modal>
  <MainScreen user={$user} title="One View" menuOpt={$menuOpts} showAlertsBanner={false} appSlug="platform" smartCitiesUrl={true}>
    <div slot="main-dashboard" class="h-100">
      <div>
        <TextInput
          name="search-app"
          id="search-applications-input"
          placeholder="Search apps"
          bind:value={appName} />
      </div>
      <div
        id="main-dashboard-application-container"
        data-testid="main-dashboard-application-container"
        class={loading ? 'spinner-container' : ''}>
        {#each apps.filter(app => appName === '' || app.name
              .toLowerCase()
              .includes(appName.toLowerCase())) as app}
          <div
            id={app.appId + '-card'}
            data-testid={app.appId + '-card'}
            class="card d-flex cox2m-shadow-1-dp
            application-card"
            on:click={() => goToApplication(app)}>
            <img
              style="height: 300px; object-fit: cover;"
              class="card-img-top"
              src={app.logo}
              alt="Card image cap" />
            <div class="city-badge">COX2M</div>
            <div class="card-body p-3">
              <h3 class="card-summary fs-800">{app.name}</h3>
              <p class="card-message fs-1200">{app.description}</p>
            </div>
          </div>
        {:else}
        {#if loading}
          <div class="w-100 h-100 align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        {/if}
        {/each}
      </div>
    </div>
  </MainScreen>
</Modal>
