<script>
    import UnauthorizedFlowScreen from 'web-forms/login/UnauthenticatedFlowScreen.svelte';
    import MainLoginForm from "web-forms/login/components/MainLoginForm.svelte";

    export let params;
</script>

<UnauthorizedFlowScreen>
    <MainLoginForm params={params} />
</UnauthorizedFlowScreen>
