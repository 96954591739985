<script>
  import {onMount, createEventDispatcher} from 'svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import SelectInput from '@cox2m/city-services-ui-components/src/forms/SelectInput.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import {EMAIL_REGEX} from 'web-constants';
  import {getGroups, sendUserInvite} from 'web-actions';
  import {user} from 'web-stores';

  import {
    selectCustomClass,
    buttonActiveClass,
    buttonLightActiveClass,
    buttonInactiveClass,
    textInputErrorClass,
    stepContainerClass
  } from '../styles.js';

  const dispatch = createEventDispatcher();
  let email = '';
  let name = '';
  let groups = [];
  let selectedGroup = '';
  let selectedRole = 'ADMIN';

  let isEmailCorrect = true;
  let isNameCorrect = true;
  let isGroupCorrect = true;
  let isLoading = false;
  let errorMessage = ""

  $: hasEmailInformation = email.trim().length !== 0;
  $: isEmailValid = EMAIL_REGEX.test(email);
  $: hasNameInformation = name.trim().length !== 0;
  $: groupIsSelected = selectedGroup.trim().length !== 0;
  $: roleIsSelected =
    selectedRole.trim().length !== 0 && selectedRole !== 'Select an option';
  $: hasInviteInformation = hasNameInformation && groupIsSelected;

  const handlePreviousButtonClick = () => {
    dispatch('goPrevStep', {});
  };

  const clearErrors = () => {
    isEmailCorrect = true;
    isGroupCorrect = true;
    isNameCorrect = true;
  };

  const validateInviteInformation = () => {
    clearErrors();
    if (!hasEmailInformation || !isEmailValid) {
      isEmailCorrect = false;
    } else if (!hasNameInformation) {
      isNameCorrect = false;
    } else if (!groupIsSelected) {
      isGroupCorrect = false;
    }
  };

  const handleSendButtonClick = async () => {
    validateInviteInformation();
    if (hasInviteInformation && isEmailValid && hasNameInformation) {
      isLoading = true;
      const invitationSendResult = await sendUserInvite({
        email,
        groupId: selectedGroup,
        role: selectedRole,
        name
      });
      if (invitationSendResult.fulfilled) {
        dispatch('inviteSent', {});
      } else {
        errorMessage = invitationSendResult.message;
      }
      isLoading = false;
    }
  };

  onMount(async () => {
    //Load groups data
    const groupResult = await getGroups({token: $user.token}, {active: true});
    groups = groupResult.collection.map(group => ({
      value: group.groupId,
      label: group.name
    }));
  });
</script>

<style>
  header {
    border-bottom: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-400);
  }

  .role-group-container {
    flex-grow: 1;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--cox2m-clr-neutral-white);
    padding: 0.5em 0px;
  }
</style>

{#if errorMessage}
  <div data-testid="inviteUserErrorMessage" class="alert alert-danger" role="alert">{errorMessage}</div>
{/if}
<div class={stepContainerClass}>
  <header class="pb-3">
    <h3>Invite via Email Address</h3>
    <TextInput
      id="emailAddress"
      placeholder="Enter an email address"
      name="email"
      bind:value={email}
      customContainerClass={!isEmailCorrect ? textInputErrorClass : ''}
      onChange={clearErrors}
      showPlaceholderAsWrappedLabel={false} />
    <TextInput
      id="name"
      placeholder="Enter a name"
      name="name"
      bind:value={name}
      customContainerClass={!isNameCorrect ? textInputErrorClass : ''}
      onChange={clearErrors}
      showPlaceholderAsWrappedLabel={false} />
  </header>
  <div class="pt-3 role-group-container">
    <h4>Add group</h4>
    <SelectInput
      bind:value={selectedGroup}
      id="groups"
      label="Select a Group"
      options={groups}
      customClass={!isGroupCorrect ? textInputErrorClass : selectCustomClass}
      onChange={clearErrors} />
  </div>
  <div class="buttons-container">
    <Button
      id="previous"
      text="Previous"
      on:click={handlePreviousButtonClick}
      customClass={buttonLightActiveClass} />
    {#if !isLoading}
      <Button
        id="sendInvite"
        text="Send"
        on:click={handleSendButtonClick}
        customClass={hasEmailInformation && hasNameInformation && isEmailValid && groupIsSelected ? buttonActiveClass : buttonInactiveClass} />
    {:else}
      <LoadingSpinner />
    {/if}
  </div>
</div>
