<script>
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {putPermissionsOverridden, updateUserUsingClientId} from 'web-actions';
  import {setSuccessToast} from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher} from 'svelte';
  import {fade} from 'svelte/transition';
  import {user} from 'web-stores';

  const dispatch = createEventDispatcher();

  export let resetOverriddenPermissions = false;
  export let blockageReasonToUpdate = null;
  export let phoneNumberToUpdate = null;

  export let overriddenPermissionList = [];
  export let userToEdit = null;

  export let show = false;

  const handleUpdateUserData = async () => {
    if (resetOverriddenPermissions) {
      await overriddePermissions();
    } else {
      let userUpdateResponse = await updateUserUsingClientId(
        $user.token,
        userToEdit.clientId,
        {
          phoneNumber: `${phoneNumberToUpdate || ''}`.trim(),
          name: `${userToEdit.givenName || ''} ${userToEdit.middleName ||
            ''} ${userToEdit.familyName || ''}`.trim(),
          title: `${userToEdit.title || ''}`.trim(),
          departmentName: `${userToEdit.departmentName || ''}`.trim(),
          groupNames: userToEdit.groupNames,
          blockageReasonToUpdate: `${blockageReasonToUpdate || ''}`.trim()
        }
      );

      if (userUpdateResponse.fulfilled) {
        setSuccessToast(window.dispatchEvent,'User data was updated successfully');
        await overriddePermissions();
      }
    }

    show = false;
  };

  const overriddePermissions = async () => {
    let overriddenPermissionsUpdateResponse = await handleOverriddenPermissions();

    if (overriddenPermissionsUpdateResponse.fulfilled) {
      setSuccessToast(
        window.dispatchEvent,
        resetOverriddenPermissions
          ? 'User Roles were reseted successfully'
          : 'User Roles were overridden sucessfully'
      );
    }

    dispatch('userUpdateSuccess');
  };

  const handleOverriddenPermissions = async () => {
    if (overriddenPermissionList.length || resetOverriddenPermissions) {
      let tempOpts = {};
      tempOpts['permission_state_list'] = resetOverriddenPermissions
        ? []
        : overriddenPermissionList.map(permission => ({
            permission_id: permission.id,
            active: permission.isActive
          }));
      tempOpts['client_id'] = userToEdit.clientId;

      let overriddenPermissionsResponse = await putPermissionsOverridden(
        $user.token,
        {overriddenPermissionDto: tempOpts}
      );
      return overriddenPermissionsResponse;
    } else {
      return false;
    }
  };
</script>

<style>
  .modal-content {
    box-shadow: var(--cox2m-shadow-6-dp-dir) var(--cox2m-shadow-6-dp-clr);
    max-width: 680px;
  }
  .disclaimer-icon {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .modal-body {
    padding: var(--cox2m-spacing-2-units) var(--cox2m-spacing-4-units);
  }

  .modal-backdrop {
    z-index: 1100;
  }
  .modal.fade.show {
    z-index: 1101;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

{#if show}
  <div class="modal-backdrop fade show" transition:fade />
  <div
    class="modal fade show d-block"
    id="update-user-roles-modal"
    data-testid="update-user-roles-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="update-user-roles-modal-title"
    aria-hidden="true"
    transition:fade>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pl-4">
        <div class="modal-body">
          <div class="row m-0 justify-content-between align-items-center">
            <h5
              class="modal-title font-weight-bold
              position-relative"
              id="update-user-roles-modal-title"
              data-testid="update-user-roles-modal-title">
              <span class="position-absolute disclaimer-icon">
                <Icon
                  icon="info"
                  size="var(--cox2m-spacing-6-units)"
                  color="var(--cox2m-clr-brand-600)" />
              </span>
              {#if resetOverriddenPermissions}
                Remove Overridden Permissions
              {:else}Modify Overridden Permissions{/if}
            </h5>
            <Icon
              icon="cross"
              size="calc(var(--cox2m-spacing-4-units) + var(--cox2m-visual-corrector))"
              className="m-0 p-0"
              id="update-user-roles-modal-close"
              onClick={() => (show = false)}
              color="var(--cox2m-clr-neutral-black)" />
          </div>
          <p class="disclaimer fs-1000 mt-3">
            {#if resetOverriddenPermissions}
              You are about to remove overridden permissions, therefore, the user
              will have his set of permissions according to his assigned roles
            {:else}
              You are about to add overridden permissions for the selected user,
              therefore, the user role will now be custom
            {/if}
          </p>
        </div>
        <div class="modal-footer pt-0 mt-0 border-0">
          <div class="row m-0 align-items-center pr-1">
            <div class="d-flex">
              <Button
              customClass="mr-3 "
              color="var(--cox2m-clr-brand-600)"
              backgroundColor="var(--cox2m-clr-neutral-white)"
              borderColor="var(--cox2m-clr-brand-400)"
                text="Discard"
                id="discard-block-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => (show = false)} />
            </div>
            <div class="d-flex">
              <Button
              customClass=""
              color="var(--cox2m-clr-neutral-white)"
              backgroundColor="var(--cox2m-clr-brand-600)"
              borderColor="var(--cox2m-clr-brand-600)"
                text="Modify"
                id="modify-user-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => handleUpdateUserData()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
