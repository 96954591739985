<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import marked from 'marked';

  import {searchTextInputContainer} from '@cox2m/city-services-ui-components/src/main/components/styles';
  import {getNotifications, patchNotificationsAsRead} from 'web-actions';
  import {user, menuOpts} from 'web-stores';
  import {timeSince} from 'web-funcs';
  import {onMount} from 'svelte';
  import {css} from 'emotion';

  let notifications = [];
  let loading = false;
  let notificationToSearch = '';
  const severityColors = {
    alert: 'var(--cox2m-clr-critical-500)',
    warning: 'var(--cox2m-clr-warning-400)',
    info: 'var(--cox2m-clr-brand-400)'
  };

  onMount(async () => {
    loading = true;
    const result = await getNotifications({token: $user.token, limit: 100});
    if (result.fulfilled) {
      notifications = result.notifications;
    }
    loading = false;

    await patchNotificationsAsRead($user.token);
  });

  function handleNotificationClick(notification) {
    if (notification.action) {
      window.open(notification.action);
    }
  }

  const notificationCardClass = notification => css`
    border-radius: 5px;
    cursor: ${notification.action ? 'pointer' : 'initial'};
    border-bottom: ${severityColors[notification.severity]} solid 0.5rem;
  `;

  $: notificationsList = [
    ...notifications.filter(
      notification =>
        notificationToSearch === '' ||
        notification.summary
          .toLowerCase()
          .includes(notificationToSearch.toLowerCase())
    )
  ];
</script>

<style>
  .card-summary {
    font-weight: var(--cox2m-fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .card-message {
    font-weight: var(--cox2m-fw-regular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .card-activity {
    font-weight: var(--cox2m-fw-regular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-500);
  }

  .spinner-container {
    height: calc(100% - var(--cox2m-spacing-15-units));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fs-800{
    font-size: var(--cox2m-fs-800);
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  .fs-1200{
    font-size: var(--cox2m-fs-1200);
  }
</style>

<Modal>
  <MainScreen title="Notifications" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="platform" smartCitiesUrl={true}>
    <div slot="main-dashboard" class="h-100">
      <div class="row mx-1">
        <div class="col-12">
          <TextInput
            id="main-dashboard-notifications-search-input"
            bind:value={notificationToSearch}
            name="search-app"
            customContainerClass={searchTextInputContainer}
            placeholder="Search notifications" />
        </div>
      </div>
      <div
        id="main-dashboard-notifications-container"
        class="row mx-3 {loading ? 'spinner-container' : ''}">
        {#each notificationsList as notification}
          <div
            class="card col-lg-3 my-3 mx-3 cox2m-shadow-1-dp {notificationCardClass(notification)}"
            on:click|once={() => handleNotificationClick(notification)}>
            <div class="card-body">
              <h3 class="card-summary fs-800">{notification.summary}</h3>
              <p class="card-message fs-1000">
                {@html marked(notification.message)}
              </p>
              <span class="card-activity fs-1200">
                Last Activity: {timeSince(notification.createdAt)}
              </span>
            </div>
          </div>
        {:else}
          {#if loading}
            <LoadingSpinner />
          {/if}
        {/each}
      </div>
    </div>
  </MainScreen>
</Modal>
