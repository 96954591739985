import { css } from "emotion";

export const stepContainerClass = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 1.5em;
    h3{
        margin: 0.5em 0px;
    }
`;

export const selectCustomClass = css`
    height: var(--cox2m-spacing-10-units) !important;
`;

export const buttonActiveClass = css`
    background-color: var(--cox2m-clr-brand-500) !important;
    color: var(--cox2m-clr-neutral-white) !important;
    width: 30% !important;
`;

//Active class with white background
export const buttonLightActiveClass = css`
    background-color: var(--cox2m-clr-neutral-white) !important;
    color: var(--cox2m-clr-brand-500) !important;
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-500) !important;
    width: 30% !important;
`;

export const buttonInactiveClass = css`
    width: 30% !important;
`;

export const textInputErrorClass = css`
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-critical-500) !important;
`;
