<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';

  export let getUsers = () => {};
  export let userName = '';

  let timer;

  const debounceUserSearch = () => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			getUsersByGivenName()
		}, 500);
	}

  const  getUsersByGivenName = async () => {
    await getUsers();
  }
</script>

<style>
  :global(#user-search-container div:first-of-type){
    height: var(--cox2m-spacing-11-units) !important;
  }
</style>

<TextInput
  id="user-search"
  onChange={debounceUserSearch}
  bind:value={userName}
  name="user-search"
  icon="search"
  iconColor="var(--cox2m-clr-brand-600)"
  backgroundColor="var(--cox2m-clr-brand-200)"
  placeholder="Search"
  borderRadius="60px"
  customContainerClass="border-0" />

