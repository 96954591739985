import Api from 'cox_city_services_platform_api';

import { setErrorEventData } from '@cox2m/city-services-ui-components/src/funcs';

const {
  LoginActiveDirectoryRequestDto,
  LoginActiveDirectoryTokenDto,
  ResetPasswordRequest,
  InviteUserRequest,
  NotificationsApi,
  DepartmentsApi,
  PermissionsApi,
  LoginRequest,
  DummyUserApi,
  SupportApi,
  GroupsApi,
  ApiClient,
  EventApi,
  UsersApi,
  AuthApi,
  AppsApi,
  SettingsApi
} = Api;

/**
 * Initialize the API client
 */
const initApiClient = () => {
  const apiClient = new ApiClient();
  apiClient.basePath = 'API_HOST';
  apiClient.defaultHeaders = {
    'Access-Control-Allow-Origin': '*'
  };

  return apiClient;
};
const apiClient = initApiClient();

const notificationsApi = new NotificationsApi(apiClient);
const departmentsApi = new DepartmentsApi(apiClient);
const permissionsApi = new PermissionsApi(apiClient);
const dummyUserApi = new DummyUserApi(apiClient);
const supportApi = new SupportApi(apiClient);
const groupsApi = new GroupsApi(apiClient);
const eventApi = new EventApi(apiClient);
const usersApi = new UsersApi(apiClient);
const authApi = new AuthApi(apiClient);
const appsApi = new AppsApi(apiClient);
const settingsApi = new SettingsApi(apiClient);

/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = (token) => {
  apiClient.authentications["API_TOKEN"].apiKey = token;
};

/**
 * Handles a login request using an email and password, returns the query response
 */
export const login = async ({email, password, additionalLoginInfo}) => {
  const opts = {
    'loginRequest': new LoginRequest(password, additionalLoginInfo, email)
  };
  try {
    const response = await authApi.login(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'login');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Handles a login with active directory request using a domain, email and password, returns the query response
 */
export const loginWithActiveDirectory = async ({domain, username, password}) => {
  const opts = {
    'loginActiveDirectoryRequestDto': new LoginActiveDirectoryRequestDto(domain, username, password) // LoginActiveDirectoryRequestDto | Object containing the information needed to be authenticated.
  };
  try {
    const response = await usersApi.loginActiveDirectory(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'login-active-directory');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Handles a login with active directory request using an access token generated from the UI
 */
export const loginWithActiveDirectoryToken = async (token, additionalLoginInfo) => {
  try {
    const opts = {
      'loginActiveDirectoryTokenDto': new LoginActiveDirectoryTokenDto(additionalLoginInfo, token)
    };
    const response = await usersApi.loginActiveDirectoryToken(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'login-active-directory-token');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Sends user invite email
 * @param {{email: string, groupId: string, role: string}} inviteParams Required parameters for sending the invite
 */
export const sendUserInvite = async ({email, groupId, role, name}) => {
  const inviteUserRequest = new InviteUserRequest(role, groupId, name, email);
  try {
    const response = await usersApi.inviteUser(inviteUserRequest);
    return {fulfilled: true, ...response};
  } catch (error) {
    // setErrorEventData(window.dispatchEvent, error, 'invite-user');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Make a forgot password request
 * @param {string} email
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const forgotPassword = async ({email}) => {
  const opts = {
    'forgotPasswordRequest': {email}
  };
  try {
    const response = await usersApi.forgotPassword(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'forgot-password');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Reset the password
 * @param string oneTimeToken
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const resetPassword = async ({token, password}) => {
  const opts = {
    'resetPasswordRequest': new ResetPasswordRequest(password)
  };
  try {
    setToken(token);
    const response = await usersApi.resetPassword(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'reset-password');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get applications list
 * @param {string} token
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<(Q[U] extends ((...args: any[]) => any) ? ReturnType<Q[U]> : never&{fulfilled: boolean})|{fulfilled: boolean}|undefined>}
 */
export const getApps = async ({token, limit = 100, offset = 0}) => {
  try {
    setToken(token);
    const response = await appsApi.getApps({limit, offset});
    return {fulfilled: true, apps: response.apps};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-apps');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get an application by ID
 * @param token
 * @param slug
 * @returns {Promise<(*&{fulfilled: boolean})|{fulfilled: boolean, apps: *}>}
 */
export const getAppBySlug = async ({token, slug}) => {
  try {
    setToken(token);
    const response = await appsApi.getAppSlug(slug);
    return {fulfilled: true, app: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-app-slug');
    return {fulfilled: false, ...error.body};
  }
}


/**
 * Get applications list with associated event definitions
 * @param {string} token
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<(Q[U] extends ((...args: any[]) => any) ? ReturnType<Q[U]> : never&{fulfilled: boolean})|{fulfilled: boolean}|undefined>}
 */
export const getAppsWithEventDefinition = async ({token, limit = 100, offset = 0}) => {
  try {
    setToken(token);
    const response = await appsApi.getAppsEventDefinition({limit, offset});
    return {fulfilled: true, apps: response.apps};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-apps-event-definition');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get unread notifications for the current user
 * @param token
 * @param {number} limit
 * @param {string} status
 */
export const getNotifications = async ({token, limit = 5, status = null}) => {
  try {
    setToken(token);
    const response = await notificationsApi.getNotifications({limit, status});
    return {fulfilled: true, notifications: response.notifications};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-notifications');
    return {fulfilled: false, ...error.body};
  }
};


/**
 * Set notifications status
 * @param {String} token
 * @param {String} id
 * @param {String} status
 */
export const setNotificationStatus = async (token, {id, status = 'read'}) => {
  const opts = {
    'updateNotificationDto': {token, status}
  };
  try {
    const response = await notificationsApi.putNotificationsId(id, opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-notifications-id');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Update given profile user information
 */
export const editUserProfile = async ({token, phoneNumber, name, title, departmentName, groupNames}) => {
  const opts = {
    'editUserProfileDto': {phoneNumber, name, title, departmentName, groupNames}
  };
  try {
    setToken(token);
    const response = await usersApi.patchUsers(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'patch-users-client-id');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get list of groups
 */
export const getGroups = async ({token}, filter ) => {
  try {
    setToken(token);
    const response = await groupsApi.getAllGroups(filter);
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-all-groups');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Search Users by name.
 *
 * @param {number} limit Number of users.
 * @param {number} offset
 * @param {string} userName The name of the user.
 * @param {string} xToken
 * @param {string} status
 * @param {string} groups
 */
export const searchUsersByName = async ({limit = 100, offset = 0, userName, xToken, status, groups}) => {
  try {
    setToken(xToken);
    const response = await usersApi.searchUser({userName, limit, offset, status, groups});
    return {fulfilled: true, ...response};
  } catch (error) {
    if(error.status !== 404){
      setErrorEventData(window.dispatchEvent, error, 'search-users');
    }
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Archive User.
 *
 * @param clientId
 * @param xToken
 * @returns {Promise<(Q[U] extends ((...args: any[]) => any) ? ReturnType<Q[U]> : never&{fulfilled: boolean})|{fulfilled: boolean}|undefined>}
 */
export const archiveUser = async ({clientId, xToken}) => {
  try {
    setToken(xToken);
    const response = await usersApi.archiveUser(clientId);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'archive-user');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get list of departments
 */
export const getDepartments = async ({token}) => {
  try {
    setToken(token);
    const response = await departmentsApi.getDepartments();
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-departments');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get user information
 */
export const getUser = async ({token}) => {
  try {
    setToken(token);
    const response = await usersApi.getUser();
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-user');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get the user information using the client id
 * @param {string} token
 * @param {string} clientId
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const getUserByClientId = async (token, clientId) => {
  try {
    setToken(token);
    const response = await usersApi.getUsersClientId(clientId);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-users-client-id');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Update the user data identified by the given client id
 * @param {string} token
 * @param {string} phoneNumber
 * @param {string} title
 * @param {string} departmentName
 * @param {string} groupName
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const updateUserUsingClientId = async (token, clientId, {
  phoneNumber,
  name,
  title,
  departmentName,
  groupNames,
  blockage_reason = null,
}) => {
  const opts = [];
  opts['editUserDto'] = {phoneNumber, name, title, departmentName, groupNames, blockage_reason};
  try {
    setToken(token);
    const response = await usersApi.patchUsersClientId(clientId, opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'patch-users-client-id');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get a list of permissions per group
 * @param {string} token
 * @param {string} groupId
 * @param {number} limit
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const getPermissionsByGroup = async (token, groupId, {limit}) => {
  try {
    setToken(token);
    const response = await permissionsApi.getPermissions(groupId, limit);
    return {fulfilled: true, permissions: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-permissions');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Activate a user identified by a client id in other words sets is status to active
 * @param {string} token
 * @param {string} clientId
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const activateUser = async (token, clientId) => {
  try {
    setToken(token);
    const response = await usersApi.putUsersClientIdActivate(clientId);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-users-client-id-activate');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Get a list of documents
 * @param {string} token
 * @param {string} query
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const getDocumentsList = async ({token, query, limit, offset = 0}) => {
  try {
    setToken(token);
    const response = await supportApi.getSupportDocuments({query, limit, offset});
    return {fulfilled: true, collection: response.documents};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-support-documents');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Download a document using its id
 * @param id
 * @returns {Promise<{fulfilled: boolean}|*>}
 */
export const downloadDocumentById = async (id) => {
  try {
    const response = await supportApi.getSupportDocumentsId(id)
    return response;
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-support-documents-id');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Get a list of documents
 * @param {string} token
 * @param {string} query
 * @param {string} limit
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const postSupportDocuments = async ({token, file}) => {
  const opts = {
    'file': file
  };
  try {
    setToken(token);
    const response = await supportApi.postSupportDocuments(opts);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'post-support-documents');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Get all the authentication domains available
 * @param {string} token
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const getAllDomains = async () => {
  try {
    const response = await usersApi.getUsersDomains();
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-users-domains');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Change current password method
 * @param {string} token
 * @param {string} newPassword
 * @param {string} currentPassword
 * @returns {Promise<{[p: string]: *}|{fulfilled: boolean}>}
 */
export const changeCurrentPassword = async (token, {newPassword, currentPassword}) => {
  const opts = {
    'changePasswordRequest': {newPassword, currentPassword}
  };
  try {
    setToken(token);
    const passwordChangeResponse = await usersApi.changePassword(opts);
    return {fulfilled: true, ...passwordChangeResponse};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'change-password');
    return {fulfilled: false, ...error.body};
  }
}

/**
 * Handles a logout request.
 * @param {string} token
 */
export const logout = async (token) => {
  try {
    setToken(token);
    const response = await authApi.logout();
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'logout');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Returns all configurations related to notifications
 * @param {string} token
 */
export const getNotificationsConfigurations = async (token) => {
  try {
    setToken(token);
    const response = await notificationsApi.getAllNotificationsConfigurations();
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-all-notifications-configurations');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get all notifications metrics associated to a specific app
 * @param {string} appId
 */
export const getNotificationsMetricsByAppId = async (token, appId) => {
  try {
    setToken(token);
    const response = await notificationsApi.getNotificationsMetricsByAppId(appId);
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-notifications-metrics-by-app-id');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Create notification configuration
 * @param {string} token
 * @param {object} opts
 */
export const postNotificationConfiguration = async (token, opts) => {
  try {
    setToken(token);
    const response = await notificationsApi.postNotificationConfiguration({notificationSettingsDto: opts});
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'post-notification-configuration');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Update notification configuration
 * @param {string} id
 * @param {object} opts
 */
export const patchNotificationConfiguration = async (token, id, opts) => {
  try {
    setToken(token);
    const response = await notificationsApi.patchNotificationConfiguration(id, {notificationSettingsDto: opts});
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'patch-notification-configuration');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Delete notification configuration
 * @param {string} id
 */
export const deleteNotificationConfiguration = async (token, id) => {
  try {
    setToken(token);
    const response = await notificationsApi.deleteNotificationConfiguration(id);
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'delete-notification-configuration');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Deletes all notifications from a given user
 * @param {string} token
 */
export const deleteAllNotifications = async (token) => {
  try {
    setToken(token);
    const response = await notificationsApi.deleteNotifications();
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'delete-notifications');
    return {fulfilled: false, ...error.body};
  }
};


/**
 * Patch all notifications as read
 */
export const patchNotificationsAsRead = async (token) => {
  try {
    setToken(token);
    const response = await notificationsApi.patchNotificationsRead();
    return {fulfilled: true, ...response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'patch-notifications-read');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get notifications by client id
 * @param {string} token
 */
export const getAllNotificationsConfigurationsByCliendId = async (token) => {
  try {
    setToken(token);
    const response = await notificationsApi.getAllNotificationsConfigurations();
    const clientId = token.substring(0, 8);
    const userNotifications = response.filter(notification => notification.clientId === clientId);
    return {fulfilled: true, collection: userNotifications};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-all-notifications-configurations');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get a dummy user
 * @param {string} token
 * @param {string} clientId
 */
export const postDummyUser = async (token, clientId) => {
  try {
    setToken(token);
    const response = await dummyUserApi.postDummyUser(clientId);
    await authApi.logout(token);
    return {fulfilled: true, user: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'post-dummy-user');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get active directory domains
 */
export const getActiveDirectoryDomains = async () => {
  try {
    const response = await usersApi.getActiveDirectoryDomains();
    return {fulfilled: true, response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-active-directory-domains');
    return {fulfilled: false, ...error.body};
  }
};


/**
 * Block an user
 * @param {string} token
 * @param {string} clientId
 * @param {string} blockageReason
 */
 export const putUsersClientIdBlock = async (token, clientId, blockageReason) => {
  try {
    setToken(token);
    await usersApi.putUsersClientIdBlock(clientId, {reason: blockageReason});
    return {fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-users-client-id-block');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Delete clients association with a permission
 * @param {string} token
 * @param {string} groupId
*/
export const patchGroup = async (token, groupId, opts = {}) => {
  try {
    setToken(token);
    await groupsApi.patchGroup(groupId, {groupDto: opts});
    return {fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'patch-groups');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * get all the permissions of an application based in the app slug
 * @param {string} token
 * @param {string} slug
 */
 export const getPermissionsByAppSlug = async (token, slug) => {
  try {
    setToken(token);
    let response = await permissionsApi.getPermissionsByAppSlug(slug);
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-permissions-by-app-slug');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * get all the permissions for all the platform
 * @param {string} token
 */
 export const getAllPermissions = async (token) => {
  try {
    setToken(token);
    let response = await permissionsApi.getAllPermissions();
    return {fulfilled: true, collection: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-all-permissions');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * get users count and organize user by state as active and blocked
 * @param {string} token
 */

export const getCountUser = async (token) => {
  try {
    setToken(token);
    let response = await usersApi.getCountUser();
    return {fulfilled: true, userCount: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-count-user');
    return {fulfilled: false, ...error.body};
  }
};


/**
 * get users count and organize user by state as active and blocked
 * @param {string} token
 * @param {object} opts
 * @param {overriddenPermissionDto} opts.overriddenPermissionDto
 */


 export const putPermissionsOverridden = async (token, opts) => {
  try {
    setToken(token);
    await permissionsApi.putPermissionsOverridden(opts);
    return {fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-permission-overriden');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * get users count and organize user by state as active and blocked
 * @param {string} token
 * @param {string} appSlug
 *
 */
 export const getEventDefinitionsGroupedByGroup = async (token, appSlug) => {
  try {
    setToken(token);
    const response = await eventApi.getEventDefinitionsGroupedByGroup(appSlug);
    return {fulfilled: true, alertGroups: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-permission-overriden');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get the user domain using his email/client key
 * @param {string} clientKey
 *
 */
export const getUserDomain = async (clientKey) => {
  try {
    const response = await usersApi.getUserAuthDomain(clientKey);
    return {fulfilled: true, domain: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-user-auth-domain');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Validate that the users exists and has no authentication domain
 * @param {string} clientKey
 *
 */
export const validateUserNoDomain = async (clientKey) => {
  try {
    await usersApi.validateUserNoAuthDomain(clientKey);
    return {fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'validate-user-no-auth-domain');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Get the settings for the current user organization
 *
 */
export const getSettings = async (token) => {
  try {
    setToken(token);
    const response = await settingsApi.getSettings();
    return {fulfilled: true, settings: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-settings');
    return {fulfilled: false, ...error.body};
  }
};

/**
 * Update an existing setting using name and value e.g.: { name: "idleTime", value: "10" }
 * @param token
 * @param opts e.g.: { name: "idleTime", value: "10" }
 */
export const updateSetting = async (token, opts) => {
  try {
    setToken(token);
    await settingsApi.putOrganizationSetting({nameValueDto: opts});
    return {fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-organization-setting');
    return {fulfilled: false, ...error.body};
  }
};
