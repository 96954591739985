<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MuiPasswordInput from '@cox2m/city-services-ui-components/src/forms/MuiPasswordInput.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';

  import {setSuccessToast} from "@cox2m/city-services-ui-components/src/funcs";
  import {push, querystring} from 'svelte-spa-router';
  import {PASSWORD_REGEX} from 'web-constants';
  import {cleanCookies} from '../../../funcs';
  import {resetPassword} from 'web-actions';
  import {parse} from 'qs';

  let newPassword = '';
  let confirmPassword = '';
  let errorMessage = '';
  let isNewPasswordCorrect = true;
  let isConfirmedPasswordCorrect = true;
  let loading = false;

  $: hasPasswordsInformation =
    newPassword.trim().length !== 0 && confirmPassword.trim().length !== 0;
  $: isInformationValid = isNewPasswordCorrect && isConfirmedPasswordCorrect;
  $: params = parse($querystring);

  const validateLoginInformation = () => {
    clearErrors();
    if (newPassword.trim() === '') {
      isNewPasswordCorrect = false;
      errorMessage = 'Please type your new password.';
    } else if (!PASSWORD_REGEX.test(newPassword)) {
      isNewPasswordCorrect = false;
      errorMessage =
        'Passwords must have no spaces, be at least 8 characters long, contain one lowercase letter, uppercase letter, number, and symbol.';
    } else if (confirmPassword.trim() === '') {
      isConfirmedPasswordCorrect = false;
      errorMessage = 'Please type your new password.';
    } else if (newPassword !== confirmPassword) {
      isConfirmedPasswordCorrect = false;
      errorMessage = "Passwords don't match.";
    } else {
      errorMessage = '';
    }
  };

  const clearErrors = () => {
    isNewPasswordCorrect = true;
    isConfirmedPasswordCorrect = true;
  };

  const handleTextChange = () => {
    clearErrors();
  };

  const handleConfirmPasswordClick = async () => {
    validateLoginInformation();
    if (isNewPasswordCorrect && isConfirmedPasswordCorrect) {
      loading = true;
      const {token} = params;
      const requestResponse = await resetPassword({
        token,
        password: newPassword
      });
      if (requestResponse.fulfilled) {
        cleanCookies({shouldRemoveLatestVisitedUrl: true});
        push('/login');
        setSuccessToast(window.dispatchEvent, 'User password has been successfully updated');
      } else {
        errorMessage = "The token has been already used or the user wasn't found";
      }
      loading = false;
    }
  };

  const handleReturnToLoginClick = () => {
    push('/start');
  };
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-500);
    font-size: clamp(var(--cox2m-fs-1200), var(--cox2m-fs-1000), var(--cox2m-fs-900)) !important;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--cox2m-spacing-4-units);
    align-items: center;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
  }

  .anchor-container {
    height: var(--cox2m-spacing-11-units);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: clamp(var(--cox2m-spacing-4-units), var(--cox2m-spacing-6-units), var(--cox2m-spacing-8-units))
  }

  .message, .error-message{
    width: 100%;
    font-size: clamp(var(--cox2m-fs-1300), var(--cox2m-fs-1200), var(--cox2m-fs-1000)) !important;
    text-align: initial;
    margin-block: var(--cox2m-spacing-3-units);
  }

  .error-message {
    color: var(--cox2m-clr-critical-800);
  }

  .paragraph-button {
    color: var(--cox2m-clr-brand-500);
    text-decoration: none;
    margin-left: calc(var(--cox2m-spacing-1-units) + 1px);
  }

  .paragraph-button:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .message {
    color: var(--cox2m-clr-brand-600);
    text-align: initial;
  }

  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }

  @media (max-width: 1200px){
    .message, .error-message{
    width: 100%;
    font-size: clamp(var(--cox2m-fs-1300), var(--cox2m-fs-1200), var(--cox2m-fs-1000)) !important;
    text-align: initial;
  }
  }
</style>

<div class="login-container">
  <div class="form-container">
    <MuiPasswordInput
      id="new-password-input"
      bind:value={newPassword}
      customContainerClass="w-100"
      onChange={handleTextChange}
      inputError={errorMessage !== ''}
      label="New Password" />
    <span class="{isConfirmedPasswordCorrect && errorMessage === '' ? "message" : "error-message"}">Passwords must have no spaces, be at least 8 characters long, contain one lowercase letter, uppercase letter, number, and symbol.</span>
    <MuiPasswordInput
      id="confirm-new-password-input"
      bind:value={confirmPassword}
      customContainerClass="w-100"
      onChange={handleTextChange}
      inputError={errorMessage !== ''}
      label="Confirm Password" />
    {#if errorMessage}
      <p class="error-message mb-0">{errorMessage}</p>
    {/if}
  </div>
  {#if loading}
    <LoadingSpinner />
  {:else}
    <Button
      id="confirm-new-password-button"
      text="Continue"
      on:click={handleConfirmPasswordClick}
      margin="var(--cox2m-spacing-8-units) 0 0 0"
      backgroundColor="var(--cox2m-clr-brand-600)"
      customClass="cox2m-shadow-1-dp {hasPasswordsInformation && isInformationValid ? 'active' : ''}" />
  {/if}
  <div class="anchor-container fs-1000">
    <p class="paragraph-button fs-1000" on:click={handleReturnToLoginClick}>Return to login</p>
  </div>
</div>
