<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import PasswordInput from '@cox2m/city-services-ui-components/src/forms/PasswordInput.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {changeCurrentPassword} from 'web-actions';
  import {PASSWORD_REGEX} from 'web-constants';
  import {user} from 'web-stores';
  import {
    textInputErrorClass,
    saveChangesButtonActiveClass
  } from './components/styles.js';

  let currentPassword = '';
  let newPassword = '';
  let confirmPassword = '';
  let errorMessage = '';
  let successMessage = '';
  let isCurrentPasswordCorrect = true;
  let isNewPasswordCorrect = true;
  let isConfirmedPasswordCorrect = true;
  let loadingPasswordChange = false;

  $: hasPasswordsInformation =
    newPassword.trim().length !== 0 && confirmPassword.trim().length !== 0;
  $: isInformationValid =
    isNewPasswordCorrect &&
    confirmPassword.trim() !== '' &&
    newPassword === confirmPassword;

  const validateLoginInformation = () => {
    clearErrors();
    if (currentPassword.trim() === '') {
      isCurrentPasswordCorrect = false;
      errorMessage = 'Please type your current password.';
    } else if (newPassword.trim() === '') {
      isNewPasswordCorrect = false;
      errorMessage = 'Please type your new password.';
    } else if (!PASSWORD_REGEX.test(newPassword)) {
      isNewPasswordCorrect = false;
      errorMessage =
        'Passwords must have no spaces, be at least 8 characters long, contain one lowercase letter, uppercase letter, number, and symbol.';
    } else if (confirmPassword.trim() === '') {
      isConfirmedPasswordCorrect = false;
      errorMessage = 'Please type your new password.';
    } else if (newPassword !== confirmPassword) {
      isConfirmedPasswordCorrect = false;
      errorMessage = "Passwords don't match.";
    } else {
      errorMessage = '';
      return;
    }
  };

  const clearErrors = () => {
    isNewPasswordCorrect = true;
    isConfirmedPasswordCorrect = true;
  };

  const handleTextChange = () => {
    clearErrors();
  };

  const handleConfirmPasswordClick = async () => {
    successMessage = '';
    validateLoginInformation();
    if (isInformationValid) {
      loadingPasswordChange = true;
      let passwordChangeResponse = await changeCurrentPassword($user.token, {
        newPassword,
        currentPassword
      });
      if (passwordChangeResponse.fulfilled) {
        successMessage = 'Password succesfully changed.';
      } else {
        errorMessage = "Password couldn't be changed.";
      }
      currentPassword = '';
      newPassword = '';
      confirmPassword = '';
      loadingPasswordChange = false;
    }
  };
</script>

<style>
  .change-password-content-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 5px;
    padding: var(--cox2m-spacing-4-units);
    margin-left: 0px;
  }
  .error-message {
    color: var(--cox2m-semantic-citical-color);
  }

  .success-message {
    color: var(--cox2m-clr-success-400);
  }
  .anchor-container {
    height: calc(var(--cox2m-spacing-12-units) + var(--cox2m-visual-corrector));
  }

  .password-container {
    padding-right: var(--cox2m-spacing-2-units);
  }

  .confirm-password-container {
    padding-left: var(--cox2m-spacing-2-units);
  }

  @media (max-width: 767.98px) {
    .change-password-content-container {
      margin-right: 0px;
      max-width: initial;
      height: 100%;
    }

    .password-container {
      padding-right: var(--cox2m-spacing-4-units);
    }

    .confirm-password-container {
      padding-left: var(--cox2m-spacing-4-units);
    }
  }
</style>

<div class="change-password-content-container cox2m-shadow-1-dp">
  <div class="row py-2">
    <div class="password-container col-sm-12 col-md-6">
      <PasswordInput
        id="edit-profile-current-password"
        bind:value={currentPassword}
        customContainerClass={!isCurrentPasswordCorrect ? textInputErrorClass : ''}
        onChange={handleTextChange}
        placeholder="Current password" />
    </div>
    <div class="anchor-container col-12 d-flex align-items-center">
      <a
        id="edit-profile-forgot-password-anchor"
        class="pl-3"
        href="javascript:void(0);">
        Forgot password?
      </a>
    </div>
  </div>
  <div class="row pb-2">
    <div class="password-container col-sm-12 col-md-6">
      <PasswordInput
        id="edit-profile-new-password"
        bind:value={newPassword}
        customContainerClass={!isNewPasswordCorrect ? textInputErrorClass : ''}
        onChange={handleTextChange}
        placeholder="New password" />
    </div>
    <div class="confirm-password-container col-sm-12 col-md-6">
      <PasswordInput
        id="edit-profile-confirm-password"
        bind:value={confirmPassword}
        customContainerClass={!isConfirmedPasswordCorrect ? textInputErrorClass : ''}
        onChange={handleTextChange}
        placeholder="Confirm password" />
    </div>
  </div>
  <footer class="d-flex justify-content-end">
    {#if errorMessage}
      <div class="d-flex align-items-center m-0 mr-3">
        <Icon icon="cross-circle" color="var(--cox2m-clr-critical-500)" size="var(--cox2m-spacing-6-units)" className="mr-2" />
        <p id="edit-profile-error-message-container" class="error-message m-0">
          {errorMessage}
        </p>
      </div>
    {/if}
    {#if successMessage}
      <div class="d-flex align-items-center m-0 mr-3">
        <Icon icon="check-circle" color="var(--cox2m-clr-success-400)" size="var(--cox2m-spacing-6-units)" className="mr-2" />
        <p
          id="edit-profile-success-message-container"
          class="success-message m-0">
          {successMessage}
        </p>
      </div>
    {/if}
    {#if !loadingPasswordChange}
      <button
        id="edit-profile-save-password-button"
        type="button"
        on:click={handleConfirmPasswordClick}
        class="{hasPasswordsInformation && isInformationValid ? saveChangesButtonActiveClass : 'btn btn-secondary'}
        m-0">
        Save Changes
      </button>
    {:else}
      <LoadingSpinner />
    {/if}
  </footer>
</div>
