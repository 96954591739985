<script>
  import FormTextInput from '@cox2m/city-services-ui-components/src/forms/FormTextInput.svelte';
  import SelectInput from '@cox2m/city-services-ui-components/src/forms/SelectInput.svelte';
  import MultiSelect from './components/MultiSelect.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {textInputErrorClass, saveChangesButtonActiveClass} from './components/styles.js';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';
  import {
    editUserProfile,
    getGroups,
    getDepartments,
    getUser
  } from 'web-actions';
  import {EMAIL_REGEX, PHONE_NUMBER_REGEX} from 'web-constants';

  let name = '';
  let jobTitle = '';
  let phoneNumber = '';
  let email = '';
  let domain = '';
  let selectedGroup = '';
  let selectedDepartment = '';
  let departmentList = [];
  let groupList = [];

  let loadingSubmit = false;
  let isNameCorrect = true;
  let isEmailCorrect = true;
  let isJobTitleCorrect = true;
  let isPhoneNumberCorrect = true;
  let isGroupCorrect = true;
  let isDepartmentCorrect = true;

  let errorMessage = '';
  let successMessage = '';

  $: hasEditProfileInformation =
    name.trim().length !== 0 &&
    email.trim().length !== 0 &&
    jobTitle.trim().length !== 0 &&
    phoneNumber.trim().length !== 0 &&
    selectedGroup.trim().length !== 0 &&
    selectedDepartment.trim().length !== 0;

  $: isInformationValid =
    EMAIL_REGEX.test(email) && PHONE_NUMBER_REGEX.test(phoneNumber);

  const cleanErrorsAndMessages = () => {
    isNameCorrect = true;
    isEmailCorrect = true;
    isJobTitleCorrect = true;
    isPhoneNumberCorrect = true;
    isGroupCorrect = true;
    isDepartmentCorrect = true;
    errorMessage = '';
    successMessage = '';
  };

  const handleTextChange = () => {
    cleanErrorsAndMessages();
  };

  const validateEditProfileInformation = () => {
    cleanErrorsAndMessages();
    if (name.trim() === '') {
      errorMessage = 'Please type a name.';
      isNameCorrect = false;
    } else if (email.trim() === '') {
      errorMessage = 'Please type an email address.';
      isEmailCorrect = false;
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = 'Please type a valid email address.';
      isEmailCorrect = false;
    } else if (jobTitle.trim() === '') {
      errorMessage = 'Please type a job title.';
      isJobTitleCorrect = false;
    } else if (phoneNumber.trim() === '') {
      errorMessage = 'Please type a phone number.';
      isPhoneNumberCorrect = false;
    } else if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      errorMessage = 'Please type a valid phone number. E.g.: +XXXXXXXXX or XXX-XXX-XXXX';
      isPhoneNumberCorrect = false;
    } else if (selectedGroup.trim() === '') {
      errorMessage = 'Please select a group.';
      isGroupCorrect = false;
    } else if (selectedDepartment.trim() === '') {
      errorMessage = 'Please select a department.';
      isDepartmentCorrect = false;
    } else {
      errorMessage = '';
    }
  };

  let loading = false;

  onMount(async () => {
    name = $user.fullName;
    email = $user.email;
    loading = true;

    const userResponse = await getUser({token: $user.token});
    if (userResponse.fulfilled) {
      name = `${userResponse.givenName || ''} ${userResponse.middleName ||
      ''} ${userResponse.familyName || ''}`.trim();
      jobTitle = `${userResponse.title || ''}`.trim();
      phoneNumber = `${userResponse.phoneNumber || ''}`.trim();
      domain = `${userResponse.organization.name || ''}`.trim();
      selectedGroup = userResponse.groupNames ? userResponse.groupNames.join(',') : '';
      selectedDepartment = `${userResponse.departmentName || ''}`.trim();
    }

    if (userResponse.operations && userResponse.operations.includes('get-all-groups')) {
      const getGroupsDtoList = await getGroups({token: $user.token}, {active: true});
      if (getGroupsDtoList.fulfilled) {
        getGroupsDtoList.collection.forEach(function (item) {
          groupList.push({value: item.name, label: item.name});
        });
      }
    } else {
      groupList.push({ value: selectedGroup, label: selectedGroup });
    }

    const getDepartmentDtoList = await getDepartments({token: $user.token});
    if (getDepartmentDtoList.fulfilled) {
      getDepartmentDtoList.collection.forEach(function(it) {
        departmentList.push({value: it.name, label: it.name});
      });
    }


    loading = false;
  });

  const handleSaveChangesClick = async () => {
    validateEditProfileInformation();
    if (hasEditProfileInformation && isInformationValid) {
      loadingSubmit = true;
      let requestResponse = await editUserProfile({
        token: $user.token,
        phoneNumber,
        name: name,
        title: jobTitle,
        departmentName: selectedDepartment,
        groupNames: selectedGroup.split(',')
      });
      if (requestResponse.fulfilled) {
        successMessage = 'User succesfully updated.';
      } else {
        errorMessage = 'Error updating the user.';
      }
      loadingSubmit = false;
    }
  };
</script>

<style>
  h4 {
    font-weight: var(--cox2m-fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  p {
    color: var(--cox2m-clr-neutral-500);
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
  }

  .success-message {
    color: var(--cox2m-clr-success-400);
  }

  .container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
  }

  .btn {
    border-radius: 5px;
    background-color: var(--cox2m-clr-neutral-400);
    border: none;
    cursor: pointer;
  }
  .fs-600{
    font-size: var(--cox2m-fs-600);
  }
  .fs-900{
    font-size: var(--cox2m-fs-900);
  }
</style>

<div class="container col-md-12 order-md-1 cox2m-shadow-1-dp">
  <div class="col-md-12 order-md-1">
    <form class="needs-validation" novalidate>
      <h4 class="py-3 fs-600">My Details</h4>
      <div class="row">
        <div class="col-md-6 mb-3">
          <FormTextInput
            id="name"
            name="Name"
            placeholder="Name"
            bind:value={name}
            onChange={handleTextChange}
            className={!isNameCorrect ? textInputErrorClass : ''} />
        </div>
        <div class="col-md-6 mb-3">
          <FormTextInput
            id="emailAddress"
            name="Email Address"
            placeholder="Email Address"
            bind:value={email}
            onChange={handleTextChange}
            disabled={true}
            className={!isEmailCorrect ? textInputErrorClass : ''} />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <FormTextInput
            id="jobTitle"
            name="Job Title"
            placeholder="Job Title"
            bind:value={jobTitle}
            onChange={handleTextChange}
            className={!isJobTitleCorrect ? textInputErrorClass : ''} />
        </div>
        <div class="col-md-6 mb-3">
          <FormTextInput
            id="phoneNumber"
            name="Phone Number"
            placeholder="Phone Number"
            bind:value={phoneNumber}
            onChange={handleTextChange}
            className={!isPhoneNumberCorrect ? textInputErrorClass : ''} />
        </div>
      </div>
      <hr class="mb-4" />
      <h4 class="mb-3 fs-600">Domain and Groups</h4>
      <div class="row">
        <div class="col-md-6 mb-3">
          <FormTextInput
            id="domain"
            name="Domain"
            placeholder="Domain"
            disabled="true"
            bind:value={domain} />
        </div>
        <div class="col-md-6 mb-3">
          {#if loading}
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          {/if}
          {#if !loading}
            <MultiSelect
              items={groupList}
              id="group"
              label="Role"
              bind:value={selectedGroup}
              onChange={handleTextChange}
              customClass={!isGroupCorrect ? textInputErrorClass : ''} />
          {/if}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          {#if loading}
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          {/if}
          {#if !loading}
            <SelectInput
              id="department"
              label="Department"
              bind:value={selectedDepartment}
              customClass={!isDepartmentCorrect ? textInputErrorClass : ''}
              options={departmentList}
              onChange={handleTextChange}
              defaultOption="Choose Department" />
          {/if}
        </div>
        <div class="col-md-6 mb-3" />
      </div>
      <div
        class="col-md-12 d-flex align-items-center justify-content-end py-3 px-0">
        {#if errorMessage}
          <div class="d-flex align-items-center m-0 mr-3">
            <Icon icon="cross-circle" color="var(--cox2m-clr-critical-500)" size="var(--cox2m-spacing-6-units)" className="mr-2" />
            <p class="error-message m-0 fs-900">{errorMessage}</p>
          </div>
        {/if}
        {#if successMessage}
          <div class="d-flex align-items-center m-0 mr-3">
            <Icon icon="check-circle" color="var(--cox2m-clr-success-400)" size="var(--cox2m-spacing-6-units)" className="mr-2" />
            <p class="success-message m-0 fs-900">{successMessage}</p>
          </div>
        {/if}
        {#if !loadingSubmit}
          <button
            id="saveProfile"
            type="button"
            on:click={handleSaveChangesClick}
            class="{hasEditProfileInformation && isInformationValid ? `enabled-button ${saveChangesButtonActiveClass}` : 'btn btn-secondary'}
            m-0 cox2m-shadow-level-2">
            Save Changes
          </button>
        {:else}
          <LoadingSpinner />
        {/if}
      </div>
    </form>
  </div>
</div>
