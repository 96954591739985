<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import ManageUserForm from 'web-forms/profile/settings/ManageUserForm.svelte';

  import {menuOpts, user} from 'web-stores';

  export let params = {};
</script>

<style>
  .manage-user-container {
    border-radius: 5px;
    background-color: var(--cox2m-clr-neutral-white);
  }
</style>

<Modal>
  <MainScreen
    title="Manage User"
    menuOpt={$menuOpts}
    user={$user}
    appSlug="platform"
    smartCitiesUrl={true}
    showAlertsBanner={false}>
    <div slot="main-dashboard" class="manage-user-container cox2m-shadow-1-dp">
      <ManageUserForm clientId={params.user} />
    </div>
  </MainScreen>
</Modal>
