<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import ChangePasswordForm from 'web-forms/profile/settings/ChangePasswordForm.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import EditProfileForm from 'web-forms/profile/settings/EditProfileForm.svelte';

  import {menuOpts, user} from 'web-stores';

  export let params;

  params = params || {path: 'profile'};

  //this can be refactored creating a specific route for every component
  $: path = params.path;
  $: menuOpt = [
    {
      key: 'profile',
      title: 'Profile',
      Component: EditProfileForm
    },
    {
      key: 'password',
      title: 'Password',
      Component: ChangePasswordForm
    },
  ];

  $: selectedOpt = menuOpt.find(opt => path === opt.key);
</script>

<style>
  @media (max-width: 767.98px) {
    div {
      height: 100%;
    }
  }
</style>

<Modal>
  <MainScreen
    title={selectedOpt.title}
    menuOpt={$menuOpts}
    user={$user}
    appSlug="platform"
    smartCitiesUrl={true}
    showAlertsBanner={false}>
    <div slot="main-dashboard">
      <svelte:component this={selectedOpt.Component} />
    </div>
  </MainScreen>
</Modal>
