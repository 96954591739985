import { css } from "emotion";

export const textInputErrorClass = css`
    border: solid var(--cox2m-brd-w-2) var(--cox2m-clr-critical-500) !important;
`;

export const saveChangesButtonActiveClass = css`
    border-radius: 5px;
    color: #fff;
    background-color: var(--cox2m-clr-info-900) !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18), 0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.1);
    min-width: var(--cox2m-spacing-30-units) !important;
    border: none;
    cursor: pointer;
`;
