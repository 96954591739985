<script>
  export let title = '';
  export let message = '';
  export let onClick = () => {};
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-500);
  }
  .fs-1200{
    font-size: var(--cox2m-fs-1200);
  }
</style>

<div class="col-12 my-2 d-flex align-items-center">
  <div class="flex-grow-1">
    <h6 style="cursor: pointer;" on:click={onClick}>{title}</h6>
    <p class="m-0 fs-1200">{message}</p>
  </div>
</div>
