<script>
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {postDummyUser} from 'web-actions';
  import {fade} from 'svelte/transition';
  import {user} from 'web-stores';


  export let show = false;
  export let userToViewAs = null;

  const handleMockUser = async () => {
    try{
      const response = await postDummyUser($user.token, userToViewAs.clientId);
      if (response.fulfilled) {
        user.saveUser(response.user);
        if (document.location.hostname === 'localhost') {
          document.cookie = `${'ENV'}_token=${response.user.token};`;
          document.cookie = `${'ENV'}_user=${JSON.stringify(response.user)};`;
        } else {
          const now = new Date();
          const minutes = 60;
          now.setTime(now.getTime() + minutes * 60 * 1000);
          document.cookie = `${'ENV'}_user=${JSON.stringify(
            response.user
          )};expires=${now.toUTCString()};path=/;domain=cox2m.com`;
          document.cookie = `${'ENV'}_token=${
            response.user.token
          };expires=${now.toUTCString()};path=/;domain=cox2m.com`;
        }
        window.location.href = 'SMART_CITIES_URL';
      }
    }catch(error){
      console.error(error);
    }
  };
</script>

<style>
  .modal-content {
    box-shadow: var(--cox2m-shadow-6-dp-dir) var(--cox2m-shadow-6-dp-clr);
    max-width: 680px;
  }
  .disclaimer-icon {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .modal-body {
    padding: var(--cox2m-spacing-2-units) var(--cox2m-spacing-4-units);
  }

  .modal-backdrop {
    z-index: 1100;
  }
  .modal.fade.show {
    z-index: 1101;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

{#if show}
  <div class="modal-backdrop fade show" transition:fade />
  <div
    class="modal fade show d-block"
    id="view-as-user-modal"
    data-testid="view-as-user-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="view-as-user-modal-title"
    aria-hidden="true"
    transition:fade>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pl-4">
        <div class="modal-body">
          <div class="row m-0 justify-content-between align-items-center">
            <h5
              class="modal-title font-weight-bold
              position-relative"
              id="view-as-user-modal-title"
              data-testid="view-as-user-modal-title">
              <span class="position-absolute disclaimer-icon">
                <Icon
                  icon="info"
                  size="var(--cox2m-spacing-6-units)"
                  color="var(--cox2m-primary-500)" />
              </span>
              View As User
            </h5>
            <Icon
              icon="cross"
              size="calc(var(--cox2m-spacing-4-units) + var(--cox2m-visual-corrector))"
              className="m-0 p-0"
              id="view-as-user-modal-close"
              onClick={() => (show = false)}
              color="var(--cox2m-clr-neutral-black)" />
          </div>
          <p class="disclaimer fs-1000 mt-3">
            You are about to view the application as the user <strong>{`${userToViewAs.givenName || ''} ${userToViewAs.familyName || ''}`}</strong>
          </p>
        </div>
        <div class="modal-footer pt-0 mt-0 border-0">
          <div class="row m-0 align-items-center pr-1">
            <div class="d-flex">
              <Button
              customClass="mr-3 "
              color="var(--cox2m-clr-brand-600)"
              backgroundColor="var(--cox2m-clr-neutral-white)"
              borderColor="var(--cox2m-clr-brand-400)"
                text="Cancel"
                id="cancel-view-as-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => (show = false)} />
            </div>
            <div class="d-flex">
              <Button
                customClass=""
                color="var(--cox2m-clr-neutral-white)"
                backgroundColor="var(--cox2m-clr-brand-600)"
                borderColor="var(--cox2m-clr-brand-600)"
                text="Accept"
                id="accept-view-as-button"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => handleMockUser()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
