<script>
  import ToastMessages from '@cox2m/city-services-ui-components/src/main/components/notifications/ToastMessages.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MuiTextInput from '@cox2m/city-services-ui-components/src/forms/MuiTextInput.svelte';
  import CheckBox from '@cox2m/city-services-ui-components/src/components/CheckBox.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';

  import {
    getCookieByName,
    waitForElementExistance
  } from '@cox2m/city-services-ui-components/src/funcs.js';
  import {getActiveDirectoryDomains, getUserDomain, validateUserNoDomain} from 'web-actions';
  import {cleanCookies, saveRememberMe} from '../../../funcs';
  import {EventType} from '@azure/msal-browser';
  import {onDestroy, onMount} from 'svelte';
  import {EMAIL_REGEX} from 'web-constants';
  import {push} from 'svelte-spa-router';
  import {signIn} from 'web-auth';

  const cox2mLatestVisitedPage = getCookieByName(`${'ENV'}_cox2mlatesturl`);

  let email = '';
  let errorMessage = '';
  let loading = false;
  let toastNotifications = [];
  let rememberMe = true;
  let activeDirectoryDomainsResponse;

  $: hasLoginInformation = email.trim().length !== 0;
  $: isInformationValid = EMAIL_REGEX.test(email);

  const addToastNotification = e => {
    toastNotifications = [...toastNotifications, e.detail];
  };

  const validateLoginInformation = () => {
    if (email.trim() === '') {
      errorMessage = 'Please type an email address.';
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = 'Please type a valid email address.';
    } else {
      errorMessage = '';
    }
  };

  const getDomains = async () => {
    activeDirectoryDomainsResponse = await getActiveDirectoryDomains();
  };

  const getUserActiveDirectory = async () => {
    const {fulfilled, ...data} = await getUserDomain(email);
    if (fulfilled) {
      return data;
    } else {
      loading = false;
      errorMessage = data.message;
    }
    return false;
  };

  const activeDirectoryHandler = async domain => {
    loading = false;

    errorMessage =
      'Your organization uses Active Directory integration for login.';
    await handleLoginWithActiveDirectoryClick(domain.systemUsername);
  };

  /**
   * Checks if the email domain is supported by Active directory existing domains
   * @returns {*}
   */
  const isEmailSupportedByActiveDirectory = () => {
    let emailDomain = email
      .split('@')[1]
      .trim()
      .toLowerCase();
    return (
      activeDirectoryDomainsResponse &&
      activeDirectoryDomainsResponse.response &&
      activeDirectoryDomainsResponse.response.find(element =>
        emailDomain.includes(element.domain)
      )
    );
  };

  const handleContinueClick = async () => {
    validateLoginInformation();
    if (hasLoginInformation && isInformationValid) {
      loading = true;
      const clientKey = email.trim().toLowerCase()
      const {fulfilled} = await validateUserNoDomain(clientKey);
      if (fulfilled) {
        push(`/login/${clientKey}`);
        return;
      }

      const domainResponse =
        (await getUserActiveDirectory()) ||
        (await isEmailSupportedByActiveDirectory());

      if (domainResponse) {
        await activeDirectoryHandler(domainResponse.domain);
      } else {
        push(`/login/${clientKey}`);
      }
    }
  };

  const handleLoginWithActiveDirectoryClick = async aadClientId => {
    loading = true;
    let oneSignalInfo = {};
    if (navigator.userAgent.indexOf('gonative') > -1) {
      oneSignalInfo = await window.gonative.onesignal.onesignalInfo();
    }

    const {fulfilled} = await signIn(
      aadClientId,
      email,
      message => {
        if (message.eventType === EventType.LOGIN_FAILURE) {
          window.location.reload();
        }
      },
      oneSignalInfo
    );

    if (fulfilled) {
      if (cox2mLatestVisitedPage !== null && cox2mLatestVisitedPage !== '') {
        window.location.href = cox2mLatestVisitedPage;
      } else {
        push('/');
      }
    } else {
      push('/start');
    }
  };

  onMount(async () => {
    cleanCookies({shouldRemoveLatestVisitedUrl: false});
    window.addEventListener('successEvent', addToastNotification);
    await getDomains();
    email = getCookieByName(`${'ENV'}_rememberme`);
    waitForElementExistance('#email-input').then(() => {
      document.getElementById('email-input').focus();
    });
  });

  onDestroy(() => {
    const emailToSave = rememberMe ? email : '';
    saveRememberMe(emailToSave);
  });
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-500);
    margin-top: var(--cox2m-spacing-4-units);
  }

  .rememberme-checkbox-label {
    color: var(--cox2m-clr-neutral-black);
  }

  .login-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    height: 150px;
    justify-content: space-evenly;
    padding-bottom: var(--cox2m-spacing-4-units);
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
    margin-top: 0;
  }

  .rememberme-container {
    display: flex;
  }

  label {
    font-weight: var(--cox2m-fw-bold);
    font-size: clamp(
      var(--cox2m-fs-1300),
      var(--cox2m-fs-1000),
      var(--cox2m-fs-1000)
    ) !important;
    line-height: clamp(
      var(--cox2m-spacing-3-units),
      var(--cox2m-spacing-4-units),
      var(--cox2m-spacing-4-units)
    ) !important;
    margin-left: calc(
      var(--cox2m-spacing-2-units) + var(--cox2m-visual-corrector)
    );
  }

  .fs-1200 {
    font-size: var(--cox2m-fs-1200);
  }
</style>

<div class="login-container">
  <div class="form-container">
    <MuiTextInput
      id="email-input"
      name="email"
      label="Email"
      padding="var(--cox2m-spacing-3-units)"
      bind:value={email}
      customContainerClass="w-100"
      onKeyPress={({key}) => {
        if (key === 'Enter') {
          handleContinueClick();
        }
      }}/>
    {#if errorMessage}
      <p class="error-message fs-1200">{errorMessage}</p>
    {/if}
    <div class="rememberme-container border-0">
      <CheckBox id="rememberme-checkbox" bind:checked={rememberMe} />
      <label for="rememberme-checkbox" class="rememberme-checkbox-label">
        Remember me
      </label>
    </div>
  </div>
  <ToastMessages {toastNotifications} />

  <div>
    {#if !loading}
      <Button
        id="continue-button"
        text="Continue"
        backgroundColor="var(--cox2m-clr-brand-600)"
        on:click={handleContinueClick}
        disabled={!hasLoginInformation || !isInformationValid}
        customClass="cox2m-shadow-1-dp {hasLoginInformation && isInformationValid ? 'active' : ''}" />
    {:else}
      <LoadingSpinner />
    {/if}
  </div>
</div>
