<script>
  import {createEventDispatcher} from 'svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import {
    buttonLightActiveClass,
    buttonInactiveClass,
    stepContainerClass
  } from '../styles.js';

  const dispatch = createEventDispatcher();
  let name = '';

  const directoryItems = [
    {name: 'Jason Sample', company: 'Cox2m', email: 'jasonsample@cox.com'},
    {
      name: 'Diego Test',
      company: 'TEAM',
      email: 'diego.reyes@teaminternational.com'
    },
    {name: 'Jason Sample', company: 'Cox2m', email: 'jasonsample@cox.com'}
  ];

  const handlePreviousButtonClick = () => {
    dispatch('goPrevStep', {});
  };

  const handleSendInviteClick = () => {
    dispatch('inviteSent', {});
  };
</script>

<style>
  header {
    border-bottom: solid 1px var(--cox2m-clr-neutral-400);
  }

  p {
    margin: 0px;
  }

  input {
    margin: 0px;
    justify-self: center;
    width: var(--cox2m-spacing-5-units);
    height: var(--cox2m-spacing-5-units);
  }
  .directory-list-container {
    flex-grow: 1;
  }

  .directory-list {
    height: var(--cox2m-spacing-13-units);
    display: grid;
    grid-template-columns: 10% 1fr;
    grid-template-rows: 100%;
    align-items: center;
    border-radius: 5px;
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-400);
    margin: calc(var(--cox2m-spacing-1-units) + 1px) 0px;
  }

  .directory-list-information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--cox2m-clr-neutral-white);
    padding: 0.5em 0px;
  }

  .paragraph-info {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-700);
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

<div class={stepContainerClass}>
  <header>
    <h3>Invite via Active Directory</h3>
    <TextInput
      placeholder="Enter a name or email address"
      id="email-input"
      name="email"
      bind:value={name}
      showPlaceholderAsWrappedLabel={false} />
  </header>
  <div class="directory-list-container">
    <h4>Directory List</h4>
    <div>
      {#each directoryItems as item}
        <div class="directory-list">
          <input type="checkbox" value={true} />
          <div class="directory-list-information">
            <p>
              <strong>{item.name}</strong>
            </p>
            <p class="paragraph-info fs-1000">{item.company} - {item.email}</p>
          </div>
        </div>
      {/each}
    </div>
  </div>
  <div class="buttons-container">
    <Button
      text="Previous"
      id="go-back-button"
      on:click={handlePreviousButtonClick}
      customClass={buttonLightActiveClass} />
    <Button
      id="send-invite-button"
      text="Send Invite"
      on:click={handleSendInviteClick}
      customClass={buttonInactiveClass} />
  </div>
</div>
