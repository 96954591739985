<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {cleanCookies} from '../../../funcs';
  import {forgotPassword} from 'web-actions';
  import {push} from 'svelte-spa-router';
  import {user} from '../../../stores';

  export let email;

  let errorMessage = '';
  let loading = false;

  const handleResendRecoveryLink = async () => {
    loading = true;
    const requestResponse = await forgotPassword({email});
    if (requestResponse.fulfilled) {
      cleanCookies({shouldRemoveLatestVisitedUrl: true});
      user.logout();
    } else {
      errorMessage = requestResponse.message;
    }
    loading = false;
  };

  const handleReturnToLoginClick = () => {
    push('/start');
  };
</script>

<style>
  .email-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: clamp(
        var(--cox2m-spacing-4-units),
        var(--cox2m-spacing-8-units),
        var(--cox2m-spacing-10-units)
      )
      clamp(
        var(--cox2m-spacing-8-units),
        var(--cox2m-spacing-12-units),
        var(--cox2m-spacing-16-units)
      );
    height: clamp(250px, 350px, 400px);
    align-items: center;
  }

  .paragraph-button {
    color: var(--cox2m-clr-brand-500);
    text-decoration: none;
  }

  .paragraph-button:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  h4 {
    font-size: clamp(
      var(--cox2m-fs-1300),
      var(--cox2m-fs-1200),
      var(--cox2m-fs-1000)
    ) !important;
    margin-bottom: var(--cox2m-spacing-3-units);
    margin-top: var(--cox2m-spacing-10-units);
  }

  span {
    font-weight: var(--cox2m-fw-bold);
    font-size: clamp(
      var(--cox2m-fs-1300),
      var(--cox2m-fs-1000),
      var(--cox2m-fs-1000)
    ) !important;
    margin-top: calc(var(--cox2m-spacing-1-units) * (-1));
    margin-bottom: var(--cox2m-spacing-8-units);
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
    font-size: clamp(
      var(--cox2m-fs-1300),
      var(--cox2m-fs-1200),
      var(--cox2m-fs-1000)
    ) !important;
  }

  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  .black-text{
    color: var(--cox2m-clr-neutral-black);
  }
</style>

<div class="email-container">
  <Icon
    icon="emails"
    color="var(--cox2m-clr-brand-600)"
    size="var(--cox2m-spacing-18-units)" />
  <h4 class="black-text">We sent a recovery link to you at</h4>
  <span class="black-text">{email}</span>
  {#if errorMessage && errorMessage !== ''}
    <p class="error-message">{errorMessage}</p>
  {/if}
  {#if loading}
    <LoadingSpinner />
  {:else}
    <Button
      id="reset-password-button"
      text="Resend recovery link"
      backgroundColor="var(--cox2m-clr-brand-600)"
      customClass='cox2m-shadow-1-dp active'
      on:click={handleResendRecoveryLink} />
  {/if}
  <p
    class="paragraph-button fs-1000"
    on:click={handleReturnToLoginClick}>
    Return to log in
  </p>
</div>
