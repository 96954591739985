<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectList from '@cox2m/city-services-ui-components/src/components/SelectList.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import LastAccessDeviceColumn from './components/LastAccessDeviceColumn.svelte';
  import UserAvatarLabelColumn from './components/UserAvatarLabelColumn.svelte';
  import UserActionsColumn from './components/UserActionsColumn.svelte';
  import UnblockUserModal from './components/UnblockUserModal.svelte';
  import ViewAsUserModal from './components/ViewAsUserModal.svelte';
  import BlockUserModal from './components/BlockUserModal.svelte';
  import RolesColumn from './components/RolesColumn.svelte';
  import DateColumn from './components/DateColumn.svelte';
  import UserSearch from './components/UserSearch.svelte';
  import EditUserModal from './EditUserModal.svelte';

  import {searchUsersByName, getGroups, getCountUser} from 'web-actions';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';

  export let openAddUserModal = () => {};

  const STATUS_OPTIONS = [
    {label: 'All Statuses', value: null},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Blocked', value: 'BLOCKED'}
  ];

  let fetchError = false;

  let selectedStatus = null;
  let selectedRole = null;
  let rolesOptions = [];
  let usersList = [];
  let userName = '';

  let showUnblockUserModal = false;
  let showViewAsUserModal = false;
  let showBlockUserModal = false;
  let showEditUserModal = false;

  let userToUnblock = null;
  let userToViewAs = null;
  let userToBlock = null;
  let userToEdit = null;

  let fetchUserCountError = false;

  let userCount = {
    total: 0,
    active: 0,
    blocked: 0
  };

  let columns = [
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      Component: UserAvatarLabelColumn,
      minWidth: 250
    },
    {label: 'Email', key: 'email', type: 'text', fillWith: 'N/A'},
    {label: 'Phone', key: 'phoneNumber', type: 'text', fillWith: 'N/A'},
    {label: 'Status', key: 'status', type: 'text'},
    {
      label: 'Role',
      key: 'groups',
      type: 'text',
      Component: RolesColumn,
      tooltipInfo:'City Administrator: Access to read and write\nfunctionality.\nBasic User:  Has default permissions, but can\nhave access to anything that a City Admin\nenables.',
      minWidth: 150
    },
    {
      label: 'Joined',
      key: 'creationDate',
      type: 'date',
      minWidth: 150,
      Component: DateColumn,
    },
    {
      label: 'Last Active',
      key: 'lastAccess',
      type: 'date',
      minWidth: 150,
      Component: DateColumn,
    },
    {
      label: 'Last Mobile App Login',
      key: 'lastMobileAppLogin',
      type: 'date',
      minWidth: 150,
      Component: DateColumn,
    },
    {
      label: 'Last Login Context',
      key: 'lastLoginContext',
      type: 'text',
      fillWith: 'N/A',
      minWidth: 150,
      Component: LastAccessDeviceColumn,
    },
    {
      label: '',
      key: '',
      width: 50,
      notSortable: true,
      Component: UserActionsColumn
    }
  ];

  let tableLoading = true;
  let loading = true;

  const handleSearchUsersByName = event => {
    usersList = event.detail.users;
  };

  const showEditUserModalHandler = e => {
    showEditUserModal = true;
    userToEdit = e.detail;
  };

  const showBlockUserModalHandler = e => {
    showBlockUserModal = true;
    userToBlock = e.detail;
  };

  const showUnblockUserModalHandler = e => {
    showUnblockUserModal = true;
    userToUnblock = e.detail;
  };

  const showViewAsUserModalHandler = e => {
    showViewAsUserModal = true;
    userToViewAs = e.detail;
  };

  onMount(async () => {
    await fetchUsersCount();
    const rolesResponse = await getGroups({token: $user.token}, {active: true});
    if (rolesResponse.fulfilled) {
      rolesOptions = rolesResponse.collection.map(role => ({
        label: role.name,
        value: role.name
      }));
      rolesOptions = [{label: 'All Roles', value: null}, ...rolesOptions]
      loading = false;
    } else {
      fetchError = true;
      loading = false;
    }
  });

  const refreshUsers = async () => {
    await fetchUsersCount();
    await getUsers();

    if (showEditUserModal) {
      let tempUserToEdit = await usersList.find(
        user => user.user.clientId === userToEdit.clientId
      );
      showEditUserModal = false;
      userToEdit = tempUserToEdit.user;
      showEditUserModal = true;
    }
  };

  const fetchUsersCount = async () => {
    const userCountResponse = await getCountUser($user.token);

    if (userCountResponse.fulfilled) {
      fetchUserCountError = false;
      userCount = userCountResponse.userCount;
    } else {
      fetchUserCountError = true;
    }
  };

  const checkLoginContext = (user) => {
    if(!user.lastAccess){
      return '';
    }else if(user.lastAccess && user.lastMobileAppLogin && user.lastAccess.substring(0,16) === user.lastMobileAppLogin.substring(0,16)){
      return "app";
    }else{
      return "browser";
    }
  }

  const getUsers = async () => {
    tableLoading = true;

    const searchUsersResponse = await searchUsersByName({
      xToken: $user.token,
      limit: 1000,
      userName,
      ...(selectedStatus && {status: selectedStatus}),
      ...(selectedRole && {groups: [selectedRole]})
    });

    if (searchUsersResponse.fulfilled) {
      usersList = searchUsersResponse.users.map(user => ({
        user: user,
        name: `${user.givenName} ${user.familyName}`,
        email: user.email,
        phoneNumber: user.phoneNumber,
        status: user.status[0].toUpperCase() + user.status.substring(1),
        groups: user.groupNames,
        initials: `${(user.givenName ? user.givenName[0] : '') +
          (user.familyName ? user.familyName[0] : '')}`,
        lastAccess: user.lastAccess,
        lastMobileAppLogin: user.lastMobileAppLogin,
        creationDate: user.creationDate,
        lastLoginContext: checkLoginContext(user)
      }));
    } else {
      usersList = [];
    }
    tableLoading = false;
    return usersList;
  };

  $: getUsers(selectedStatus, selectedRole);
</script>

<style>
  .section-title {
    font-size: var(--cox2m-fs-700);
  }
  .section-container {
    border-radius: 6px;
    width: 100%;
    padding: var(--cox2m-spacing-4-units );
    padding-bottom: var(--cox2m-spacing-2-units );
    margin: 0 auto;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
    background-color: var(--cox2m-clr-neutral-white);
  }
  .section-subtitle {
    font-size: medium;
  }

  .users-count-container {
    justify-content: space-between;
    max-width: fit-content;
    align-items: center;
    padding: var(--cox2m-spacing-4-units ) 0 0 var(--cox2m-spacing-4-units );
    gap: var(--cox2m-spacing-4-units );
  }

  #user-form-container {
    padding-bottom: 0;
  }
  :global(.options-container) {
    z-index: var(--cox2m-z-index-2);
  }
  :global(#users-management-table) {
    font-size: clamp(var(--cox2m-fs-1200), 1.5vw, var(--cox2m-fs-900));
  }
  :global(#users-management-table-container) {
    min-height: 500px;
  }

  .filter-section-container {
    align-items: center;
    justify-content: space-between;
  }
  #inputs-container {
    column-gap: var(--cox2m-spacing-4-units );
  }
  :global(#users-management-table-container thead) {
    z-index: 13 !important;
  }
  :global(#users-management-table-container tbody tr td:last-child) {
    position: sticky;
    right: 0;
    background-color: inherit !important;
  }
  @media only screen and (max-width: 1200px) {
    #user-form-container {
      max-width: calc(100% - var(--cox2m-spacing-4-units ));
      padding: var(--cox2m-spacing-4-units );
      padding-bottom: var(--cox2m-spacing-12-units);
    }
    .section-container {
      max-width: 100%;
      margin: 0 var(--cox2m-spacing-2-units );
    }
    .filter-section-container,
    #inputs-container {
      align-items: flex-end;
      flex-direction: column;
      gap: calc(var(--cox2m-spacing-4-units ) - var(--cox2m-spacing-1-units));
    }
    #inputs-container,
    #inputs-container > *,
    :global(#role-selector, #role-selector-container, #status-selector, #status-selector-container) {
      width: 100%;
    }
  }
</style>

<ViewAsUserModal {userToViewAs} bind:show={showViewAsUserModal} />
<EditUserModal
  on:userUpdateSuccess={refreshUsers}
  on:openBlockUserModal={e => {
    userToBlock = e.detail.userToBlock;
    showBlockUserModal = true;
  }}
  on:openUnblockUserModal={e => {
    userToUnblock = e.detail.userToUnblock;
    showUnblockUserModal = true;
  }}
  bind:show={showEditUserModal}
  {userToEdit} />
<BlockUserModal
  on:blockUserSuccess={refreshUsers}
  bind:show={showBlockUserModal}
  {userToBlock} />
<UnblockUserModal
  on:unblockUserSuccess={refreshUsers}
  bind:show={showUnblockUserModal}
  {userToUnblock} />

{#if loading && !fetchError}
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <LoadingSpinner />
  </div>
{:else if !loading && fetchError}
  <div
    class="d-flex row align-items-center justify-content-center w-100 h-100 mt-5
    pt-5">
    <Icon
      icon="cross-circle"
      className="mb-3"
      color="var(--cox2m-clr-brand-500)"
      size="var(--cox2m-spacing-16-units)" />
    <h2 class="text-center">
      We are sorry, we couldn't fetch the information correctly, refresh the
      page or try again later
    </h2>
  </div>
{:else}
  <div
    id="user-form-container"
    data-testid="user-form-container"
    class=" rounded w-100">
    <div class="section-container row">
      <div class="col-12 p-0 mb-5">
        <h4
          class="section-title font-weight-bold pt-3 mb-0"
          id="section-title"
          data-testid="section-title">
          Users, Permissions & Roles
        </h4>
        <p class="m-0 pt-2 section-subtitle">
          Manage users, permissions, and roles for the platform and the assigned
          solutions.
        </p>
      </div>
      <div class="col-12 m-0 p-0">
        <div class="d-flex filter-section-container flex-wrap">
          <div
            id="inputs-container"
            data-testid="inputs-container"
            class="d-flex">
            <div id="user-search-container" data-testid="user-search-container">
              <UserSearch
                bind:userName
                {getUsers}
                on:message={handleSearchUsersByName} />
            </div>
            <div class="d-flex">
              <SelectList
                minWidth="190px"
                defaultLabel="All Roles"
                borderColor="var(--cox2m-clr-brand-400)"
                iconColor="var(--cox2m-clr-brand-600)"
                options={rolesOptions}
                height="var(--cox2m-spacing-10-units)"
                id="role-selector"
                on:selectedOptionChange={e => (selectedRole = e.detail.value)} />
            </div>
            <div class="d-flex">
              <SelectList
                minWidth="190px"
                options={STATUS_OPTIONS}
                defaultLabel="All Statuses"
                borderColor="var(--cox2m-clr-brand-400)"
                iconColor="var(--cox2m-clr-brand-600)"
                height="var(--cox2m-spacing-10-units)"
                id="status-selector"
                on:selectedOptionChange={e => (selectedStatus = e.detail.value)} />
            </div>
          </div>

          <div>
            <Button
              id="add-user-button"
              text="Add user"
              backgroundColor="var(--cox2m-clr-brand-600)"
              icon="user-plus"
              iconColor="var(--cox2m-clr-neutral-white)"
              justifyContent="between"
              maxWidth="140px"
              iconSize="calc(var(--cox2m-spacing-4-units) + var(--cox2m-visual-corrector))"
              on:click={openAddUserModal} />
          </div>
        </div>
        {#if !fetchUserCountError}
          <div class="users-count-container d-flex">
            <p class="m-0">{userCount.total} Users</p>
            <p class="m-0">
              <strong>{userCount.blocked} blocked</strong>
            </p>
          </div>
        {/if}
        <div class="col-12 m-0 mt-3 p-0">
          <div class="align-items-center justify-content-between row">
            <Table
              id="users-management-table"
              {columns}
              rows={usersList}
              on:openEditUserModal={e => showEditUserModalHandler(e)}
              on:openBlockUserModal={e => showBlockUserModalHandler(e)}
              on:openViewAsUserModal={e => showViewAsUserModalHandler(e)}
              on:openUnblockUserModal={e => showUnblockUserModalHandler(e)}
              pagination
              isLoading={tableLoading}
              notFoundMessage={`No users found, try adjusting your filters or
              search terms`}
              itemsPerPage={20} />
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
