<script>
  import clickOutside from '@cox2m/city-services-ui-components/src/svelte-actions/clickOutside';
  import Popper from '@cox2m/city-services-ui-components/src/components/Popper.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {createEventDispatcher} from 'svelte';
  import {fly} from 'svelte/transition';
  import {user} from 'web-stores';

  export let props;

  const dispatch = createEventDispatcher();

  let expanded = false;
  let container;

  const indexCalculation = 100 - props.index;

  const handleItemAction = (e, callback) => {
    e.cancelBubble = true;
    expanded = false;
    callback();
  };

  const handleVisiblePopover = () => {
    container && (container.closest('td').style = `z-index: ${indexCalculation};`);
  }

  const handleNotVisiblePopover = () => {
    if (!expanded) {
      container && (container.closest('td').style = '');
    }
  }

  const addZindexToClosestTd = () => {
    if (expanded) {
      container && (container.closest('td').style = `z-index: ${indexCalculation};`);
    } else {
      setTimeout(() => (container && (container.closest('td').style = '')), 500);
    }

  }

  const isBlocked = () => {
    return $user.token.substring(0, 8) !== props.user.clientId && props.user.status === 'blocked'
  }

  const isBlockable = () => {
    return $user.organizationId === props.user.organization.id
  }

  $: addZindexToClosestTd(expanded);
</script>

<style>
  .actions-container {
    min-width: 190px;
    background-color: var(--cox2m-clr-neutral-white);
    z-index: 12;
    border-radius: 6px;
    right: 0;
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-500);
  }

  .option {
    cursor: pointer;
  }

  .option:hover {
    background-color: var(--cox2m-clr-brand-200);
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
  }

  @media only screen and (max-width: 1440px) {
    .option {
      font-size: var(--cox2m-fs-1000);
    }
  }
</style>

<Popper
  showEvents={['mouseenter']}
  hideEvents={['mouseleave', 'blur', 'click', 'focus']}
  on:popoverIsVisible={handleVisiblePopover}
  on:popoverNotVisible={handleNotVisiblePopover}
  hideTooltipComponent={expanded}>
  <div
    bind:this={container}
    slot="tooltip-trigger"
    class="position-relative
    cursor-pointer"
    use:clickOutside={() => {
      expanded = false;
    }}
    id="{props.user.clientId}-user-actions"
    data-testid="{props.user.clientId}-user-actions"
    on:click={() => {
      expanded = !expanded;
    }}>
    <Icon icon="more-vert" color="var(--cox2m-clr-brand-700)"/>
    {#if expanded}
      <div
        in:fly={{y: 50, duration: 500}}
        out:fly={{y: 50, duration: 500}}
        class="actions-container position-absolute py-2"
        id={`${props.user.clientId}-actions-container`}
        data-testid={`${props.user.clientId}-actions-container`}>
        <p
          class="w-100 option py-2 px-3 m-0"
          id={`${props.user.clientId}-edit-option`}
          data-testid={`${props.user.clientId}-edit-option`}
          on:click={e => handleItemAction(e, () =>
              dispatch('exteriorizeTableEvent', {
                eventName: 'openEditUserModal',
                exteriorDetails: props.user
              })
            )}>
          Edit user
        </p>
        {#if $user && $user.operations && $user.operations.includes('post-dummy-user-groups') && $user.token.substring(0, 8) !== props.user.clientId}
          <p
            class="w-100 option py-2 px-3 m-0"
            id={`${props.user.clientId}-mock-user-option`}
            data-testid={`${props.user.clientId}-mock-user-option`}
            on:click={e => handleItemAction(e, () =>
                dispatch('exteriorizeTableEvent', {
                  eventName: 'openViewAsUserModal',
                  exteriorDetails: props.user
                })
              )}>
            View as user
          </p>
        {/if}
        {#if isBlockable()}
          {#if !isBlocked()}
            <p
              class="w-100 option py-2 px-3 m-0"
              id={`${props.user.clientId}-block-option`}
              data-testid={`${props.user.clientId}-block-option`}
              on:click={e => handleItemAction(e, () =>
                dispatch('exteriorizeTableEvent', {
                  eventName: 'openBlockUserModal',
                  exteriorDetails: props.user
                })
              )}>
              Block user
            </p>
          {/if}
          {#if isBlocked()}
            <p
              class="w-100 option py-2 px-3 m-0"
              id={`${props.user.clientId}-unblock-option`}
              data-testid={`${props.user.clientId}-unblock-option`}
              on:click={e => handleItemAction(e, () =>
                dispatch('exteriorizeTableEvent', {
                  eventName: 'openUnblockUserModal',
                  exteriorDetails: props.user
                })
              )}>
              Unblock user
            </p>
          {/if}
        {/if}
      </div>
    {/if}
  </div>

  <div slot="tooltip-component">
    <span>More Options</span>
  </div>
</Popper>
