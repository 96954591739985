<script>
  import ToastMessages from '@cox2m/city-services-ui-components/src/main/components/notifications/ToastMessages.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MuiTextInput from '@cox2m/city-services-ui-components/src/forms/MuiTextInput.svelte';
  import CheckBox from '@cox2m/city-services-ui-components/src/components/CheckBox.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';

  import {
    getCookieByName,
    waitForElementExistance
  } from '@cox2m/city-services-ui-components/src/funcs.js';
  import {login, getActiveDirectoryDomains, getUserDomain, validateUserNoDomain} from 'web-actions';
  import {cleanCookies, saveRememberMe} from '../../../funcs';
  import {EMAIL_REGEX, PASSWORD_REGEX} from 'web-constants';
  import {onDestroy, onMount} from 'svelte';
  import {push} from 'svelte-spa-router';
  import {signIn} from 'web-auth';
  import {user} from 'web-stores';

  import {EventType} from '@azure/msal-browser';
  import MuiPasswordInput from '@cox2m/city-services-ui-components/src/forms/MuiPasswordInput.svelte';

  export let params = {};

  const cox2mLatestVisitedPage = getCookieByName(`${'ENV'}_cox2mlatesturl`);
  let password = '';
  let email = '';
  let editEmailEnabled = true;

  let activeDirectoryHighlight = false;
  let activeDirectoryDomainsResponse;

  let errorMessage = '';

  let loading = false;
  let toastNotifications = [];
  let rememberMe = true;

  $: hasLoginInformation =
    email.trim().length !== 0 && password.trim().length !== 0;

  $: isInformationValid =
    EMAIL_REGEX.test(email) && PASSWORD_REGEX.test(password);

  const addToastNotification = e => {
    toastNotifications = [...toastNotifications, e.detail];
  };

  const validateLoginInformation = () => {
    if (email.trim() === '') {
      errorMessage = 'Please type an email address.';
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = 'Please type a valid email address.';
    } else if (password.trim() === '') {
      errorMessage = 'Please type your password.';
    } else if (!PASSWORD_REGEX.test(password)) {
      errorMessage =
        'Passwords must have no spaces, be at least 8 characters long, contain one lowercase letter, uppercase letter, number, and symbol.';
    } else {
      errorMessage = '';
    }
  }

  const handlePasswordKeyPress = e => {
    if (e.key === 'Enter' && !loading) {
      handleLoginClick();
    }
  };

  const saveToken = data => {
    if (document.location.hostname === 'localhost') {
      document.cookie = `token=${data.token};`;
      document.cookie = `${'ENV'}_token=${data.token};`;
      document.cookie = `${'ENV'}_user=${JSON.stringify(data)};`;
    } else {
      const expirationDate = new Date();
      const minutes = 60;
      const hours = 24;
      expirationDate.setTime(
        expirationDate.getTime() + minutes * hours * 60 * 1000
      );
      document.cookie = `${'ENV'}_user=${JSON.stringify(
        data
      )};expires=${expirationDate.toUTCString()};path=/;domain=cox2m.com`;
      document.cookie = `${'ENV'}_token=${
        data.token
      };expires=${expirationDate.toUTCString()};path=/;domain=cox2m.com`;
      document.cookie = `token=${
        data.token
      };expires=${expirationDate.toUTCString()};path=/;domain=cox2m.com`;
    }
    redirectUser();
  };

  const redirectUser = () => {
    if (
      cox2mLatestVisitedPage !== null &&
      cox2mLatestVisitedPage !== '' &&
      !cox2mLatestVisitedPage.includes('#/account/password/forgot')
    ) {
      window.location.href = cox2mLatestVisitedPage;
    } else {
      push('/');
    }
  };

  const activeDirectoryHandler = async (domain, additionalLoginInfo) => {
    loading = false;

    errorMessage =``
      'Your organization uses Active Directory integration for login.';
    await handleLoginWithActiveDirectoryClick(
      domain.systemUsername,
      additionalLoginInfo
    );
  };

  const handleLoginClick = async () => {
    validateLoginInformation();
    if (hasLoginInformation && isInformationValid) {
      loading = true;

      const {fulfilled} = await validateUserNoDomain(email);
      const domainResponse =
        (await getUserActiveDirectory()) ||
        (await isEmailSupportedByActiveDirectory());

      let oneSignalInfo = {};
      if (navigator.userAgent.indexOf('gonative') > -1) {
        oneSignalInfo = await window.gonative.onesignal.onesignalInfo();
      }

      if (domainResponse && !fulfilled) {
        activeDirectoryHighlight = true;
        await activeDirectoryHandler(domainResponse.domain, oneSignalInfo);
      } else {
        const response = await login({
          email,
          password,
          additionalLoginInfo: oneSignalInfo
        });
        const {fulfilled, ...data} = response;
        if (fulfilled) {
          data.email = email;
          user.saveUser(data);
          saveToken(data);
        } else {
          loading = false;
          errorMessage = data.message;
        }
      }
    }
  };

  const handleLoginWithActiveDirectoryClick = async (
    aadClientId,
    additionalLoginInfo
  ) => {
    loading = true;
    const {fulfilled} = await signIn(
      aadClientId,
      email,
      message => {
        if (message.eventType === EventType.LOGIN_FAILURE) {
          window.location.reload();
        }
      },
      additionalLoginInfo
    );

    if (fulfilled) {
      if (cox2mLatestVisitedPage !== null && cox2mLatestVisitedPage !== '') {
        window.location.href = cox2mLatestVisitedPage;
      } else {
        push('/');
      }
    } else {
      push('/start');
    }
  };

  const handleForgotPasswordClick = () => {
    push('/account/password/forgot');
  };

  const getDomains = async () => {
    activeDirectoryDomainsResponse = await getActiveDirectoryDomains();
  };

  const getUserActiveDirectory = async () => {
    const {fulfilled, ...data} = await getUserDomain(email);
    if (fulfilled) {
      return data;
    } else {
      loading = false;
      errorMessage = data.message;
    }
    return false;
  };

  /**
   * Checks if the email domain is supported by Active directory existing domains
   * @returns {*}
   */
  const isEmailSupportedByActiveDirectory = () => {
    let emailDomain = email
      .split('@')[1]
      .trim()
      .toLowerCase();
    return (
      activeDirectoryDomainsResponse &&
      activeDirectoryDomainsResponse.response &&
      activeDirectoryDomainsResponse.response.find(element =>
        emailDomain.includes(element.domain)
      )
    );
  };

  const editEmail = () => {
    editEmailEnabled = !editEmailEnabled;
  };

  onMount(() => {
    getDomains();
    cleanCookies({shouldRemoveLatestVisitedUrl: false});
    window.addEventListener('successEvent', addToastNotification);
    if (params.client) {
      email = params.client;
      editEmailEnabled = false;
    } else {
      email = getCookieByName(`${'ENV'}_rememberme`);
    }

    waitForElementExistance('#password-input').then(() => {
      document.getElementById('password-input').focus();
    });
  });

  onDestroy(() => {
    const emailToSave = rememberMe ? email : '';
    saveRememberMe(emailToSave);
  });
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-500);
  }
  .rememberme-checkbox-label {
    color: var(--cox2m-clr-neutral-black);
  }

  .login-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    height: clamp(150px, 200px, 250px);
    justify-content: space-evenly;
    padding-bottom: var(--cox2m-spacing-4-units);
  }

  .anchor-container {
    height: var(--cox2m-spacing-11-units);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: clamp(
      var(--cox2m-spacing-8-units),
      var(--cox2m-spacing-18-units),
      var(--cox2m-spacing-18-units)
    );
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
    margin-top: 0;
  }

  .paragraph-button {
    color: var(--cox2m-clr-brand-500);
    text-decoration: none;
  }

  .paragraph-button:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    margin-top: var(--cox2m-spacing-4-units);
  }

  .rememberme-container {
    display: flex;
  }

  label {
    font-weight: var(--cox2m-fw-bold);
    font-size: clamp(
      var(--cox2m-fs-1300),
      var(--cox2m-fs-1000),
      var(--cox2m-fs-1000)
    ) !important;
    line-height: clamp(
      var(--cox2m-spacing-3-units),
      var(--cox2m-spacing-4-units),
      var(--cox2m-spacing-4-units)
    ) !important;
    margin-left: calc(
      var(--cox2m-spacing-2-units) + var(--cox2m-visual-corrector)
    );
  }

  :global(.edit-icon) {
    align-self: center;
    position: fixed;
    margin-left: 446px;
  }

  .fs-1000 {
    font-size: var(--cox2m-fs-1000);
  }

  .fs-1200 {
    font-size: var(--cox2m-fs-1200);
  }

  @media (max-width: 768px) {
    :global(.edit-icon) {
      right: 8%;
    }
  }
</style>

<div class="login-container">
  <div class="form-container">
    <MuiTextInput
      id="email-input"
      name="email"
      label="Email"
      padding="var(--cox2m-spacing-3-units)"
      bind:value={email}
      disabled={activeDirectoryHighlight || !editEmailEnabled}
      customContainerClass="w-100"
      icon="edit"
      iconClick={editEmail}/>
    <MuiPasswordInput
      id="password-input"
      label="Password"
      bind:value={password}
      customContainerClass="w-100"
      disabled={activeDirectoryHighlight}
      onKeyPress={handlePasswordKeyPress} />

    {#if errorMessage}
      <p class="error-message fs-1200">{errorMessage}</p>
    {/if}
  </div>
  <ToastMessages {toastNotifications} />
  {#if !activeDirectoryHighlight}
    <div class="anchor-container fs-1000">
      <div class="rememberme-container border-0">
        <CheckBox id="rememberme-checkbox" bind:checked={rememberMe} />
        <label class="rememberme-checkbox-label" for="rememberme-checkbox">
          Remember me
        </label>
      </div>
      <p
        id="forgot-password"
        class="paragraph-button fs-1000"
        on:click={handleForgotPasswordClick}>
        Forgot password?
      </p>
    </div>
  {/if}
  <div>
    {#if !loading}
      <Button
        id="login-button"
        text={!activeDirectoryHighlight ? 'Login' : 'Login With Active Directory'}
        backgroundColor="var(--cox2m-clr-brand-600)"
        on:click={handleLoginClick}
        disabled={!hasLoginInformation || !isInformationValid}
        customClass="cox2m-shadow-1-dp {hasLoginInformation && isInformationValid ? 'active' : ''}" />
    {:else}
      <LoadingSpinner />
    {/if}
  </div>
</div>
