<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import UserLogs from '../forms/users/components/UserLogs.svelte';

  import {menuOpts, user} from 'web-stores';
</script>

<style>
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
</style>

<Modal>
  <MainScreen title="Logs" menuOpt={$menuOpts} user={$user} showAlertsBanner={false} appSlug="platform" smartCitiesUrl={true}>
    <div slot='main-dashboard' class="dashboard-component-container">
      <UserLogs appSlug="platform" />
    </div>
  </MainScreen>
</Modal>
