<script>
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import Router, {replace, location} from 'svelte-spa-router';
  import routes, {openRoutes} from 'web-routes';

  import {initializePendo, trackEvent} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {initializeIdleTimer} from '@cox2m/city-services-ui-components/src/idle-timer.js';
  import {onMount} from 'svelte';

  import {
    staticMenuOptions,
    buildMenuWithDynamicItems
  } from './menuOptions/populate-menu';
  import {user, menuOpts} from 'web-stores';

  const createLatestUrlCookie = () => {
    const ignorableHashes = [
      '#/login',
      '#/logout',
      '/#/account/password/forgot',
      '#/account/password/reset',
      '#/start'
    ];
    if (!ignorableHashes.includes(window.location.hash)) {
      const currentDate = new Date();
      const futureDate = new Date(currentDate.getTime() + 600000);
      document.cookie = `${'ENV'}_cox2mlatesturl=${
        window.location.href
      };expires=${futureDate.toUTCString()};domain=cox2m.com;`;
    }
  };

  console.info('[version: VERSION]');

  const setMenuOptions = async () => {
    if ($user && $user.token) {
      menuOpts.updateMenuValues(staticMenuOptions($user));
      menuOpts.updateMenuValues(await buildMenuWithDynamicItems($user));
    }
  };

  /**
   * Go to start page
   */
  const start = () => {
    if (!openRoutes.find(route => $location.includes(route))) {
      replace('/start');
    }
  };

  $: if (!$user.token) {
    start();
  }

  const routeLoaded = () => {
    createLatestUrlCookie();
  };

  /**
   * Send a network error event as an analytics event
   * @param event
   */
  const sendNetworkErrorAsEvent = event => {
    trackEvent(event.detail.title, event.detail);
    if (event.detail.title.startsWith('Error 401:')) {
      start();
    }
  };

  onMount(async () => {
    const account = {
      id: $user.personId,
      givenName: $user.givenName,
      familyName: $user.familyName,
      email: $user.email
    };

    initializePendo(account, account);
    window.addEventListener('networkErrorEvent', sendNetworkErrorAsEvent);
    await initializeIdleTimer($user.token);
  });

  $: setMenuOptions($user);
</script>

<style>
  :global(body) {
    display: flex;
    background: linear-gradient(
      135deg,
      var(--cox2m-clr-primary-identity-light) 0%,
      var(--cox2m-clr-primary-identity-dark) 100%
    );
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    font-family: 'montserrat', 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
      Cantarell, 'Helvetica Neue', sans-serif;
  }

  :global(::-webkit-scrollbar) {
    width: 10px;
  }

  /* Track */
  :global(::-webkit-scrollbar-track) {
    background: var(--cox2m-clr-neutral-400);
    border-radius: 10px;
  }

  /* Handle */
  :global(::-webkit-scrollbar-thumb) {
    background: var(--cox2m-clr-neutral-600);
    border-radius: 10px;
  }

  /* Handle on hover */
  :global(::-webkit-scrollbar-thumb:hover) {
    background: var(--cox2m-clr-neutral-700);
  }
</style>

<svelte:head>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID">

  </script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'GA_TRACKING_ID');
    window.gtag = gtag;
  </script>
  <script>
    const pendoApiKey = `${'PENDO_API_KEY'}`;
    (function(apiKey) {
      (function(p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src =
          'https://cdn.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(pendoApiKey);
  </script>
</svelte:head>

<Modal>
  <Router {routes} on:routeLoaded={routeLoaded} />
</Modal>
