import {getContext} from 'svelte';

/**
 * Verifies if a string is empty or not
 * @param {string} str
 * @returns {boolean}
 */
export const isEmpty = (str) => !str || str === '';

/**
 * Capitalize a given string
 * @param {string} str
 * @returns {string}
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1);

/**
 * Converts a string to Title Case. e.g.: fooBar => Foo Bar
 * @param {string} str
 * @returns {string}
 */
export const titleCase = str => {
  if (!str) {
    return '';
  }
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

/**
 * Time since the given date
 * @param {Date} date
 * @returns {string}
 */
export const timeSince = date => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }

  return Math.floor(seconds) + " seconds";
};

export function openModalComponent(Component, props, options) {
  const {open, close} = getContext('simple-modal');
  open(Component, {
    handleModalClose: close,
    ...props,
  }, {...options});
}

export async function asyncForEach(arr, callback) {
  const waitFor = (ms) => new Promise(r => setTimeout(r, ms));

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  await asyncForEach(arr, async val => {
    await waitFor(50);
    await callback(val);
  });
}

export function currentHost() {
  const url = window.location.href;
  const arr = url.split("/");
  return `${arr[0]}//${arr[2]}`;
}

/**
 * Clean the existing cookies
 */
export function cleanCookies({shouldRemoveLatestVisitedUrl}) {
  if (document.location.hostname === 'localhost') {
    shouldRemoveLatestVisitedUrl && (document.cookie = `${'ENV'}_cox2mlatesturl=;`);
    document.cookie = `${'ENV'}_token=;`;
    document.cookie = `token=;`;
  } else {
    shouldRemoveLatestVisitedUrl && (document.cookie = `${'ENV'}_cox2mlatesturl=;path=/;domain=cox2m.com`);
    document.cookie = `${'ENV'}_token=;path=/;domain=cox2m.com`;
    document.cookie = `token=;path=/;domain=cox2m.com`;
  }
}

/**
 * Save the "remember me" cookie
 * @param email
 */
export function saveRememberMe(email) {
  if (document.location.hostname === 'localhost') {
    document.cookie = `${'ENV'}_rememberme=${email};`;
  } else {
    document.cookie = `${'ENV'}_rememberme=${email};path=/;domain=cox2m.com`;
  }
}
