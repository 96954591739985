<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import TextArea from '@cox2m/city-services-ui-components/src/forms/TextArea.svelte';

  import {createEventDispatcher, afterUpdate} from 'svelte';
  import {user} from 'web-stores';

  import {MONTHS, PHONE_NUMBER_REGEX} from '../../../constants';

  export let userToEdit = null;

  const dispatch = createEventDispatcher();

  let initialBlockageReason = userToEdit.blockage_reason || '';
  let blockage_reason = initialBlockageReason || '';

  let initialPhoneNumber = userToEdit.phoneNumber || '';
  let phoneNumber = initialPhoneNumber || '';

  let isBlockageValid = true;
  let isPhoneValid = true;

  const formatBlockDate = date => {
    const d = new Date(date);
    return `${MONTHS[d.getMonth()].substring(
      0,
      3
    )} ${d.getDate()}, ${d.getFullYear()} ${
      d.getHours() > 12 ? d.getHours() - 12 : d.getHours()
    }:${d.getMinutes()} ${d.getHours() >= 12 ? 'PM' : 'AM'}`;
  };

  const dispatchShouldBlock = async () => {
    if (userToEdit) {
      dispatch('shouldBlockSaveButton', {
        shouldBlock:
          (blockage_reason === initialBlockageReason &&
            phoneNumber === initialPhoneNumber) ||
          !isFormValid(),
        blockage_reason: blockage_reason,
        phoneNumber: phoneNumber
      });
    } else {
      dispatch('shouldBlockSaveButton', {
        shouldBlock: true,
        blockage_reason: null,
        phoneNumber: null
      });
    }
  };

  const resetUserFormData = () => {
    initialBlockageReason = userToEdit.blockage_reason || '';
    initialPhoneNumber = userToEdit.phoneNumber || '';

    blockage_reason = initialBlockageReason || '';
    phoneNumber = initialPhoneNumber || '';

    isBlockageValid = true;
    isPhoneValid = true;
  };

  const isFormValid = () => {
    return isPhoneValid && isBlockageValid;
  };

  afterUpdate(() => {
    dispatchShouldBlock();
  });

  $: resetUserFormData(userToEdit);

  const isBlockable = () => {
    return $user.organizationId === userToEdit.organization.id
  }

</script>

<style>
  .data-inputs-container {
    gap: var(--cox2m-spacing-4-units);
  }

  .unblock-user-tag {
    color: var(--cox2m-clr-brand-600);
    font-size: var(--cox2m-fs-1000);
    font-weight: var(--cox2m-fw-bold);
    text-decoration: underline;
  }

  .blocked-user-info-content {
    gap: var(--cox2m-spacing-4-units);
  }

  .blocked-user-label,
  .blocked-user-date,
  .blocked-user-note-label,
  .form-label {
    color: var(--cox2m-clr-neutral-black);
    font-size: var(--cox2m-fs-1000);
    font-weight: var(--cox2m-fw-bold);
  }

  .blocked-user-label,
  .blocked-user-note-label,
  .form-label {
    font-weight: var(--cox2m-fw-medium);
  }

  .blocked-user-reason-container {
    margin-top: var(--cox2m-spacing-4-units);
  }

  .blocked-user-note-label {
    margin-bottom: var(--cox2m-spacing-2-units);
  }

  @media only screen and (max-width: 1200px) {
    .data-inputs-container {
      flex-direction: column;
    }
  }
</style>

{#if userToEdit.status === 'blocked'}
  <div class="blocked-user-info-container">
    <div class="d-flex blocked-user-info-content-container align items-center">
      <div
        class="d-flex blocked-user-info-content align-items-center
        justify-content-between">
        <span class="blocked-user-label">User Blocked on:</span>
        <span class="blocked-user-date">
          {formatBlockDate(userToEdit.blockage_date)}
        </span>
        {#if isBlockable()}
          <span
            class="unblock-user-tag cursor-pointer" id="unlock-user-tag"
            data-testid="unlock-user-tag"
            on:click={() => dispatch('openUnblockUserModal', {
                userToUnblock: userToEdit
              })}>
            Unblock User
          </span>
        {/if}

      </div>
    </div>
    <div class="blocked-user-reason-container">
      <span class="blocked-user-note-label">Note</span>
      <!-- need to fix the endpoint so the blockage reason can be updated -->
      <TextArea
        id="blockage-reason"
        bind:value={blockage_reason}
        disabled={$user.token.substring(0, 8) === userToEdit.clientId || $user.token.substring(0, 8) !== userToEdit.user_who_blocked}
        validationFunction={() => userToEdit.status !== 'blocked' || (userToEdit.status === 'blocked' && blockage_reason.length > 5)}
        onValidationSuccess={() => (isBlockageValid = true)}
        onValidationFailure={() => (isBlockageValid = false)}
        placeholder="Blockage Reason"/>
    </div>
  </div>
{/if}

<div class="d-flex data-inputs-container">
  <div class="w-100">
    <span class="form-label">Phone Number (Optional)</span>
    <TextInput
      id="phoneNumber"
      name="phoneNumber"
      placeholder="Phone Number"
      bind:value={phoneNumber}
      disabled={$user.token.substring(0, 8) === userToEdit.clientId}
      validationFunction={() => PHONE_NUMBER_REGEX.test(phoneNumber)}
      onValidationSuccess={() => (isPhoneValid = true)}
      onValidationFailure={() => (isPhoneValid = false)}/>
  </div>
  <div class="w-100">
    <span class="form-label">Email</span>
    <TextInput
      id="emailAddress"
      name="Email"
      placeholder="Email Address"
      value={userToEdit.email || ''}
      disabled={true}/>
  </div>
</div>
