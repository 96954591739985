<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {slide} from 'svelte/transition';

  export let iconColor = 'var(--cox2m-clr-brand-600)';
  export let openIcon = 'chevron-down';
  export let closeIcon = 'chevron-up';
  export let showExpander = false;
  export let iconSize = 'var(--cox2m-spacing-5-units)';
  export let subItem = false;
  export let id = 'test_id';
  export let title = '';
  export let description = '';

  export let nestingNumber = 0;

  let isOpen = false;
  const toggle = () => (isOpen = !isOpen);
</script>

<style>
  .item-content-container {
    padding: var(--cox2m-spacing-3-units) var(--cox2m-spacing-8-units);
  }
  * {
    outline: none;
  }
  .item-checkbox-container {
    gap: var(--cox2m-spacing-8-units );
  }
  .sub-item {
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-300);
  }
  .item-title{
    font-weight: var(--cox2m-fw-medium);
  }
  .item-description{
    font-size: var(--cox2m-fs-1000);
  }
</style>

<div
  class={subItem ? 'sub-item' : ''}
  >
  <div
    class="w-100 d-flex justify-content-between align-items-center
    item-content-container " style="padding-left: {24 * (nestingNumber+1)}px;">
    <div class="d-flex align-items-center item-checkbox-container">
      <span>
        <slot name="item-checkbox" />
      </span>
      <span>
        <p class="m-0 item-title">{title}</p>
        {#if description && description.length}
          <p class="m-0 item-description">{description}</p>
        {/if}
      </span>
    </div>

    {#if showExpander}
      <span on:click={toggle} class="cursor-pointer" aria-expanded={isOpen}>
        <Icon
          icon={isOpen ? closeIcon : openIcon}
          size={iconSize}
          color={iconColor}
          id="{id}-expand-button" />
      </span>
    {/if}
  </div>

  {#if isOpen}
    <div transition:slide={{duration: 300}}>
      <slot />
    </div>
  {/if}

</div>
