<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  export let props;
  export let width = "var(--cox2m-spacing-8-units)";
  export let height = "var(--cox2m-spacing-8-units)";

  export let showName = true;
</script>

<style>
  .user-avatar {
    background-color: var(--cox2m-clr-neutral-white);
    color: var(--cox2m-clr-neutral-white);
  }
  .user-label-container {
    display: grid;
    grid-template-columns: var(--cox2m-spacing-8-units) 1fr;
    gap: calc(var(--cox2m-spacing-2-units) + var(--cox2m-visual-corrector));
    padding-left: var(--cox2m-spacing-2-units);
    align-items: center;
  }
</style>

<div class="user-label-container">
  <div
    style="width: {width}; height: {height};"
    class="user-avatar rounded-circle d-flex align-items-center
    justify-content-center cox2m-smalltext-1 cox2m-shadow-1-dp">
    {#if props.user.isActiveDirectory}
      <img
        style="width: {width}; height: {height};"
        src="images/active-directory-icon-img.png"
        alt="active-directory-icon" />
    {:else}
      <Icon
        icon="user-circle"
        size="var(--cox2m-spacing-5-units)"
        color="var(--cox2m-clr-neutral-700)" />
    {/if}
  </div>
  {#if showName}
    <span>{props.name}</span>
  {/if}
</div>
