<script>
  import Stepper from '@cox2m/city-services-ui-components/src/forms/Stepper.svelte';
  import InviteDetails from './steps/InviteDetails.svelte';
  import FirstStep from './steps/FirstStep.svelte';

  export let handleModalClose = () => {};

  let steps = [
    {Component: FirstStep, title: 'Add New User'},
    {Component: InviteDetails, title: 'Add New User'}
  ];
</script>

<style>
  div {
    height: 520px;
  }
</style>

<div id="add-user-container" data-testid="add-user-container" class="add-user-container">
  <Stepper bind:steps onFinishClick={handleModalClose} />
</div>
