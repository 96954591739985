<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  export let props;

  let icon;
  let label;

  const setDeviceFromProps = () => {
    if(props.lastLoginContext === ''){
      icon = '';
      label = 'N/A'
    }

    else if(props.lastLoginContext === 'app'){
      icon = 'smartphone';
      label = 'Native app';
    }

    else if(props.lastLoginContext === 'browser'){
      icon = 'globe';
      label = 'Browser';
    }
  }

  $: setDeviceFromProps(props);
</script>

<style>
  .device-icon-container{
    margin-left: var(--cox2m-spacing-2-units);
  }
</style>

<span class="d-flex align-items-center">
  <span>{label}</span>
  {#if icon && label}
  <span class="device-icon-container">
    <Icon {icon} size="var(--cox2m-spacing-5-units)" color="var(--cox2m-clr-neutral-black)" />
  </span>
  {/if}
</span>
