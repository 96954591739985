<script>
  import Select from 'svelte-select/src/Select.svelte';
  export let items = [];
  export let value;
  export let placeholder = '';
  export let id = '';
  export let label = '';
  export let customClass = '';
  export let disabled = false;
  export let onChange = () => {};
  let container;

  function handleChange(e) {
    value = e.detail ? e.detail.map(({value}) => value).join(',') : '';
    if (container) {
      container.value = value;
    }
    onChange(value);
  }

  const INPUT_STYLES = `
    height: calc(var(--cox2m-spacing-12-units) + var(--cox2m-visual-corrector));
    width: 100%; border-radius: 5px; background-color: var(--cox2m-clr-neutral-white);
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-300); font-size: var(--cox2m-fs-1000) ; margin: 0 0 0.5em 0;`;

  $: selectedValue =
    value !== undefined && value !== '' ? value.split(',') : undefined;

  $: if (container) {
    container.id = id;
    container.options = items;
  }
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-black);
    margin: 0.5em 0px;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

<div>
  {#if label}
    <p class="fs-1000" for={id}>{label}</p>
  {/if}
  <Select
    {items}
    bind:selectedValue
    {placeholder}
    isMulti={true}
    inputAttributes={{id: `${id}-input`}}
    isDisabled={disabled}
    on:select={handleChange}
    bind:container
    containerStyles={INPUT_STYLES}
    containerClasses={customClass} />
</div>
