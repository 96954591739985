import {findByKeyRecursively} from '@cox2m/city-services-ui-components/src/funcs';
import {
  getAppsWithEventDefinition,
  getAllNotificationsConfigurationsByCliendId
} from '../actions';


export const staticMenuOptions = (user) => ([
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: '/',
    shouldShow: true
  },
  {
    key: 'settings',
    name: 'Settings',
    icon: 'gear-2',
    path: null,
    shouldShow: true,
    subItems: [
      {
        key: 'users-management',
        name: 'User Management',
        icon: 'user',
        path: null,
        shouldShow: true,
        subItems: [
          {
            key: 'users',
            name: 'Users',
            icon: 'user',
            path: '/users',
            shouldShow:
              user &&
              user.operations &&
              user.operations.includes('SearchUser') &&
              user.operations.includes('patch-users-clientId'),
          },
          {
            key: 'roles',
            path: '/roles',
            name: 'Roles',
            icon: 'lock-2',
            shouldShow: true //THIS PERMISSION MUST BE MODIFIED BUT I DONT KNOW THE OPERATION
          },
          {
            key: 'profile',
            path: '/account/profile/edit',
            name: 'Edit profile',
            icon: 'user-square',
            shouldShow: true
          },
          {
            key: 'password',
            path: '/account/profile/edit/password',
            name: 'Change password',
            icon: 'eye-slash',
            shouldShow: true
          }
        ]
      },
      {
        key: 'alerts',
        name: 'Alerts+Notifications',
        icon: 'bell',
        path: null,
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-notifications-metrics-by-app-id')
      },
      {
        key: 'new-alerts',
        name: 'New Alerts+Notifications',
        icon: 'bell',
        path: null,
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-notifications-metrics-by-app-id') && `${'NEW_ALERTS_UI'}` === 'true'
      },
      {
        key: 'logs',
        path: '/monitoring/logs',
        name: 'Logs',
        icon: 'devices',
        shouldShow: true
      },
      {
        key: 'organization-settings',
        path: '/monitoring/organization-settings',
        name: 'Organization Settings',
        icon: 'nut',
        shouldShow: true
      }
    ]
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
]);

const buildAlertOptions = async (user) => {
  const [appsResponse, responseSubscriptions] = await Promise.all([
    getAppsWithEventDefinition({token: user.token}),
    getAllNotificationsConfigurationsByCliendId(user.token)
  ]);

  try {
    if(appsResponse.apps){
      return await appsResponse.apps.reduce((accumulator, current, index) => {
        accumulator[index] = {
          key: `alerts-${current.slug}`,
          name: current.name,
          path: `/alerts/${current.slug}`,

          shouldShow: true,
          icon:
            responseSubscriptions.collection.findIndex(
              currentSubscription =>
                currentSubscription.eventDefinition.app.appId === current.appId
            ) !== -1
              ? 'bell'
              : 'bell-slash',
          props: {
            appName: current.slug,
            id: current.appId
          }
        };
        return accumulator;
      }, []);
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

const buildNewAlertOptions = async (user) => {
  const [appsResponse, responseSubscriptions] = await Promise.all([
    getAppsWithEventDefinition({token: user.token}),
    getAllNotificationsConfigurationsByCliendId(user.token)
  ]);

  try {
    if(appsResponse.apps){
      return await appsResponse.apps.reduce((accumulator, current, index) => {
        accumulator[index] = {
          key: `new-alerts-${current.slug}`,
          name: current.name,
          path: `/new-alerts/${current.slug}`,

          shouldShow: true,
          icon:
            responseSubscriptions.collection.findIndex(
              currentSubscription =>
                currentSubscription.eventDefinition.app.appId === current.appId
            ) !== -1
              ? 'bell'
              : 'bell-slash',
          props: {
            appName: current.slug,
            id: current.appId
          }
        };
        return accumulator;
      }, []);
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const buildMenuWithDynamicItems = async (user) => {
  let tempMenuOptions = staticMenuOptions(user);

  let alertEntry = findByKeyRecursively({
    searchCriteria: 'alerts',
    array: tempMenuOptions,
    idKey: 'key',
    subArrayKey: 'subItems'
  });
  alertEntry.subItems = await buildAlertOptions(user);

  let newAlertsEntry = findByKeyRecursively({
    searchCriteria: 'new-alerts',
    array: tempMenuOptions,
    idKey: 'key',
    subArrayKey: 'subItems'
  });

  newAlertsEntry.subItems = await buildNewAlertOptions(user);


  return tempMenuOptions;
}


































