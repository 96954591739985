<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';

  export let fetchFunction = () => {};
  export let groupName = '';

  let timer;

  const debounceRolesSearch = () => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fetchFunction()
		}, 500);
	}
</script>

<style>
  :global(#user-search-container div:first-of-type){
    height: var(--cox2m-spacing-11-units) !important;
  }
</style>

<TextInput
  id="user-search"
  onChange={debounceRolesSearch}
  bind:value={groupName}
  name="user-search"
  icon="search"
  iconColor="var(--cox2m-clr-brand-600)"
  backgroundColor="var(--cox2m-clr-brand-200)"
  placeholder="Search"
  borderRadius="var(--cox2m-spacing-15-units)"
  customContainerClass="border-0" />

