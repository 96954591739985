<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import AddUser from 'web-forms/inviteUser/InviteUser.svelte';
  import UsersForm from 'web-forms/users/UsersForm.svelte';

  import {openModalComponent} from 'web-funcs';
  import {menuOpts, user} from 'web-stores';

  function openAddUserModal() {
    openModalComponent(AddUser);
  }
</script>

<Modal>
  <MainScreen title="Users" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="platform" smartCitiesUrl={true}>
    <div slot="main-dashboard" class="d-block h-100">
      <UsersForm {openAddUserModal} />
    </div>

    <span
      slot="main-header"
      class="col d-none d-sm-flex d-md-none justify-content-center">
    </span>
  </MainScreen>
</Modal>
