"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ActiveDirectoryDomainDto", {
  enumerable: true,
  get: function get() {
    return _ActiveDirectoryDomainDto["default"];
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient["default"];
  }
});
Object.defineProperty(exports, "ApiKeyRequest", {
  enumerable: true,
  get: function get() {
    return _ApiKeyRequest["default"];
  }
});
Object.defineProperty(exports, "ApiKeyResponse", {
  enumerable: true,
  get: function get() {
    return _ApiKeyResponse["default"];
  }
});
Object.defineProperty(exports, "AppActivationDto", {
  enumerable: true,
  get: function get() {
    return _AppActivationDto["default"];
  }
});
Object.defineProperty(exports, "AppActivationTrackerDto", {
  enumerable: true,
  get: function get() {
    return _AppActivationTrackerDto["default"];
  }
});
Object.defineProperty(exports, "AppDto", {
  enumerable: true,
  get: function get() {
    return _AppDto["default"];
  }
});
Object.defineProperty(exports, "AppStoreDto", {
  enumerable: true,
  get: function get() {
    return _AppStoreDto["default"];
  }
});
Object.defineProperty(exports, "AppVendorDto", {
  enumerable: true,
  get: function get() {
    return _AppVendorDto["default"];
  }
});
Object.defineProperty(exports, "AppsApi", {
  enumerable: true,
  get: function get() {
    return _AppsApi["default"];
  }
});
Object.defineProperty(exports, "AuthApi", {
  enumerable: true,
  get: function get() {
    return _AuthApi["default"];
  }
});
Object.defineProperty(exports, "BlockageReasonDto", {
  enumerable: true,
  get: function get() {
    return _BlockageReasonDto["default"];
  }
});
Object.defineProperty(exports, "ChangePasswordRequest", {
  enumerable: true,
  get: function get() {
    return _ChangePasswordRequest["default"];
  }
});
Object.defineProperty(exports, "CityLightsReportApi", {
  enumerable: true,
  get: function get() {
    return _CityLightsReportApi["default"];
  }
});
Object.defineProperty(exports, "ClientLogDto", {
  enumerable: true,
  get: function get() {
    return _ClientLogDto["default"];
  }
});
Object.defineProperty(exports, "CountUserDto", {
  enumerable: true,
  get: function get() {
    return _CountUserDto["default"];
  }
});
Object.defineProperty(exports, "CounterVideoProviderDto", {
  enumerable: true,
  get: function get() {
    return _CounterVideoProviderDto["default"];
  }
});
Object.defineProperty(exports, "DefaultApi", {
  enumerable: true,
  get: function get() {
    return _DefaultApi["default"];
  }
});
Object.defineProperty(exports, "DepartmentDto", {
  enumerable: true,
  get: function get() {
    return _DepartmentDto["default"];
  }
});
Object.defineProperty(exports, "DepartmentsApi", {
  enumerable: true,
  get: function get() {
    return _DepartmentsApi["default"];
  }
});
Object.defineProperty(exports, "DevicesApi", {
  enumerable: true,
  get: function get() {
    return _DevicesApi["default"];
  }
});
Object.defineProperty(exports, "DomainDto", {
  enumerable: true,
  get: function get() {
    return _DomainDto["default"];
  }
});
Object.defineProperty(exports, "DummyUserApi", {
  enumerable: true,
  get: function get() {
    return _DummyUserApi["default"];
  }
});
Object.defineProperty(exports, "EditUserDto", {
  enumerable: true,
  get: function get() {
    return _EditUserDto["default"];
  }
});
Object.defineProperty(exports, "EditUserProfileDto", {
  enumerable: true,
  get: function get() {
    return _EditUserProfileDto["default"];
  }
});
Object.defineProperty(exports, "EmailSubscriptionApi", {
  enumerable: true,
  get: function get() {
    return _EmailSubscriptionApi["default"];
  }
});
Object.defineProperty(exports, "EmailSubscriptionDto", {
  enumerable: true,
  get: function get() {
    return _EmailSubscriptionDto["default"];
  }
});
Object.defineProperty(exports, "EmbedConfig", {
  enumerable: true,
  get: function get() {
    return _EmbedConfig["default"];
  }
});
Object.defineProperty(exports, "ErrorResponse", {
  enumerable: true,
  get: function get() {
    return _ErrorResponse["default"];
  }
});
Object.defineProperty(exports, "EventApi", {
  enumerable: true,
  get: function get() {
    return _EventApi["default"];
  }
});
Object.defineProperty(exports, "EventDefinitionByGroupDto", {
  enumerable: true,
  get: function get() {
    return _EventDefinitionByGroupDto["default"];
  }
});
Object.defineProperty(exports, "EventDefinitionBySlugGroupDto", {
  enumerable: true,
  get: function get() {
    return _EventDefinitionBySlugGroupDto["default"];
  }
});
Object.defineProperty(exports, "EventDefinitionDto", {
  enumerable: true,
  get: function get() {
    return _EventDefinitionDto["default"];
  }
});
Object.defineProperty(exports, "EventDefinitionFilterDto", {
  enumerable: true,
  get: function get() {
    return _EventDefinitionFilterDto["default"];
  }
});
Object.defineProperty(exports, "EventsByGroupDto", {
  enumerable: true,
  get: function get() {
    return _EventsByGroupDto["default"];
  }
});
Object.defineProperty(exports, "ExportReportRequestDto", {
  enumerable: true,
  get: function get() {
    return _ExportReportRequestDto["default"];
  }
});
Object.defineProperty(exports, "ExportResult", {
  enumerable: true,
  get: function get() {
    return _ExportResult["default"];
  }
});
Object.defineProperty(exports, "FeatureDto", {
  enumerable: true,
  get: function get() {
    return _FeatureDto["default"];
  }
});
Object.defineProperty(exports, "ForgotPasswordRequest", {
  enumerable: true,
  get: function get() {
    return _ForgotPasswordRequest["default"];
  }
});
Object.defineProperty(exports, "GroupDto", {
  enumerable: true,
  get: function get() {
    return _GroupDto["default"];
  }
});
Object.defineProperty(exports, "GroupUsersDto", {
  enumerable: true,
  get: function get() {
    return _GroupUsersDto["default"];
  }
});
Object.defineProperty(exports, "GroupsApi", {
  enumerable: true,
  get: function get() {
    return _GroupsApi["default"];
  }
});
Object.defineProperty(exports, "HardwareApi", {
  enumerable: true,
  get: function get() {
    return _HardwareApi["default"];
  }
});
Object.defineProperty(exports, "HardwareDto", {
  enumerable: true,
  get: function get() {
    return _HardwareDto["default"];
  }
});
Object.defineProperty(exports, "HardwareGroupDto", {
  enumerable: true,
  get: function get() {
    return _HardwareGroupDto["default"];
  }
});
Object.defineProperty(exports, "HardwareInstallationApi", {
  enumerable: true,
  get: function get() {
    return _HardwareInstallationApi["default"];
  }
});
Object.defineProperty(exports, "HardwareInstallationDto", {
  enumerable: true,
  get: function get() {
    return _HardwareInstallationDto["default"];
  }
});
Object.defineProperty(exports, "HardwarePlaceDto", {
  enumerable: true,
  get: function get() {
    return _HardwarePlaceDto["default"];
  }
});
Object.defineProperty(exports, "HardwarePlacePermitDto", {
  enumerable: true,
  get: function get() {
    return _HardwarePlacePermitDto["default"];
  }
});
Object.defineProperty(exports, "HardwarePlacesApi", {
  enumerable: true,
  get: function get() {
    return _HardwarePlacesApi["default"];
  }
});
Object.defineProperty(exports, "HardwareStateHistoryDto", {
  enumerable: true,
  get: function get() {
    return _HardwareStateHistoryDto["default"];
  }
});
Object.defineProperty(exports, "HardwareTypeOptionDto", {
  enumerable: true,
  get: function get() {
    return _HardwareTypeOptionDto["default"];
  }
});
Object.defineProperty(exports, "InlineObject", {
  enumerable: true,
  get: function get() {
    return _InlineObject["default"];
  }
});
Object.defineProperty(exports, "InlineObject1", {
  enumerable: true,
  get: function get() {
    return _InlineObject2["default"];
  }
});
Object.defineProperty(exports, "InviteUserRequest", {
  enumerable: true,
  get: function get() {
    return _InviteUserRequest["default"];
  }
});
Object.defineProperty(exports, "JwtApi", {
  enumerable: true,
  get: function get() {
    return _JwtApi["default"];
  }
});
Object.defineProperty(exports, "LoginActiveDirectoryRequestDto", {
  enumerable: true,
  get: function get() {
    return _LoginActiveDirectoryRequestDto["default"];
  }
});
Object.defineProperty(exports, "LoginActiveDirectoryTokenDto", {
  enumerable: true,
  get: function get() {
    return _LoginActiveDirectoryTokenDto["default"];
  }
});
Object.defineProperty(exports, "LoginRequest", {
  enumerable: true,
  get: function get() {
    return _LoginRequest["default"];
  }
});
Object.defineProperty(exports, "LoginResponse", {
  enumerable: true,
  get: function get() {
    return _LoginResponse["default"];
  }
});
Object.defineProperty(exports, "NameValueDto", {
  enumerable: true,
  get: function get() {
    return _NameValueDto["default"];
  }
});
Object.defineProperty(exports, "NotificationConfigurationDto", {
  enumerable: true,
  get: function get() {
    return _NotificationConfigurationDto["default"];
  }
});
Object.defineProperty(exports, "NotificationDataDto", {
  enumerable: true,
  get: function get() {
    return _NotificationDataDto["default"];
  }
});
Object.defineProperty(exports, "NotificationDto", {
  enumerable: true,
  get: function get() {
    return _NotificationDto["default"];
  }
});
Object.defineProperty(exports, "NotificationEventDto", {
  enumerable: true,
  get: function get() {
    return _NotificationEventDto["default"];
  }
});
Object.defineProperty(exports, "NotificationSettingsDto", {
  enumerable: true,
  get: function get() {
    return _NotificationSettingsDto["default"];
  }
});
Object.defineProperty(exports, "NotificationSettingsFilterDto", {
  enumerable: true,
  get: function get() {
    return _NotificationSettingsFilterDto["default"];
  }
});
Object.defineProperty(exports, "NotificationsApi", {
  enumerable: true,
  get: function get() {
    return _NotificationsApi["default"];
  }
});
Object.defineProperty(exports, "OrganizationApi", {
  enumerable: true,
  get: function get() {
    return _OrganizationApi["default"];
  }
});
Object.defineProperty(exports, "OrganizationDto", {
  enumerable: true,
  get: function get() {
    return _OrganizationDto["default"];
  }
});
Object.defineProperty(exports, "OverriddenPermissionDto", {
  enumerable: true,
  get: function get() {
    return _OverriddenPermissionDto["default"];
  }
});
Object.defineProperty(exports, "PasswordsApi", {
  enumerable: true,
  get: function get() {
    return _PasswordsApi["default"];
  }
});
Object.defineProperty(exports, "PermissionDto", {
  enumerable: true,
  get: function get() {
    return _PermissionDto["default"];
  }
});
Object.defineProperty(exports, "PermissionState", {
  enumerable: true,
  get: function get() {
    return _PermissionState["default"];
  }
});
Object.defineProperty(exports, "PermissionsApi", {
  enumerable: true,
  get: function get() {
    return _PermissionsApi["default"];
  }
});
Object.defineProperty(exports, "ReportsApi", {
  enumerable: true,
  get: function get() {
    return _ReportsApi["default"];
  }
});
Object.defineProperty(exports, "RequestClientGroupDto", {
  enumerable: true,
  get: function get() {
    return _RequestClientGroupDto["default"];
  }
});
Object.defineProperty(exports, "ResetPasswordRequest", {
  enumerable: true,
  get: function get() {
    return _ResetPasswordRequest["default"];
  }
});
Object.defineProperty(exports, "SettingDto", {
  enumerable: true,
  get: function get() {
    return _SettingDto["default"];
  }
});
Object.defineProperty(exports, "SettingsApi", {
  enumerable: true,
  get: function get() {
    return _SettingsApi["default"];
  }
});
Object.defineProperty(exports, "SocialMediaApi", {
  enumerable: true,
  get: function get() {
    return _SocialMediaApi["default"];
  }
});
Object.defineProperty(exports, "SocialMediaDto", {
  enumerable: true,
  get: function get() {
    return _SocialMediaDto["default"];
  }
});
Object.defineProperty(exports, "SupportApi", {
  enumerable: true,
  get: function get() {
    return _SupportApi["default"];
  }
});
Object.defineProperty(exports, "Token", {
  enumerable: true,
  get: function get() {
    return _Token["default"];
  }
});
Object.defineProperty(exports, "TokenDto", {
  enumerable: true,
  get: function get() {
    return _TokenDto["default"];
  }
});
Object.defineProperty(exports, "UpdateNotificationDto", {
  enumerable: true,
  get: function get() {
    return _UpdateNotificationDto["default"];
  }
});
Object.defineProperty(exports, "UpdateNotificationSettingStatusDto", {
  enumerable: true,
  get: function get() {
    return _UpdateNotificationSettingStatusDto["default"];
  }
});
Object.defineProperty(exports, "UpdateSettingRequest", {
  enumerable: true,
  get: function get() {
    return _UpdateSettingRequest["default"];
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User["default"];
  }
});
Object.defineProperty(exports, "UsersApi", {
  enumerable: true,
  get: function get() {
    return _UsersApi["default"];
  }
});
Object.defineProperty(exports, "VendorsApi", {
  enumerable: true,
  get: function get() {
    return _VendorsApi["default"];
  }
});
Object.defineProperty(exports, "VideoAnalyticsApi", {
  enumerable: true,
  get: function get() {
    return _VideoAnalyticsApi["default"];
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _ActiveDirectoryDomainDto = _interopRequireDefault(require("./model/ActiveDirectoryDomainDto"));
var _ApiKeyRequest = _interopRequireDefault(require("./model/ApiKeyRequest"));
var _ApiKeyResponse = _interopRequireDefault(require("./model/ApiKeyResponse"));
var _AppActivationDto = _interopRequireDefault(require("./model/AppActivationDto"));
var _AppActivationTrackerDto = _interopRequireDefault(require("./model/AppActivationTrackerDto"));
var _AppDto = _interopRequireDefault(require("./model/AppDto"));
var _AppStoreDto = _interopRequireDefault(require("./model/AppStoreDto"));
var _AppVendorDto = _interopRequireDefault(require("./model/AppVendorDto"));
var _BlockageReasonDto = _interopRequireDefault(require("./model/BlockageReasonDto"));
var _ChangePasswordRequest = _interopRequireDefault(require("./model/ChangePasswordRequest"));
var _ClientLogDto = _interopRequireDefault(require("./model/ClientLogDto"));
var _CountUserDto = _interopRequireDefault(require("./model/CountUserDto"));
var _CounterVideoProviderDto = _interopRequireDefault(require("./model/CounterVideoProviderDto"));
var _DepartmentDto = _interopRequireDefault(require("./model/DepartmentDto"));
var _DomainDto = _interopRequireDefault(require("./model/DomainDto"));
var _EditUserDto = _interopRequireDefault(require("./model/EditUserDto"));
var _EditUserProfileDto = _interopRequireDefault(require("./model/EditUserProfileDto"));
var _EmailSubscriptionDto = _interopRequireDefault(require("./model/EmailSubscriptionDto"));
var _EmbedConfig = _interopRequireDefault(require("./model/EmbedConfig"));
var _ErrorResponse = _interopRequireDefault(require("./model/ErrorResponse"));
var _EventDefinitionByGroupDto = _interopRequireDefault(require("./model/EventDefinitionByGroupDto"));
var _EventDefinitionBySlugGroupDto = _interopRequireDefault(require("./model/EventDefinitionBySlugGroupDto"));
var _EventDefinitionDto = _interopRequireDefault(require("./model/EventDefinitionDto"));
var _EventDefinitionFilterDto = _interopRequireDefault(require("./model/EventDefinitionFilterDto"));
var _EventsByGroupDto = _interopRequireDefault(require("./model/EventsByGroupDto"));
var _ExportReportRequestDto = _interopRequireDefault(require("./model/ExportReportRequestDto"));
var _ExportResult = _interopRequireDefault(require("./model/ExportResult"));
var _FeatureDto = _interopRequireDefault(require("./model/FeatureDto"));
var _ForgotPasswordRequest = _interopRequireDefault(require("./model/ForgotPasswordRequest"));
var _GroupDto = _interopRequireDefault(require("./model/GroupDto"));
var _GroupUsersDto = _interopRequireDefault(require("./model/GroupUsersDto"));
var _HardwareDto = _interopRequireDefault(require("./model/HardwareDto"));
var _HardwareGroupDto = _interopRequireDefault(require("./model/HardwareGroupDto"));
var _HardwareInstallationDto = _interopRequireDefault(require("./model/HardwareInstallationDto"));
var _HardwarePlaceDto = _interopRequireDefault(require("./model/HardwarePlaceDto"));
var _HardwarePlacePermitDto = _interopRequireDefault(require("./model/HardwarePlacePermitDto"));
var _HardwareStateHistoryDto = _interopRequireDefault(require("./model/HardwareStateHistoryDto"));
var _HardwareTypeOptionDto = _interopRequireDefault(require("./model/HardwareTypeOptionDto"));
var _InlineObject = _interopRequireDefault(require("./model/InlineObject"));
var _InlineObject2 = _interopRequireDefault(require("./model/InlineObject1"));
var _InviteUserRequest = _interopRequireDefault(require("./model/InviteUserRequest"));
var _LoginActiveDirectoryRequestDto = _interopRequireDefault(require("./model/LoginActiveDirectoryRequestDto"));
var _LoginActiveDirectoryTokenDto = _interopRequireDefault(require("./model/LoginActiveDirectoryTokenDto"));
var _LoginRequest = _interopRequireDefault(require("./model/LoginRequest"));
var _LoginResponse = _interopRequireDefault(require("./model/LoginResponse"));
var _NameValueDto = _interopRequireDefault(require("./model/NameValueDto"));
var _NotificationConfigurationDto = _interopRequireDefault(require("./model/NotificationConfigurationDto"));
var _NotificationDataDto = _interopRequireDefault(require("./model/NotificationDataDto"));
var _NotificationDto = _interopRequireDefault(require("./model/NotificationDto"));
var _NotificationEventDto = _interopRequireDefault(require("./model/NotificationEventDto"));
var _NotificationSettingsDto = _interopRequireDefault(require("./model/NotificationSettingsDto"));
var _NotificationSettingsFilterDto = _interopRequireDefault(require("./model/NotificationSettingsFilterDto"));
var _OrganizationDto = _interopRequireDefault(require("./model/OrganizationDto"));
var _OverriddenPermissionDto = _interopRequireDefault(require("./model/OverriddenPermissionDto"));
var _PermissionDto = _interopRequireDefault(require("./model/PermissionDto"));
var _PermissionState = _interopRequireDefault(require("./model/PermissionState"));
var _RequestClientGroupDto = _interopRequireDefault(require("./model/RequestClientGroupDto"));
var _ResetPasswordRequest = _interopRequireDefault(require("./model/ResetPasswordRequest"));
var _SettingDto = _interopRequireDefault(require("./model/SettingDto"));
var _SocialMediaDto = _interopRequireDefault(require("./model/SocialMediaDto"));
var _Token = _interopRequireDefault(require("./model/Token"));
var _TokenDto = _interopRequireDefault(require("./model/TokenDto"));
var _UpdateNotificationDto = _interopRequireDefault(require("./model/UpdateNotificationDto"));
var _UpdateNotificationSettingStatusDto = _interopRequireDefault(require("./model/UpdateNotificationSettingStatusDto"));
var _UpdateSettingRequest = _interopRequireDefault(require("./model/UpdateSettingRequest"));
var _User = _interopRequireDefault(require("./model/User"));
var _AppsApi = _interopRequireDefault(require("./api/AppsApi"));
var _AuthApi = _interopRequireDefault(require("./api/AuthApi"));
var _CityLightsReportApi = _interopRequireDefault(require("./api/CityLightsReportApi"));
var _DefaultApi = _interopRequireDefault(require("./api/DefaultApi"));
var _DepartmentsApi = _interopRequireDefault(require("./api/DepartmentsApi"));
var _DevicesApi = _interopRequireDefault(require("./api/DevicesApi"));
var _DummyUserApi = _interopRequireDefault(require("./api/DummyUserApi"));
var _EmailSubscriptionApi = _interopRequireDefault(require("./api/EmailSubscriptionApi"));
var _EventApi = _interopRequireDefault(require("./api/EventApi"));
var _GroupsApi = _interopRequireDefault(require("./api/GroupsApi"));
var _HardwareApi = _interopRequireDefault(require("./api/HardwareApi"));
var _HardwareInstallationApi = _interopRequireDefault(require("./api/HardwareInstallationApi"));
var _HardwarePlacesApi = _interopRequireDefault(require("./api/HardwarePlacesApi"));
var _JwtApi = _interopRequireDefault(require("./api/JwtApi"));
var _NotificationsApi = _interopRequireDefault(require("./api/NotificationsApi"));
var _OrganizationApi = _interopRequireDefault(require("./api/OrganizationApi"));
var _PasswordsApi = _interopRequireDefault(require("./api/PasswordsApi"));
var _PermissionsApi = _interopRequireDefault(require("./api/PermissionsApi"));
var _ReportsApi = _interopRequireDefault(require("./api/ReportsApi"));
var _SettingsApi = _interopRequireDefault(require("./api/SettingsApi"));
var _SocialMediaApi = _interopRequireDefault(require("./api/SocialMediaApi"));
var _SupportApi = _interopRequireDefault(require("./api/SupportApi"));
var _UsersApi = _interopRequireDefault(require("./api/UsersApi"));
var _VendorsApi = _interopRequireDefault(require("./api/VendorsApi"));
var _VideoAnalyticsApi = _interopRequireDefault(require("./api/VideoAnalyticsApi"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }